import useWindowSize from '../../hooks/useWindowSize';
import DesktopHeader from './DesktopHeader';
import MobileHeader from './MobileHeader';

export default function Header({ items }) {
    const windowSize = useWindowSize();

    if (!windowSize.height) {
        return <div style={{ height: '220px', width: '100%' }}></div>;
    }

    if (windowSize.width < 960 || windowSize.height < 700) {
        return <MobileHeader items={items} />;
    } else {
        return <DesktopHeader windowSize={windowSize} items={items} />;
    }
}
