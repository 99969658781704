import { Link } from "react-router-dom";
import TCardBase from "../../Common/TCardBase";

function EmployeCard({employee}) {
    return (
        <Link to={"/pages/employees/" + employee.id}>
            <TCardBase img={employee.photo} title={employee.name} theme={"white"} containerCSS={style.container} bodyCSS={style.body} titleCSS={style.title} footerCSS={style.footer} hint={employee.position_title} hintCSS={style.hint}/>
        </Link>
    )
}

const style = {
    container: {
        width: "130px",
        '@xs': {
            width: "250px",
        },
        '@lg': {
            width: "200px",
        }
    },
    body: {
        width: "130px",
        height: "200px",
        '@xs': {
            width: "250px",
            height: "350px",
        },
        '@lg': {
            width: "200px",
            height: "300px",
        }
    },
    title: {
        fontWeight: 300,
        fontSize: "16px",
        '@xs': {
            fontSize: "27px",
        },
        '@sm': {
            fontSize: "25px",
        }
    },
    hint: {
        fontWeight: 300,
        fontSize: "14px",
        '@xs': {
            fontSize: "22px",
        },
        '@sm': {
            fontSize: "20px",
        }
    },
    footer: {
       paddingBottom: "5px"
    }
}

export default EmployeCard;