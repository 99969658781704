import { Col, Row } from '@nextui-org/react';
import TText from '../../Common/TText';
import NotFoundImage from '../../../images/404.svg';
import './NotFoundPage.css';
import PublicHeader from '../../Header/PublicHeader';

function NotFoundPage() {
    return (
        <Row css={style.container}>
            <PublicHeader />
            <Col css={style.messageContainer}>
                <Row css={style.message} justify="center" align="center">
                    <img
                        src={NotFoundImage}
                        alt="not found"
                        style={style.image}
                        className="not-found__image"
                    />
                    <Col css={style.messageTextContainer}>
                        <Row justify="center">
                            <TText css={style.text404}>404</TText>
                        </Row>
                        <Row justify="center">
                            <TText css={style.textMessage}>
                                Тут ничего нет
                            </TText>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

const style = {
    container: {
        flexFlow: 'column',
        height: '100vh',
        width: '100%',
    },
    messageContainer: {
        width: '100%',
        flexGrow: 1,
        position: 'relative',
    },
    image: {
        marginLeft: '100px',
        transform: 'translate(-10%, 0)',
        '@sm': {
            transform: 'unset',
        },
    },
    message: {
        height: '100%',
        flexFlow: 'column',
        '@sm': {
            flexFlow: 'row-reverse',
        },
    },
    messageTextContainer: {
        width: 'max-content',
    },
    text404: {
        fontSize: '95px',
        '@sm': {
            fontSize: '95px',
        },
        '@lg': {
            fontSize: '136px',
        },
    },
    textMessage: {
        fontSize: '48px',
        paddingBottom: '100px',
        '@sm': {
            paddingBottom: 'unset',
            fontSize: '48px',
        },
        '@lg': {
            fontSize: '64px',
        },
    },
};

export default NotFoundPage;
