import { useParams } from 'react-router-dom';
import BlockTitle from '../../MainPage/BlockTitle';
import useFetch from '../../../hooks/useFetch';
import {API, imageUrlOutput} from '../../../api/api';
import Output from '../../../lib/editor-output/editor-output';
import { Row, Spacer } from '@nextui-org/react';
import moment from 'moment';
import TText from '../../Common/TText';

function ConcreteNewsPage() {
    const { newsID } = useParams();

    const news = useFetch({ initial: {}, api: API.concreteNews(newsID) });

    return (
        <>
            <Spacer/>
            <Row>
                <TText css={style.title}>{news.data.title}</TText>
            </Row>
            <Spacer y={0.5} />
            {!news.isLoading && <Output data={imageUrlOutput(news.data?.content)} />}
            <Row justify="flex-end">
                <TText size={21} css={{ color: 'gray' }}>
                    {moment(news.data?.created_at).format('DD MMMM YYYY')}
                </TText>
            </Row>
            <Spacer />
        </>
    );
}

const style = {
    title: {
        fontSize: '42px',
        '@xs': {
            fontSize: '54px',
        },
        '@sm': {
            fontSize: '64px',
        },
    },
};

export default ConcreteNewsPage;
