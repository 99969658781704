import React from "react"
import ContentLoader from "react-content-loader"

const RectLoader = ({width, height, padding}) => {

    return (
        <ContentLoader
            speed={2}
            width={width}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
        >
            <rect x={padding} y={padding} rx="0" ry="0" width={width - 2*padding} height={height - 2*padding} />
        </ContentLoader>
    )
}

export default RectLoader

