import useValue from "./useValue";
import {useCallback, useEffect} from "react";
import useFetch from "./useFetch";
import axios from "../lib/axios/axios";
import useInfiniteScroll from "react-infinite-scroll-hook";

function useFetchOffsetPagination({initial, api, params, errClb, deps, limit}) {
    const data = useValue(initial);
    const isLoading = useValue(true);
    const status = useValue(null);
    const hasMore = useValue(true);

    const depsEffect = deps ? [...deps, params] : [params]

    const loadData = useCallback((haven) => {
        isLoading.set(true);
        axios
            .get(api, {
                params: {...params, since: haven.length, limit}
            })
            .then((resp) => {
                isLoading.set(false);
                if (resp.status === 204) {
                    data.set(haven)
                    hasMore.set(false);
                    return;
                }
                hasMore.set(true);
                status.set(resp.status);
                const newData = [...haven, ...resp.data];
                data.set(newData);
            })
            .catch((err) => {
                isLoading.set(false);
                if (errClb) {
                    errClb(err);
                }
            });
        }, [...depsEffect])

    const toLoad = useCallback(() => {
        loadData(data.value)
    }, [data.value, loadData])

    useEffect(() => {
            loadData([])
        }
    , depsEffect);

    const [scrollRef] = useInfiniteScroll({
        loading: isLoading.value,
        hasNextPage: hasMore.value,
        onLoadMore: toLoad,
    })

    return {
        data: data.value,
        isLoading: isLoading.value,
        status: status,
        hasMore: hasMore.value,
        loadData: toLoad,
        scrollRef: scrollRef
    }
}

export default useFetchOffsetPagination;