import {useCallback, useEffect, useMemo, useRef} from "react";
import {SliderElement} from "./SliderElement";
import useElementSize from "../../../hooks/useElementSize";
import LongArrow from "../../../images/LongArrow.svg";
import useValue from "../../../hooks/useValue";

export default function VerticalSlider({items}) {
    const [viewRef, viewContainerSize] = useElementSize();
    const [elementRef, elementSize] = useElementSize();
    const [elementsContainerRef, elementsContainerSize] = useElementSize([items]);
    const currentPosition = useValue(0);

    const canNext =  useMemo(() => {
        return viewContainerSize.height < elementsContainerSize.height + currentPosition.value;
    },[viewContainerSize.height, elementsContainerSize.height, items, elementsContainerRef.current?.offsetTop])

    const canPrev = useMemo(() => {
        return currentPosition.value < 0;
    }, [currentPosition.value])

    const onNext = useCallback(() => {
        if (!canNext) {
            return;
        }
        currentPosition.set(currentPosition.value - elementSize.height);
    }, [elementSize.height, currentPosition.value, canNext]);

    const onPrev = useCallback(() => {
        if (!canPrev) {
            return;
        }
        currentPosition.set(currentPosition.value + elementSize.height);
    }, [elementSize.height, currentPosition.value, canPrev]);

    const visibleCss = (isVisible) => {
        if (isVisible) {
            return {
                cursor: "pointer"
            }
        }

        return {
            opacity: 0,
        }
    }


   return (
       <div ref={viewRef} style={{position: "relative", overflow: "hidden", display: "flex", height: "100%"}}>
           <div ref={elementsContainerRef} style={{height: "min-content", transition: "300ms", position: "relative", top: `${currentPosition.value}px`}}>
               {items ? items.map((item, idx) => (<SliderElement ref={idx === 0 ? elementRef : null}>{item}</SliderElement>)) : <div>Здесь ничего нет</div>}
           </div>
           <div style={{
               height: "100%",
               paddingLeft: "10px",
               width: "30px",
               display: "flex",
               flexDirection: "column",
               justifyContent: "space-evenly"}}
           >
               <img src={LongArrow} style={{transform: "rotate(180deg)", height: "150px", ...visibleCss(canPrev)}} onClick={onPrev}/>
               <img src={LongArrow} style={{height: "150px", ...visibleCss(canNext)}} onClick={onNext}/>
           </div>
       </div>
   );
}