import {Children} from "react";
import {styled} from "@nextui-org/react";

export default function FullContainer({children, css}) {
   return (
       <AbsoluteContainer css={css}>
           {Children.map(children, child => (child))}
       </AbsoluteContainer>
   )
}

const AbsoluteContainer = styled('div', {
    position: "absolute",
    left: 0, top: 0, right: 0, bottom: 0
})