import {Button} from "@nextui-org/react";
import {Children} from "react";

export const TButton = (props) => {
    return <Button
        {...props}
        css={{
            background: props.transparent ? "transparent" : "white",
            border: `solid ${props.bold ? 2 : 1}px ${props.white ? "white" : "black"}`,
            borderRadius: "0",
            color: props.white ? "white" : "black",
            "&:hover": {
                background: "$primary",
                color: "white",
                borderColor: "transparent",
            },
            ...props.css
        }}>
        {Children.map(props.children, child => (child))}</Button>
}