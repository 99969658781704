import useWindowSize from "../../hooks/useWindowSize";
import {Children, useEffect, useState, useTransition} from "react";
import useValue from "../../hooks/useValue";
import moment from "moment";
import {Mutex} from "async-mutex";
import LogoLoader from "../Common/Loader/LogoLoader";
import {IconArrowUp, IconCircleArrowUpFilled} from "@tabler/icons-react";

export default function FullPageScroller({children}) {
    const windowSize = useWindowSize();


    const [animation, setAnimation] = useState(true);
    const [currentPosition, setCurrentPosition] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [prevPosition, setPrevPosition] = useState(0);
    const [prevWindowHeight, setPrevWindowHeight] = useState(windowSize.height);
    const maxPosition = -(children.length - 1) * windowSize.height;

    const [canScroll, setCanScroll] = useState(true);


    useEffect(() => {
        if (prevWindowHeight === undefined) {
            setPrevWindowHeight(windowSize.height)
        }
    }, [windowSize.height])

    useEffect(() => {
        setAnimation(false);
        setCurrentPosition(-currentPage * windowSize.height);
        setPrevPosition(-currentPage * windowSize.height);
        setTimeout(() => {
            setAnimation(true);
        }, 10)
    }, [windowSize.height])

    const lastScrollTime = useValue(moment());

    function onScroll(e) {
        const diff = moment.duration(moment().diff(lastScrollTime.value)).asMilliseconds()
        if (!canScroll || diff < 200 & Math.abs(e.deltaY) < 70) {
            lastScrollTime.set(moment())
            return;
        }

        lastScrollTime.set(moment())
        setCanScroll(false);
        if (e.deltaY > 0 && currentPosition !== maxPosition) {
            const nextPosition = currentPosition - windowSize.height;
            setCurrentPosition(nextPosition)
            setPrevPosition(nextPosition)
            setCurrentPage(currentPage + 1)
        } else if (e.deltaY < 0 && currentPosition !== 0) {
            const nextPosition = currentPosition + windowSize.height;
            setCurrentPosition(nextPosition)
            setPrevPosition(nextPosition)
            setCurrentPage(currentPage - 1)
        } else {
            if (e.deltaY > 0) {
                setCurrentPosition(currentPosition - 100)
            } else {
                setCurrentPosition(currentPosition + 100)
            }
            setTimeout(() => {
                setCurrentPosition(currentPosition)
            }, 200)
        }

        setTimeout(() => {
            setCanScroll(true)
        }, 500)

    }

    const [startTouchY, setStartTouchY] = useState(0);

    const onTouchStart = e => {
        setAnimation(false);
        setStartTouchY(e.touches[0].clientY);
    }

    const onTouchEnd = e => {
        setAnimation(true);
        setStartTouchY(0);
        const delta = prevPosition - currentPosition;
        if (delta > 80 && prevPosition !== maxPosition) {
            const nextPosition = prevPosition - windowSize.height;
            setCurrentPosition(nextPosition);
            setPrevPosition(nextPosition);
            setCurrentPage(currentPage + 1)
        } else if (delta < -80 && prevPosition !== 0) {
            const nextPosition = prevPosition + windowSize.height;
            setCurrentPosition(nextPosition);
            setPrevPosition(nextPosition);
            setCurrentPage(currentPage - 1)
        } else {
            setCurrentPosition(prevPosition);
        }
    }

    const onTouchMove = e => {
        const delta = startTouchY - e.touches[0].clientY;
        setStartTouchY(e.touches[0].clientY);
        setCurrentPosition(currentPosition - delta);
    }

    const up = () => {
        console.log("click");
        setCurrentPosition(0);
        setPrevPosition(0);
        setCurrentPage(0);
    }

    return (
        <div className={"full-screen-scroller-container"}
             style={{position: "fixed", overflow: "hidden", left: 0, right: 0, bottom: 0, top: 0}} onWheel={onScroll}
             onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>
            {!!windowSize.height &&
                <div style={{
                    position: "absolute", transition: animation ? "500ms" : "",
                    top: `${currentPosition}px`, left: 0, right: 0, height: `${children.length * windowSize.height}px`
                }}>
                    {Children.map(children, child => (child))}
                </div>
            }
            <div style={{
                position: "absolute",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                bottom: 5,
                right: 5,
                width: "48px",
                height: "48px",
                opacity: currentPage === 0 ? 0 : 0.7,
                transition: "300ms",
                cursor: "pointer",
                borderRadius: "50%",
                padding: 0,
            }} onClick={up}>
                <div style={{
                    position: "absolute",
                    width: "50%",
                    height: "50%",
                    background: "white",
                    borderRadius: "50%",
                    zIndex: "1",
                }}/>
                <IconCircleArrowUpFilled size={48} style={{zIndex: "2"}}/>
            </div>
        </div>
    )
}