import {styled} from "@nextui-org/react";

export const StyledP = styled('p', {
    variants: {
        color: {
            tblack: {
                color: "black",
                background: "white",
            },
            twhite: {
                color: "white",
                background: "$darkBackground"
            }
        }
    }
});