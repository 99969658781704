import { useEffect } from "react";
import useValue from "../../../hooks/useValue";
import Editor from "../../../lib/Editor/Editor";
import Output from "../../../lib/editor-output/editor-output";
import { TButton } from "../../Common/TButton";
import BlockTitle from "../../MainPage/BlockTitle";
import axios from "../../../lib/axios/axios";
import { API } from "../../../api/api";

function HistoryPage() {
    const historyData = useValue(null);

    useEffect(() => {
        axios.get(API.history)
        .then(resp => {
            historyData.set(resp.data.content);
        })
        .catch(err => {

        });
    }, [])

    return (
        <>
            <BlockTitle color="tblack">История</BlockTitle>
            {historyData.value &&
                <Output data={historyData.value}/>
            }
        </>
    )
}

export default HistoryPage;