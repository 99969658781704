import React, {useMemo} from "react"
import ContentLoader from "react-content-loader"

const PosterShowCardLoader = ({size}) => {
    const sizes = useMemo(() => {
        if (size === "m") {
            return {
                w: 180,
                h: 170,
                fontSize: 20,
                timeWidth: 40,
                titleWidth: 80
            }
        }

        return {
            w: 300,
            h: 230,
            fontSize: 30,
            timeWidth: 50,
            titleWidth: 150
        }
    }, [size])

    const textPadding = 12;

    const imgSize = {
        h: sizes.h - sizes.fontSize - 2*textPadding,
        w: sizes.w
    }

    return (
        <ContentLoader
            speed={2}
            width={sizes.w}
            height={sizes.h}
            viewBox={`0 0 ${sizes.w} ${sizes.h}`}
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
        >
            <rect x="0" y="0" rx="0" ry="0" width={imgSize.w} height={imgSize.h}/>
            <rect x="0" y={imgSize.h + textPadding} rx="0" ry="0" width={sizes.titleWidth} height={sizes.fontSize}/>
            <rect x={sizes.w - sizes.timeWidth} y={imgSize.h + textPadding} rx="0" ry="0" width={sizes.timeWidth} height={sizes.fontSize}/>
        </ContentLoader>
    )
}

export default PosterShowCardLoader

