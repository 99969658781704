import FullContainer from "../../Common/FullContainer";
import TContainer from "../../Common/TContainer/TContainer";
import {Grid} from "@nextui-org/react";
import useValue from "../../../hooks/useValue";
import {useEffect} from "react";
import {API} from "../../../api/api";
import axios from "../../../lib/axios/axios";
import Mask from "../../Common/Mask";
import FooterInformation from "./FooterInformation";
import HARSHMINT from "./HARSHMINT";
import GridSlider from "./GridSlider";
import useFetch from "../../../hooks/useFetch";

function Footer() {
    const {data, isLoading} = useFetch({
        api: API.footer,
        initial: {
            banners: [],
            help: []
        }
    })


    return (
        <FullContainer css={{background: '$darkBackground'}}>
            <Mask dark/>
            <Mask right dark/>
            <TContainer css={{position: "absolute", top: 0, bottom: 0, left: 0, right: 0}}>
                <Grid.Container direction={"column"} justify={"space-between"} css={{h: "100%", w: "100%"}}>
                    <Grid xs css={{minWidth: "100%", pb: "20px"}}>
                        <GridSlider items={data.banners} css={gridCss}/>
                    </Grid>
                    <Grid css={{height: "max-content", pt: "20px", pb: "20px"}}>
                        <FooterInformation help={data.help}/>
                    </Grid>
                    <Grid>
                        <HARSHMINT/>
                    </Grid>
                </Grid.Container>
            </TContainer>
        </FullContainer>
    )
}

const gridCss = {
    marginTop: "20px",
    height: "100%",
    width: "100%",
    filter: "grayscale(1)",
    '@xs': {
        height: "100%",
    }
}

export default Footer;


