import useElementSize from "../../../hooks/useElementSize";
import RightArrow from "../../../images/RightArrow.svg"
import SmallArrowDown from "../../../images/ArrowDown.svg"
import {SliderElement} from "./SliderElement";
import useValue from "../../../hooks/useValue";
import {forwardRef, useCallback, useMemo} from "react";
import {Col, Row} from "@nextui-org/react";
import SlideContainer from "./SlideContainer";
import "./HorizontalSlider.css";

function HorizontalSlider({items, sideArrows, css, theme, severalComponentOnSlide, numberOfComponentOnSlide}, ref) {
    const [viewRef, viewContainerSize] = useElementSize();
    const [elementsContainerRef, elementsContainerSize] = useElementSize([items]);
    const [elementRef, elementSize] = useElementSize();

    const slides = useMemo(() => {
        let currentSlideCount = 0;
        const sliders = [];
        let sliderContent = [];
        items.forEach(item => {
            currentSlideCount += 1;
            sliderContent.push(item);
            if (currentSlideCount === numberOfComponentOnSlide) {
                currentSlideCount = 0;
                sliders.push(<SlideContainer items={sliderContent} defaultCountOnSlide={numberOfComponentOnSlide} width={viewContainerSize.width}/>);
                sliderContent = [];
            }
        })
        if (currentSlideCount !== 0) {
            sliders.push(<SlideContainer items={sliderContent} defaultCountOnSlide={numberOfComponentOnSlide} width={viewContainerSize.width}/>);
        }
        return sliders;
    }, [items, viewContainerSize.width, numberOfComponentOnSlide]);

    
    const visibleCss = (isVisible) => {
        if (isVisible) {
            return {
                cursor: "pointer"
            }
        }

        return {
            opacity: 0,
        }
    }

    const itemsToRender = useMemo(() => {
        if (severalComponentOnSlide) {
            return slides;
        }
        return items;
    });

    const scrollPos = (num) => {
        return num * elementSize.width;
    }

    const getPos = (currentScrollPos) => {
        return Math.floor(currentScrollPos / elementSize.width);
    } 
 
    const onNext = useCallback(() => {
        viewRef.current.scrollTo(scrollPos(getPos(viewRef.current.scrollLeft) + 1), 0);
    }, [elementSize.width]);

    const onPrev = useCallback(() => {
        viewRef.current.scrollTo(scrollPos(Math.ceil(viewRef.current.scrollLeft / elementSize.width) - 1), 0);
    }, [elementSize.width]);

    const fakeScrollPos = useValue(0);

    const canNext = useMemo(() => {
        if (!viewRef.current) {
            return;
        }
        return viewRef.current.offsetWidth < viewRef.current.scrollWidth - viewRef.current.scrollLeft;
    }, [viewRef.current, viewContainerSize.width, elementsContainerSize.width, items, fakeScrollPos.value])

    const canPrev = useMemo(() => {
        if (!viewRef.current) {
            return;
        }
        return viewRef.current.scrollLeft > 0;
    }, [viewRef.current, fakeScrollPos.value])

    return (
        <div ref={ref} style={{...styles.container,...css}}>
            <div className="horizontal-slider__view" ref={viewRef} onScroll={e => fakeScrollPos.set(e.target.scrollLeft)}>
                <div
                    ref={elementsContainerRef}
                    style={{
                        width: "max-content",
                        display: "flex",
                        flexWrap: "wrap",
                        position: "relative",
                        left: 0,
                    }}>
                    {itemsToRender ? itemsToRender.map((item, idx) => (
                            <div ref={idx === 0 ? elementRef : null}
                                 style={{paddingRight: idx === itemsToRender.length - 1 ? "" : "20px"}}>
                                <SliderElement key={'slider-element' + idx} ref={idx === 0 ? elementRef : null}>{item}</SliderElement>
                            </div>
                        ))
                        : <div>Здесь ничего нет</div>}
                </div>
            </div>
            {!sideArrows && (
                    <Row css={styles.arrows.container}
                         justify={"space-evenly"}
                         align={"center"}
                    >
                        <div style={{height: "100%"}} onClick={onPrev}>
                            <img src={RightArrow}
                                 style={{
                                     transform: "rotate(180deg)",
                                     height: "15px", ...visibleCss(canPrev), filter: theme === "dark" ? "invert(100%)" : ""
                                 }}
                            />
                        </div>

                        <div style={{height: "100%"}} onClick={onNext}>
                            <img src={RightArrow}
                                 style={{
                                     height: "15px", ...visibleCss(canNext), filter: theme === "dark" ? "invert(100%)" : ""
                                 }}
                            />
                        </div>
                    </Row>
                )}
            {!!sideArrows && (
                    <Col css={{...styles.sideArrow, ...styles.sideArrow.rightArrow}} onClick={onNext}>
                        <img src={SmallArrowDown} style={{transform: "rotate(-90deg)", ...visibleCss(canNext), filter: theme === "dark" ? "invert(100%)" : ""}}/>
                    </Col>
                )}
                {!!sideArrows && (
                    <Col css={{...styles.sideArrow, ...styles.sideArrow.leftArrow}} onClick={onPrev}>
                        <img src={SmallArrowDown} style={{transform: "rotate(90deg)", ...visibleCss(canPrev), filter: theme === "dark" ? "invert(100%)" : ""}}/>
                    </Col>
                )}
        </div>

    )
}

export default forwardRef(HorizontalSlider)

const styles = {
    container: {
        position: 'relative',
        width: "100%",
        height: "max-content"
    },
    arrows: {
        container: {
            width: "100%",
            zIndex: "99",
            paddingTop: "$lg",
            '@sm': {
                paddingTop: "$xl",
            }
        }
    },
    sideArrow: {
        w: 'max-content',
        h: 'max-content',
        position: "absolute",
        top: '50%',
        rightArrow: {
            right: 0,
            transform: 'translate(100%, -50%)',
        },
        leftArrow: {
            left: 0,
            transform: 'translate(-100%, -50%)',
        }
    },
}