import {Row, Grid, Text} from "@nextui-org/react";
import moment from "moment";
import TText from "../../../Common/TText";

const weekDays = () => {
    const weekDays = [];
    for (let idx = 0; idx < 7; idx++) {
        weekDays.push(idx)
    }
    return weekDays
}

export default function PosterCalendarWeeks({size}) {

    const responsiveSize = () => {
        switch (size) {
            case "m":
                return {
                    height: "35px",
                    fontSize: "24px",
                }
            default:
                return {
                    height: "70px",
                    fontSize: "48px",
                }
        }
    }

   return (
       <Row css={{borderTop: "1px solid", borderBottom: "1px solid", h: responsiveSize().height}} align={"center"}>
           <Grid.Container>
               {weekDays().map(weekDay => (
                   <Grid justify={"center"} xs={12/7}>
                       <TText light style={{
                           textTransform: "uppercase",
                           fontSize: responsiveSize().fontSize}}>
                           {moment().weekday(weekDay).format("dd")}
                       </TText>
                   </Grid>
               ))}
           </Grid.Container>
       </Row>
   )
}