import FullContainer from "../../Common/FullContainer";
import BlockTitle from "../BlockTitle";
import PosterCalendar from "./PosterCalendar/PosterCalendar";
import {Col, Container, Grid, Row, Spacer} from "@nextui-org/react";
import useWindowSize from "../../../hooks/useWindowSize";
import PosterShowCard from "./PosterShowCard";
import TContainer from "../../Common/TContainer/TContainer";
import VerticalSlider from "../../Common/Slider/VerticalSlider";
import useValue from "../../../hooks/useValue";
import moment from "moment";
import {useEffect, useMemo, useTransition} from "react";
import axios from "../../../lib/axios/axios";
import {API} from "../../../api/api";
import HorizontalSlider from "../../Common/Slider/HorizontalSlider";
import useElementSize from "../../../hooks/useElementSize";
import TText from "../../Common/TText";
import PosterShowCardLoader from "./PosterShowCardLoader";
import Mask from "../../Common/Mask";

export default function Poster() {
    const windowSize = useWindowSize();
    const responsiveControlWidth = 960;
    const currentDate = useValue({year: moment().year(), month: moment().month(), date: moment().date()});
    const scheduledShows = useValue([]);
    const [sliderRef, sliderSize] = useElementSize();

    const isLoading = useValue(true);

    useEffect(() => {
        isLoading.set(true);
        axios
            .get(API.poster + `?year=${currentDate.value.year}&month=${currentDate.value.month + 1}`)
            .then(resp => {
                isLoading.set(false);
                if (resp.status !== 200) {
                    return;
                }
                scheduledShows.set(resp.data.filter(item => moment(item.at) >= moment()));
            })
            .catch(err => {
                isLoading.set(false);
            })
    }, [currentDate.value.month, currentDate.value.year])

    const activeDays = useMemo(() => {
        return scheduledShows.value.map(item => moment(item.at).date());
    }, [scheduledShows.value])

    let responsiveSize = useMemo(() => {
        if (windowSize.width < responsiveControlWidth) {
            return "m";
        }
        return "lg";
    }, [windowSize.width]);


    const currentShows = useMemo(() => {
        return scheduledShows.value
            .filter(item => moment(item.at).date() === currentDate.value.date)
            .map(item => (<PosterShowCard item={item} size={responsiveSize}/>))
    }, [currentDate.value.date, currentDate.value.month, currentDate.value.year, responsiveSize, scheduledShows.value])


    return (<FullContainer css={{background: "white", zIndex: "0"}}>
        <Mask/>
        <Mask right/>
        <TContainer css={{mt: "25px", h: (windowSize.height - 50) + "px", zIndex: "10"}}>
            {windowSize.width >= responsiveControlWidth && <Row css={{h: "100%"}} justify={"space-between"}>
                <Col css={{position: "relative", h: "100%"}}>
                    <PosterCalendar
                        activeDays={activeDays}
                        currentDate={currentDate}
                        isLoading={isLoading.value}
                    />
                </Col>
                <Spacer x={2}/>
                <Row css={{h: "100%", w: "min-content"}}>
                    <Col css={{h: "100%", w: "min-content", display: "flex", flexDirection: "column", justifyContent: "center"}}>
                        <div style={{width: "330px", maxHeight: "100%"}}>
                            {isLoading.value && (
                                <Col>
                                    <PosterShowCardLoader size={responsiveSize}/>
                                    <PosterShowCardLoader size={responsiveSize}/>
                                </Col>
                            )}
                            {!isLoading.value && !!currentShows.length && <VerticalSlider items={currentShows}/>}
                            {!isLoading.value && !currentShows.length && (
                                <TText
                                    light
                                    size={30}>На {(currentDate.value.date + "").padStart(2, "0")}.{(currentDate.value.month + 1 + "").padStart(2, "0")} ничего
                                    не запланировано</TText>)}
                        </div>
                    </Col>
                </Row>
            </Row>}

            {windowSize.width < responsiveControlWidth && <Col css={{h: "calc(100% - 20px)", pb: "20px"}}>
                <Row css={{
                    position: "relative", h: `calc(100% - ${sliderSize.height ? sliderSize.height : 170}px)`
                }}>
                    <PosterCalendar
                        activeDays={activeDays}
                        currentDate={currentDate}
                        isLoading={isLoading.value}
                    />
                </Row>
                {isLoading.value && (
                    <Row align={"center"}>
                        <PosterShowCardLoader size={responsiveSize}/>
                        <Spacer x={1}/>
                        <PosterShowCardLoader size={responsiveSize}/>
                    </Row>
                )}
                {!isLoading.value && !!currentShows.length && <HorizontalSlider ref={sliderRef} items={currentShows} sideArrows={true}/>}
                {!isLoading.value && !currentShows.length && (<Row align={"center"} justify={"center"} css={{h: "260px"}}>
                    <TText light
                           size={30}>На {(currentDate.value.date + "").padStart(2, "0")}.{(currentDate.value.month + 1 + "").padStart(2, "0")} ничего
                        не запланировано</TText>
                </Row>)}
            </Col>}
        </TContainer>
            </FullContainer>)
}