import { Row, Grid, Col, Spacer} from "@nextui-org/react";
import { TButton } from "../../Common/TButton";
import TText from "../../Common/TText";
import moment from "moment";
import useValue from "../../../hooks/useValue";
import { useLinkClickHandler, useNavigate } from "react-router-dom";
import pushkinImgGrey from "../../../images/P_Grey.svg";
import pushkinImgWhite from "../../../images/P_White.svg";
import useWindowSize from "../../../hooks/useWindowSize";
import {adaptiveImageUrl} from "../../../api/api";

function PosterItem({show, last}) {
    const momentDatetime = moment(show.at);
    const isHover = useValue(false);
    const navigate = useNavigate();
    const windowSize = useWindowSize();

    const onClickBuyButton = e => {
        e.stopPropagination();

    }
    return (
        <Row css={{...styles.container, border: last ? "none" : ""}} onMouseEnter={() => isHover.set(true)} onMouseLeave={() => isHover.set(false)} onClick={() => navigate(`/pages/shows/${show.id}`)}>
            <img src={adaptiveImageUrl(show.under_layer_picture ? show.under_layer_picture : show.main_picture, 700,100, false)} style={{...styles.containerBackground, opacity: isHover.value ? 1 : 0}}/>
            <Row justify={"space-between"} align={"center"}>
                <Col css={styles.datetimeContainer}>
                    <Row css={{w: "100%"}} justify={"center"}><TText css={{...styles.date, color: isHover.value ? "white" : ""}} light>{momentDatetime.format("D")}</TText></Row>
                    <Spacer y={0.25}/>
                    <Row css={{w: "100%"}} justify={"center"}><TText css={{...styles.time, color: isHover.value ? "white" : "#a5a5a5"}} light>{momentDatetime.format(`${windowSize.width < 650 ? "" : "ddd "}HH:mm`)}</TText></Row>
                </Col>
                <Row align={"center"} justify={"space-between"}>
                    <TText css={{...styles.title, color: isHover.value ? "white" : ""}}>{show.title}</TText>
                    <Col css={{w: "min-content"}}>
                        <Row justify={"flex-end"}>
                            {show.pushkin && (
                                <Col css={styles.pushkinLogo}>
                                    <img src={isHover.value ? pushkinImgWhite : pushkinImgGrey} alt={"pushkin"}/>
                                </Col>
                            )}
                            <TText css={{...styles.rars, color: isHover.value ? "white" : "#a5a5a5"}}>{show.rars}+</TText>
                        </Row>
                    </Col>
                </Row>
                <Row justify="center" align="center" css={styles.buttonContainer}>
                    <a href={show.link_to_ticket} target={"_blank"}>
                        <TButton css={styles.button} white={isHover.value} transparent onClick={onClickBuyButton}><TText css={styles.buttonText} light>Купить билеты</TText></TButton>
                    </a>
                </Row>
            </Row>
        </Row>
            )
}

const styles = {
    container: {
        position: "relative",
        overflow: "hidden",
        borderBottom: "solid 1px #8f2512",
        cursor: "pointer",
        padding: "20px 10px",
        '@sm': {
            padding: "30px 20px",
        }
    },
    containerBackground: {
        transition: "200ms",
        position: "absolute",
        inset: 0,
        width: "100%",
        height: "100%",
        objectFit: "cover",
        filter: "brightness(0.7) blur(3px)",
        transform: "scale(1.1)",
    },
    title: {
        fontSize: "25px",
        pl: "15px",
        '@xs': {
            fontSize: "35px",
        },
        '@sm': {
            fontSize: "50px",
            pl: "20px",
        },
        '@lg': {
            pl: "25px",
            fontSize: "55px",
        }
    },
    rars: {
        fontSize: "21px",
        pl: "5px",
        '@sm': {
            fontSize: "30px",
        },
        '@lg': {
            fontSize: "40px",
            pl: "10px",
        }
    },
    pushkinLogo: {
        display: "flex",
        flexDirection: "column",
        justify: "center",
        width: "20px",
        marginLeft: "10px",
        '@sm': {
            width: "30px",
        },
        '@lg': {
            width: "40px",
        }
    },
    datetimeContainer: {
        width: "max-content",
        flexDirection: "column",
        alignItems: "center",
    },
    date: {
        width: "max-content",
        fontSize: "25px",
        textTransform: "uppercase",
        '@sm': {
            fontSize: "35px",
        },
        '@lg': {
            fontSize: "40px"
        }
    },
    time: {
        color: "#a5a5a5",
        fontSize: "20px",
        width: "max-content",
        textTransform: "uppercase",
        '@sm': {
            fontSize: "25px",
        },
        '@lg': {
            fontSize: "32px",
        }

    },
    buttonText: {
        '@sm': {
            fontSize: "25px",
        },
        '@lg': {
            fontSize: "32px",
        }
    },
    buttonContainer: {
        display: "none",
        '@xs': {
            display: "",
        }
    },
    button: {
        '@sm': {
            w: "150px",
            h: "40px",
        },
        '@lg': {
            w: "245px",
            h: "50px",
        }
    }
}

export default PosterItem;