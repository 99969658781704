import { Input } from "@nextui-org/react";
import "./TInput.css";


export default function TInput({
    placeholder, width, css, 
    onBlur, onFocus, contentRight,
    onContentClick, className, bindings,
    light
}) {
    return <Input
                css={css}
                placeholder={placeholder}
                bordered color="primary"
                className={`tinput ${className ? className : ""} ${light ? "light" : ""}`}
                width={width}
                onFocus={onFocus}
                onBlur={onBlur}
                animated={false}
                contentRight={contentRight}
                onContentClick={onContentClick}
                {...bindings}
                />
}