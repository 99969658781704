import { useEffect } from 'react';
import axios from '../lib/axios/axios';
import useValue from './useValue';
import {config} from "@nextui-org/react";

function useFetch({ initial, api, params, errClb, deps}) {
    const data = useValue(initial);
    const isLoading = useValue(true);
    const status = useValue(null);
    const hasMore = useValue(true);

    const depsEffect = deps ? [...deps] : []

    useEffect(
        () => {
            isLoading.set(true);
            axios
                .get(api, {
                    params
                })
                .then((resp) => {
                    isLoading.set(false);
                    if (resp.status === 204) {
                        hasMore.set(false);
                        return;
                    }
                    hasMore.set(true);
                    status.set(resp.status);
                    data.set(resp.data);
                })
                .catch((err) => {
                    isLoading.set(false);
                    if (errClb) {
                        errClb(err);
                    }
                });
        },
        depsEffect
    );

    return {
        data: data.value,
        isLoading: isLoading.value,
        hasMore: hasMore.value,
        status: status.value,
        set: data.set,
    };
}

export default useFetch;