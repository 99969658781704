import { Row} from "@nextui-org/react";
import TText from "../../Common/TText";
import ArrowDown from "../../../images/ArrowDown.svg";

const FooterTitle = ({text, mobileVersion, isOpen, onClick}) => (
    <Row css={{
        height: "35px",
        cursor: mobileVersion ? "pointer" : "",
        '@md': {
            height: "42px",
        }
    }} align={"center"} onClick={onClick}>
        <TText css={{
            color: "white",
            fontSize: "32px",
            '@md': {
                fontSize: "42px",
            }
        }}>{text}</TText>
        {mobileVersion &&
            <img src={ArrowDown} style={{marginLeft: "5px", filter: "invert(1)", transform: `rotate(${isOpen ? 180 : 0}deg)`, transition: "300ms"}}/>
        }
    </Row>)

export default FooterTitle;