import {Col, Row, Spacer, Text} from "@nextui-org/react";
import ArrowLeft from "../../../../images/arrowLeft.svg"
import moment from "moment";
import {useMemo} from "react";
import TText from "../../../Common/TText";

export default function PosterCalendarControls({size, currentMonth, currentYear, onNextClick, onPrevClick}) {

    const responsiveSize = () => {
        switch (size) {
            case "m":
                return {
                    height: "40px",
                    fontSize: "36px",
                    buttonWidth: "40px",
                    maxWidth: ""
                }
            default:
                return {
                    height: "70px",
                    fontSize: "48px",
                    buttonWidth: "50px",
                    maxWidth: "350px"
                }
        }
    }

    const activePreviousButton = useMemo(() => {
        return currentYear === moment().year() && currentMonth === moment().month()
    }, [currentYear, currentMonth])

    return (
        <Row align={"center"} justify={"center"}>
            <Row align={"center"} justify={"space-between"}
                 css={{maxWidth: responsiveSize().maxWidth, h: responsiveSize().height}}>
                <Col css={{w: "max-content", cursor: "pointer"}} onClick={onPrevClick}>
                    <img style={{
                        filter: !activePreviousButton ? "invert(100%)" : "",
                        width: responsiveSize().buttonWidth, position: "relative"
                    }}
                         src={ArrowLeft}/>
                </Col>
                <Spacer x={2}/>
                <TText light css={{
                    textTransform: "capitalize",
                    fontSize: responsiveSize().fontSize,
                    position: "relative",
                    top: "-5px"
                }}>
                    {moment().month(currentMonth).format("MMMM")}
                </TText>
                <Spacer x={2}/>
                <Col css={{w: "max-content", cursor: "pointer"}} onClick={onNextClick}>
                    <img style={{
                        filter: "invert(100%)",
                        transform: "rotate(180deg)",
                        width: responsiveSize().buttonWidth
                    }} src={ArrowLeft}/>
                </Col>
            </Row>
        </Row>

    )
}