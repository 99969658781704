import { Grid } from "@nextui-org/react";
import TText from "../../Common/TText";

const FooterText = ({text, link}) => (
    <Grid css={{pl: 0}} xs={12/2} sm={12 / 4} md={12}>
        {link && <a href={link} target="_blank"><FText text={text}/></a>} 
        {!link && <FText text={text}/>}
    </Grid>
)

const FText = ({text}) => (
    <TText light css={{
            w: "max-content",
            color: "white",
            fontSize: "16px",
            letterSpacing: "-1px",
            '@xs': {
                fontSize: "21px",
            }
        }}>{text}</TText>
)

export default FooterText;