import {Row, Spacer} from "@nextui-org/react";
import {IconBrandTelegram, IconBrandVk} from "@tabler/icons-react";

export const Socials = () => {
    return (
        <Row>
            <a href={"https://t.me/teatrarm"} target={"_blank"}>
                <IconBrandTelegram cursor={"pointer"} color={"white"} stroke={1.5}/>
            </a>
            <Spacer x={0.5}/>
            <a href={"https://vk.com/teatrarm"} target={"_blank"}>
                <IconBrandVk cursor={"pointer"} color={"white"} stroke={1.5}/>
            </a>
        </Row>
    )
}