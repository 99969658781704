import BlockTitle from "../../MainPage/BlockTitle";
import PosterList from "../Poster/PosterList";
import PushkinDescription from "./PushkinDescription";
import useValue from "../../../hooks/useValue";
import moment from "moment";
import { useEffect } from "react";
import axios from "../../../lib/axios/axios";
import { API } from "../../../api/api";
import { Spacer } from "@nextui-org/react";

function PushkinCardPage() {
    const currentMonth = useValue(moment().month());
    const currentYear = useValue(moment().year());
    const isLoading = useValue(true);

    const shows = useValue([]);

    useEffect(() => {
        isLoading.set(true);
        axios
            .get(API.poster + `?year=${currentYear.value}&month=${currentMonth.value + 1}`)
            .then(resp => {
                isLoading.set(false);
                if (resp.status !== 200) {
                    return;
                }
                shows.set(resp.data.filter(item => moment(item.at) >= moment()));
            })
            .catch(err => {
                isLoading.set(false);
            })
    }, [currentMonth.value, currentYear.value]);


    return (
        <>
            <BlockTitle color={"tblack"}>Пушкинская карта</BlockTitle>
            <PushkinDescription/>
            <BlockTitle color={"tblack"} h={3}>Спектакли по пушкинской карте</BlockTitle>
            <PosterList shows={shows.value} currentMonth={currentMonth} currentYear={currentYear} isLoading={isLoading.value}/>
            {isLoading.value && <Spacer y={1000}/>}
        </>
    );
}

export default PushkinCardPage;