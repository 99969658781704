import { useEffect } from "react";
import BlockTitle from "../../MainPage/BlockTitle";
import { API } from "../../../api/api";
import useValue from "../../../hooks/useValue";
import NewsItem from "./NewsItem/NewsItem";
import axios from "../../../lib/axios/axios";
import useWindowSize from "../../../hooks/useWindowSize";
import {Row, Spacer} from "@nextui-org/react";
import useFetchOffsetPagination from "../../../hooks/useFetchOffsetPagination";
import useInfiniteScroll from "react-infinite-scroll-hook";
import SimpleLogoLoader from "../../Common/Loader/SimpleLogoLoader";

function NewsPage() {
    const windowSize = useWindowSize();

    const  news= useFetchOffsetPagination({
        initial: [],
        api: API.news,
        limit: 10,
    })

    return (
        <>
            <BlockTitle color={"tblack"}>Новости</BlockTitle>
            {news.data.map((news, idx, arr) => (
                <NewsItem key={news.id} news={news} last={idx === arr.length - 1} isMobile={windowSize.width < 1280}/>
            ))}
            {(news.isLoading || news.hasMore) &&
                <Row justify={"center"}>
                    <Spacer/>
                    <div ref={news.scrollRef}>
                        <SimpleLogoLoader containerSize={{
                            width: 100,
                            height: 100,
                        }} padding={10}/>
                    </div>
                </Row>
            }
        </>
    );
}

export default NewsPage;