import { Navbar } from '@nextui-org/react';
import logo from '../../images/SimpleLogoWithoutBackground.svg';
import TText from '../Common/TText';
import TCollapse from '../Common/TCollapse';
import { Link, useLocation } from 'react-router-dom';
import logoOnlyText from './logoOnlyText.svg';
import useValue from '../../hooks/useValue';
import { useCallback, useEffect, useRef } from 'react';

function MobileHeader({ items }) {
    const titleFontCss = {
        fontSize: '27px',
        weight: 500,
        cursor: 'pointer',
    };

    const clickable = {
        fontSize: '25px',
        cursor: 'pointer',
        padding: '5px 10px',
        '&:hover': {
            border: '1px solid',
        },
    };

    const toggleRef = useRef(null);
    const navbarIsOpen = useValue(false);
    const location = useLocation();

    const onSelectItem = useCallback(() => {
        if (!navbarIsOpen.value) {
            return;
        }
        toggleRef.current.click();
    }, [navbarIsOpen.value]);

    useEffect(() => {
        onSelectItem();
    }, [location.pathname, onSelectItem]);

    const collapseItems = items.map((item) => {
        if (item.link) {
            return (
                <Link to={item.link} onClick={onSelectItem}>
                    <TText light css={titleFontCss}>
                        {item.name}
                    </TText>
                </Link>
            );
        }

        return (
            <TCollapse
                title={
                    <TText light css={titleFontCss}>
                        {item.name}
                    </TText>
                }
                content={
                    <ul style={{ margin: '0' }}>
                        {item.items.map((item) => (
                            <Link to={item.link} onClick={onSelectItem}>
                                <li>
                                    <TText light css={clickable}>
                                        {item.name}
                                    </TText>
                                </li>
                            </Link>
                        ))}
                    </ul>
                }
            />
        );
    });

    return (
        <Navbar disableShadow>
            <Navbar.Brand>
                <Link to="/" onClick={onSelectItem}>
                    <img src={logo} width={'30px'} />
                </Link>
            </Navbar.Brand>
            <Navbar.Content>
                <Link to="/" onClick={onSelectItem}>
                    <img src={logoOnlyText} style={{ height: '45px' }} />
                </Link>
            </Navbar.Content>
            <Navbar.Toggle
                ref={toggleRef}
                showIn={'xl'}
                onChange={(isOpen) => navbarIsOpen.set(isOpen)}
            />
            <Navbar.Collapse>
                {collapseItems.map((item, index) => (
                    <Navbar.CollapseItem key={'header-' + index}>
                        {item}
                    </Navbar.CollapseItem>
                ))}
                <Navbar.CollapseItem>
                    <Link to={"/pages/pushkin"} onClick={onSelectItem}>
                        <TText light css={titleFontCss}>
                            Пушкинская карта
                        </TText>
                    </Link>
                </Navbar.CollapseItem>
            </Navbar.Collapse>
        </Navbar>
    );
}

export default MobileHeader;
