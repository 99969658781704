import { Col, Row, Text } from "@nextui-org/react";
import TText from "../../../Common/TText";
import moment from "moment";
import { TButton } from "../../../Common/TButton";
import { useNavigate } from "react-router-dom";
import {imageUrl} from "../../../../api/api";

function NewsItemDesktop({news, last}) {
    const navigate = useNavigate();

    const onClick = () => navigate(`/pages/news/${news.id}`);

    return (
        <Row css={{...style.container, borderBottom: last ? "none" : "$primary solid 2px"}} className="news-item" onClick={onClick}>
            <Col css={style.imageContainer}>
                <img src={imageUrl(news.image)} style={style.image}/>
            </Col>
            <Col css={style.contentContainer}>
                <TText css={style.title} light className={"news-item__title"}>{news.title}</TText>
                <Row css={style.descriptionContainer}>
                    <Text css={style.description}>{news.summary}</Text>
                </Row>
                <Row justify="space-between" align="center">
                    <TText css={style.date}>{moment(news.created_at).format("DD MMMM YYYY") }</TText>
                    <TButton><TText size={21} light onClick={onClick}>Подробнее...</TText></TButton>
                </Row>
            </Col>
        </Row>
    );
}

const style = {
    container: {
        // height: "350px",
        padding: "20px 0",
        borderBottom: "$primary solid 2px",
        cursor: "pointer",
        '@xs': {
            padding: "30px 0",
        },
        '@md': {
            padding: "50px 0",
            height: "360px",
        }
    },
    image: {
        width: "100%",
        height: "100%",
        objectFit: "cover"
    },
    imageContainer: {
        display: "none",
        '@md': {
            height: "100%",
            width: "550px",
            marginRight: "80px",
            display: "block",
        }
    },
    title: {
        fontSize: "32px",
        marginBottom: "20px",
        '@md': {
            fontSize: "48px",
        }
    }, 
    descriptionContainer: {
        flexGrow: 1,
        overflow: "hidden",
        marginBottom: "10px",
    },
    description: {
        display: "-webkit-box",
        overflow: "hidden",
        "-webkit-box-orient": "vertical",
        "-webkit-line-clamp": 5,
        whiteSpace: "pre-wrap",
        '@xs': {
            "-webkit-line-clamp": 3,
        }
    },
    contentContainer: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
    },
    date: {
        fontSize: "21px",
        color: "gray",
    }
    
};

export default NewsItemDesktop;