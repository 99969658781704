import {Children} from "react";
import {StyledDiv} from "./StyledDiv";


export default function TContainer({children, css}) {
    return (
        <StyledDiv css={{...styles, ...css}}>
            {Children.map(children, child => (child))}
        </StyledDiv>

    )
}

const styles = {
    pl: 'calc(2 * $sm)',
    pr: 'calc(2 * $sm)',
    flexWrap: 'wrap',
    display: 'block',
    mr: 'auto',
    ml: 'auto',
    w: '100%',
    '@xs': {
        w: '650px'
    },
    '@sm': {
        w: '960px',
    },
    '@md': {
        w: '1280px',
    },
    '@lg': {
        w: '1400px',
    },
    '@txl': {
        w: '1920px',
    }
}