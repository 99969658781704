import React, {useMemo} from "react"
import ContentLoader from "react-content-loader"
import {responsiveLoaderSize} from "./Utils";

const SimpleLogoLoader = ({containerSize, padding}) => {
    const responsiveSize = useMemo(() => {
        return responsiveLoaderSize(containerSize, {
            width: 220,
            height: 296
        }, padding);
    }, [containerSize.width, containerSize.height])
    return (
        <ContentLoader
            speed={2}
            width={responsiveSize.width}
            height={responsiveSize.height}
            viewBox="0 0 220 296"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
        >
            <path
                d="M 160.61 0 H 6 C 2.69 0 0 2.69 0 6 v 135.13 c 0 3.31 2.69 6 6 6 l 71.16 0.06 c -5.18 8.8 -10.87 17.35 -30.29 28.74 c -14.03 8.23 -32.32 10.77 -41.35 11.48 c -3.15 0.25 -5.55 2.91 -5.51 6.06 v 81.51 c 0.05 3.4 2.91 6.08 6.31 5.92 c 81.47 -3.95 122.08 -85.15 141.53 -141.48 c 16.69 -48.34 18.59 -113.45 18.77 -133.38 c 0.03 -3.33 -2.67 -6.04 -6 -6.04 z M 36.25 80.65 V 54.5 s 11.13 2.07 21.49 8.54 c 10.36 6.47 17.35 17.61 17.35 17.61 H 36.25 z"/>
            <path
                d="M 188.82 207.4 c 9.25 -6.32 18.94 -9.61 25.41 -10.19 c 2.43 -0.22 4.42 -1.98 4.42 -4.42 V 93.1 c 0 -2.44 -1.98 -4.42 -4.42 -4.42 h -49.75 c -2.8 17.74 -6.76 35.93 -12.38 52.22 c -9.79 28.34 -21.12 52.55 -33.95 72.61 c 16.42 37.94 44.93 79.87 94.96 82.29 c 2.51 0.12 4.62 -1.85 4.65 -4.36 l 0.83 -60.19 c 0.03 -2.33 -1.74 -4.29 -4.06 -4.47 l -43.65 -0.62 s 3.8 -9.11 17.93 -18.76 z m -25.53 -59.25 s 5.15 -8.21 12.79 -12.98 c 7.64 -4.77 15.85 -6.3 15.85 -6.3 v 19.28 h -28.64 z"/>
        </ContentLoader>
    )
}

export default SimpleLogoLoader

