import {Button, Col, Row, Text} from "@nextui-org/react";
import pushkin from '../../images/pushkinCardLogo.svg'

export default function PushkinButton({simple, onClick}) {
    const height = "60px";
    const lineHeight = "20px";
    return (
        <Row css={{w: "max-content", h: height, cursor: "pointer"}} align={"center"} onClick={onClick}>
            <Col >
                <img src={pushkin} height={height}/>
            </Col>
            {!simple &&
                <Col>
                    <Row>
                        <Text css={{fontWeight: 900, lineHeight}}>ПУШКИНСКАЯ</Text>
                    </Row>
                    <Row>
                        <Text css={{fontWeight: 900, letterSpacing: "15px", lineHeight}}>КАРТА</Text>
                    </Row>
                </Col>
            }

        </Row>
    )
}