import { Col, Row, Text } from "@nextui-org/react";
import TText from "../../../Common/TText";
import moment from "moment";
import { TButton } from "../../../Common/TButton";
import { useNavigate } from "react-router-dom";
import {imageUrl} from "../../../../api/api";

function NewsItemMobile({news, last}) {

    const navigate = useNavigate();

    const onClick = () => navigate(`/pages/news/${news.id}`);

    return (
        <Col css={{...style.container, borderBottom: last ? "none" : "$primary solid 2px"}} className="news-item" onClick={onClick}>
            <Row>
                <TText css={style.title} light className={"news-item__title"}>{news.title}</TText>
            </Row>
            <Row css={style.imageContainer} justify="center">
                <img src={imageUrl(news.image)} style={style.image}/>
            </Row>
            <Col css={style.contentContainer}>
                <Row css={style.descriptionContainer}>
                    <Text css={style.description}>{news.summary}</Text>
                </Row>
                <Row justify="space-between" align="center">
                    <TText css={style.date}>{moment(news.created_at).format("DD MMMM YYYY") }</TText>
                    <TButton><TText light size={21} onClick={onClick}>Подробнее...</TText></TButton>
                </Row>
            </Col>
        </Col>
    );
}

const style = {
    container: {
        // height: "350px",
        padding: "0 0 20px 0",
        margin: "0 0 20px 0",
        borderBottom: "$primary solid 2px",
        cursor: "pointer",
        '@xs': {
            padding: "0 0 30px 0",
            margin: "0 0 30px 0",
        },
        '@md': {
            padding: "0 0 50px 0",
            margin: "0 0 50px 0",
            height: "360px",
        }
    },
    image: {
        height: "100%",
        width: "100%",
        maxWidth: "560px",
        objectFit: "cover",
    },
    imageContainer: {
        height: "350px",
        marginBottom: "15px",
    },
    title: {
        fontSize: "32px",
        marginBottom: "20px",
        '@sm': {
            fontSize: "48px",
        }
    }, 
    descriptionContainer: {
        flexGrow: 1,
        overflow: "hidden",
        marginBottom: "20px",
    },
    description: {
        display: "-webkit-box",
        overflow: "hidden",
        "-webkit-box-orient": "vertical",
        "-webkit-line-clamp": 5,
        whiteSpace: "pre-wrap",
    },
    contentContainer: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
    },
    date: {
        fontSize: "18px",
        color: "gray",
    }
    
};

export default NewsItemMobile;