import { useEffect, useState } from "react";
import useWindowSize from "./useWindowSize";

const initData = {
        xs: false,
        sm: false,
        md: false,
        lg: false,
        xl: false,
        gteSm: false, 
        gteMd: false, 
        gteLg: false, 
};

export default function useBreakpoints() {
    const windowSize = useWindowSize();

    const [data, setData] = useState({
        xs: windowSize.width < 650,
        sm: windowSize.width >= 650 && windowSize.width < 960,
        md: windowSize.width >= 960 && windowSize.width < 1280,
        lg: windowSize.width >= 1280 && windowSize.width < 1400,
        xl: windowSize.width >= 1400,
        gteSm: windowSize.width >= 650,
        gteMd: windowSize.width >= 960,
        gteXl: windowSize.width >= 1280,
    });

    useEffect(() => {
        if (windowSize.width >= 1400) {
            setData({...initData, xl: true, gteSm: true, gteMd: true, gteLg: true});
            return;
        }
        if (windowSize.width >= 1280) {
            setData({...initData, lg: true, gteSm: true, gteMd: true, gteLg: true});
            return;
        }
        if (windowSize.width >= 960) {
            setData({...initData, md: true, gteSm: true, gteMd: true});
            return;
        }
        if (windowSize.width >= 650) {
            setData({...initData, sm: true, gteSm: true});
            return;
        }
        if (windowSize.width < 650) {
            setData({...initData, xs: true});
            return;
        }
    }, [windowSize.width]);

    return data;
}