import leftMask from "../../images/leftSadMask.svg";
import rightMask from "../../images/rightHappyMask.svg";


function Mask({right, dark}) {
    return (
        <img src={right ? rightMask : leftMask} style={{
            position: "absolute",
            left: right ? "" : 0,
            right: right ? 0 : "", 
            top: "50%",
            height: "40%",
            transform: `translate(0, ${right ? "-100" : "0"}%)`,
            filter: "invert(99%) sepia(0%) saturate(916%) hue-rotate(317deg) brightness(96%) contrast(80%)",
            opacity: dark ? "10%" : "30%",
        }}/>
    )
}

export default Mask;