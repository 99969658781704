import { Row } from "@nextui-org/react";
import TText from "../../../Common/TText";
import "./QuestionItem.css";

function QuestionItem({title, onClick, isLast, isActive}) {
    return (
        <Row className={`question-item-dsktp${isActive ? " question-item-dsktp__active " : ""}` } onClick={onClick} align="center">
            <TText css={style.title}>{title}</TText>
        </Row>
    );
}

const style = {
    title: {
        fontSize: "32px",
    }
};

export default QuestionItem;