import TContainer from "../Common/TContainer/TContainer";
import Editor from "../../lib/Editor/Editor";
import useValue from "../../hooks/useValue";
import Output from "../../lib/editor-output/editor-output";
import { TButton } from "../Common/TButton";
import { Row, Spacer } from "@nextui-org/react";
import TText from "../Common/TText";

function BoxEditor() {
    const editorInstance = useValue(null);
    const editorData = useValue(localStorage.getItem("box-data") ? JSON.parse(localStorage.getItem("box-data")): {});
    const mode = useValue('w');

    const onSave = () => {
        editorInstance.value.save()
        .then(data => {
            navigator.clipboard.writeText(JSON.stringify(data));
            editorData.set(data); 
            localStorage.setItem("box-data", JSON.stringify(data));
        })
        .catch(error => {
        })
    }

    const onToggle = () => {
        if (mode.value === 'w') {
            onSave();
            editorInstance.value.destroy();
            mode.set('r');
        } else {
            mode.set('w')
        }
    }

    const onClear = () => {
        editorInstance.value.blocks.clear();
    }

    return (
        <TContainer>
            {mode.value === 'w' && 
                <Editor holder="box-editor" instanceSetter={editorInstance} data={editorData.value}/>
            }
            {(mode.value === 'r' && editorData.value.blocks?.length > 0) &&
                <Output data={editorData.value}/>
            }
            {(mode.value === 'r' && editorData.value.blocks?.length === 0) &&
                <Row justify="center" css={{m: "36px"}}>
                    <TText size={32}>Нечего отображать</TText>
                </Row>
            }
            <Spacer y={1}/>
            <Row justify="space-between">
                {mode.value === 'w' &&
                    (<>
                        <TButton onClick={onSave}>Сохранить</TButton>
                        <TButton onClick={onClear}>Очистить</TButton>
                    </>)
                }
                <TButton onClick={onToggle}>{mode.value === 'w' ? "Режим чтения" : "Режим редактирования"}</TButton>
            </Row>
            <Spacer y={3}/>
        </TContainer>
    );
}

export default BoxEditor;