import {Card, Col, Row, Spacer} from "@nextui-org/react";
import TText from "./TText";
import {adaptiveImageUrl, adaptiveSrcSet, imageUrl} from "../../api/api";

function TCardBase({img, title, action, theme, containerCSS, footerCSS, titleCSS, hintCSS, bodyCSS, className, hint}) {
    return (
        <Card variant={"flat"} isPressable css={{...styles.card, ...containerCSS, background: "transparent"}} onClick={action} className={className}>
            <Card.Body css={{p: 0, ...bodyCSS}}>
                <Card.Image
                    src={adaptiveImageUrl(img, 500, 700)}
                    width="100%"
                    height="100%"
                    objectFit="cover"
                    alt="Card example background"
                />
            </Card.Body>
            <Card.Footer css={{...styles.cardFooter, ...footerCSS, background: "transparent"}}>
                <Col>
                    {hint &&
                        <Row justify={"flex-center"}>
                            <TText css={{...styles.hint, ...hintCSS, color: theme === "dark" ? "white" : "gray"}}>{hint}</TText>
                            <Spacer y={1}/>
                        </Row>
                    }
                    <Row justify={"flex-center"}>
                        <TText css={{...styles.title, ...titleCSS, color: theme === "dark" ? "white" : "black"}}>{title}</TText>
                    </Row>
                </Col>
            </Card.Footer>
        </Card>
    )
}

export default TCardBase;

const styles = {
    card: {
        borderRadius: 0,
    },
    cardFooter: {
        background: "$darkBackground",
        borderRadius: 0,
        pt: '$sm',
        pl: 0,
        pr: 0,
        pb: 0,
    },
    title: {
    },
    hint: {
    }
}