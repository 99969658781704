import { Outlet } from 'react-router-dom';
import TContainer from '../Common/TContainer/TContainer';
import PublicHeader from '../Header/PublicHeader';

function Layout() {
    return (
        <div
            style={{
                position: 'fixed',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                overflowY: 'scroll',
            }}
        >
            <PublicHeader />
            <TContainer>
                <Outlet />
            </TContainer>
        </div>
    );
}

export default Layout;
