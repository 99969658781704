import { useEffect } from "react";
import BlockTitle from "../../MainPage/BlockTitle";
import {API, imageUrlOutput} from "../../../api/api";
import useValue from "../../../hooks/useValue";
import FAQDesktop from "./FAQDesktop/FAQDesktop.js";
import axios from "../../../lib/axios/axios";
import { Spacer } from "@nextui-org/react";
import useWindowSize from "../../../hooks/useWindowSize";
import FAQMobile from "./FAQMobile/FAQMobile";
// import { useHistory } from "react-router-dom";


function FAQPage() {
    const windowSize = useWindowSize();
    const isMobileVersion = useValue(windowSize.width < 960);

    const questions = useValue([]);
    const questionsIsLoading = useValue(true);

    const selectedQuestion = useValue(null);

    const answer = useValue(null);
    const answerIsLoading = useValue(true);

    useEffect(() => {
        if (windowSize.width < 960 && !isMobileVersion.value) {
            isMobileVersion.set(true);
            return;
        }
        if (windowSize.width >= 960 && isMobileVersion.value) {
            isMobileVersion.set(false);
        }
    }, [windowSize.width])

    useEffect(() => {
        axios.get(API.frequentlyAskedQuestions)
        .then(resp => {
            questions.set(resp.data);
            questionsIsLoading.set(false);

            if (resp.data) {
                selectedQuestion.set(resp.data[0].id);
            }
        })
    }, []);

    useEffect(() => {
        if (!selectedQuestion.value) {
            return;
        }
        answerIsLoading.set(true);

        axios.get(API.frequentlyAskedQuestion(selectedQuestion.value))
        .then(resp => {
            answerIsLoading.set(false);
            resp.data.content = imageUrlOutput(resp.data.content)
            answer.set(resp.data);
        })
    }, [selectedQuestion.value])

    return (
        <>
            <BlockTitle color={"tblack"}>Визит в театр</BlockTitle>
            {isMobileVersion.value && <FAQMobile questions={questions.value} questionSelector={selectedQuestion} answer={answer.value}
                        answerIsLoading={answerIsLoading.value} questionsIsLoading={questionsIsLoading.value}/>}
            {!isMobileVersion.value && <FAQDesktop questions={questions.value} questionSelector={selectedQuestion} answer={answer.value}
                        answerIsLoading={answerIsLoading.value} questionsIsLoading={questionsIsLoading.value}/>}
        </>
    )
}

export default FAQPage;