import Header from './Header';

const items = [
    { name: 'Афиша', link: '/pages/poster' },
    {
        name: 'Репертуар',
        items: [
            { name: 'Текущее', link: '/pages/shows' },
            { name: 'По Пушкинской карте', link: '/pages/pushkin' },
            { name: 'Архив', link: '/pages/archive' },
        ],
    },
    {
        name: 'О театре',
        items: [
            { name: 'Коллектив', link: '/pages/employees' },
            { name: 'История', link: '/pages/history' },
        ],
    },
    { name: 'Визит в театр', link: '/pages/faq' },
    { name: 'Новости', link: '/pages/news' },
];

function PublicHeader() {
    return <Header items={items} />;
}

export default PublicHeader;
