import {Grid, Popover, Row, useInput} from "@nextui-org/react";
import TInput from "../TInput/TInput";
import useWindowSize from "../../../hooks/useWindowSize";
import { IconFilter, IconSearch } from "@tabler/icons-react";
import TText from "../TText";
import useValue from "../../../hooks/useValue";
import "./FilterController.css";
import TSelect from "../TSelect/TSelect";
import { API } from "../../../api/api";
import {useEffect, useMemo} from "react";
import useFetch from "../../../hooks/useFetch";

function FilterController({params}) {
    const windowSize = useWindowSize();
    const expandedControlPanel = useValue(false);
    const waitingTimeOut = useValue(null);
    const tempParams = useValue({});

    const genre = useFetch({
        initial: [],
        api: API.genre,
    })

    const search = useInput('');


    const filterOptions = useMemo(() => {
        return [
            {label: "Возраст", id: "age",
                options: [
                    {name: "0+", id: 0},
                    {name: "6+", id: 6},
                    {name: "12+", id: 12},
                    {name: "16+", id: 16},
                    {name: "18+", id: 18}
                ],
                setter: value => {
                    params.update({age: value === null ? null : value.id})
                    tempParams.update({age: value})
                },
                init: tempParams.value.age,
            },
            {label: "Жанр", id: "genre",
                options: genre.data.map(item => ({name: item.title, id: item.id})),
                setter: value => {
                    params.update({genre: value === null ? null : value.id})
                    tempParams.update({genre: value})
                },
                init: tempParams.value.genre,
            },
            {label: "Актер", id: "actor",
                autocompleteAPI: API.employees,
                autocompleteAPIParams: {category: "actor", limit: 10},
                setter: value => {
                    params.update({actor: value === null ? null : value.id})
                    tempParams.update({actor: value})
                },
                init: tempParams.value.actor,
            }
        ]
    }, [genre.data, params, tempParams])

    useEffect(() => {
        if (waitingTimeOut.value) {
            clearTimeout(waitingTimeOut.value)
        }
        const timeOut = setTimeout(() => {
            params.update({search: search.value});
            waitingTimeOut.set(null);
        }, 300)

        waitingTimeOut.set(timeOut)
    }, [search.value]);

    return (
        <Row css={style.controlContainer} className={`control-panel${expandedControlPanel.value ? " expanded-control-panel" : ""}`} align="center" fluid={false}>
            <TInput placeholder="Поиск..." css={style.input}
                    onFocus={() => expandedControlPanel.set(true)}
                    onBlur={() => expandedControlPanel.set(false)}
                    contentRight={<IconSearch stroke={3}/>}
                    className={"search-input"}
                    bindings={search.bindings}
                    />
            {windowSize.width <= 650 &&
                <Popover placement="bottom-right" isBordered disableShadow offset={20}>
                    <Popover.Trigger>
                        <IconSearch className="icon-search"/>
                    </Popover.Trigger>
                    <Popover.Content css={{border: "none", padding: "10px"}}>
                        <TInput
                            placeholder="Поиск..."
                            css={style.popoverInput}
                            className={"search-input-popover"}
                            bindings={search.bindings}
                            contentRight={<IconSearch stroke={3}/>}
                        />
                    </Popover.Content>
                </Popover>
            }
            <div style={style.filterButtonContainer}>
                <Popover placement="bottom-right" isBordered disableShadow offset={20}>
                    <Popover.Trigger>
                        <div>
                            <IconFilter className="icon-filter"/>
                            <TText css={style.filterButton} className={"text-filter"}>Фильтры</TText>
                        </div>
                    </Popover.Trigger>
                    <Popover.Content css={style.popoverFilterContent}>
                        <Grid.Container css={{w: "300px"}} gap={1}>
                            {filterOptions.map((item, idx, arr) => (
                                <>
                                    <Grid xs={4} alignItems="center">
                                        <TText size={24}>{item.label}</TText>
                                    </Grid>
                                    <Grid xs={8}>
                                        <TSelect
                                            initSelected={item.init}
                                            initOptions={item.options}
                                            autocompleteAPI={item.autocompleteAPI}
                                            autocompleteAPIParams={item.autocompleteAPIParams}
                                            initZIndex={arr.length - idx}
                                            setSelected={item.setter}
                                            key={item.id}
                                            />
                                    </Grid>
                                </>
                            ))}
                        </Grid.Container>
                    </Popover.Content>
                </Popover>
                {
                    (
                        (!!search.value && search.value !== '') ||
                        tempParams.value.age || tempParams.value.actor || tempParams.genre
                    ) && <div style={style.filterButtonIndicator}/>
                }
            </div>
        </Row>
    )
}

const style = {
    controlContainer: {
        paddingLeft: "15px",
        justifyContent: "flex-end",
        position: "relative",
        top: "3px",
        transition: "300ms",
    },
    filterButtonContainer: {
        position: "relative",
        cursor: "pointer",
    },
    filterButton: {
        fontSize: "27px",
        padding: "0 15px",
    },
    filterButtonIndicator: {
        width: "8px",
        height: "8px",
        borderRadius: "100%",
        background: "#8f2512",
        position: "absolute",
        top: 0,
        right: 0
    },
    inputContainer: {
        position: "relative",
    },
    input: {
        flexGrow: 1,
    },
    popoverInput: {
        width: "250px",
    },
    popoverFilterContent: {
        border: "2px solid",
        borderRadius: 0,
        padding: "15px",
        overflow: "visible",
    }
}

export default FilterController;