import Output from "./editor-output";
import {useRef} from "react";

function OutputWrapper({data, maxHeight}) {
    const outputRef = useRef();

    const hidden = maxHeight < outputRef.current?.offsetHeight;

    return (
        <div style={style.wrapper(maxHeight ? maxHeight : "unset")}>
            <div ref={outputRef}>
                <Output data={data}/>
            </div>
            <div style={style.gradient(hidden)}/>
        </div>
    )
}

const style = {
    wrapper: (maxHeight) => ({
        width: "100%",
        maxHeight: maxHeight,
        overflow: "hidden",
        position: "relative",
    }),
    gradient: (visible) => ({
        display: !visible ? '' : 'none',
        background: 'linear-gradient(0deg, rgba(255,255,255,1) 30%, rgba(255,255,255,0) 100%)',
        width: '100%',
        height: '20%',
        position: 'absolute',
        bottom: 0,
    })
}

export default OutputWrapper;