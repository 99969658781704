import {createTheme} from "@nextui-org/react";

const defaultTheme = createTheme({
    type: "theater",
    theme: {
        breakpoints: {
            xs: '320px',
            sm: '960px',
            md: '1280px',
            lg: '1400px',
            txl: '1921px',
            xxs: '320px',
        },
        colors: {
            primary: '#8F2512',
            darkBackground: '#252525',
        }
    }
});

export default defaultTheme;