import BlockTitle from '../BlockTitle';
import HorizontalSlider from '../../Common/Slider/HorizontalSlider';
import FullContainer from '../../Common/FullContainer';
import { useEffect, useMemo } from 'react';
import TContainer from '../../Common/TContainer/TContainer';
import useValue from '../../../hooks/useValue';
import axios from '../../../lib/axios/axios';
import { Col, Row } from '@nextui-org/react';
import { API } from '../../../api/api';
import useElementSize from '../../../hooks/useElementSize';
import useDeviceSize from '../../../hooks/useDeviceSize';
import useWindowSize from '../../../hooks/useWindowSize';
import TCard from '../../Common/TCard';
import Mask from '../../Common/Mask';
import './TCardNews.css';
import {useNavigate} from "react-router-dom";

function News({}) {
    const deviceSize = useDeviceSize();
    const items = useValue([]);
    const navigate = useNavigate();

    useEffect(() => {
        axios
            .get(API.newsSlider)
            .then((resp) => {
                items.set(resp.data);
            })
            .catch(() => {});
    }, []);

    const windowSize = useWindowSize();
    const numberOfComponents = () => {
        if (windowSize.width <= 960) {
            return 2;
        }

        if (windowSize.height <= 750) {
            if (windowSize.width <= 1280) {
                return 2;
            }
            return 3;
        }

        return 4;
    };

    const cards = useMemo(() => {
        const resultItems = [];
        let tempSlide = [];
        for (let idx = 0; idx < items.value.length; idx++) {
            tempSlide.push(items.value[idx]);
            const mod = (idx + 1) % numberOfComponents();
            if (mod === 0 || idx === items.value.length - 1) {
                resultItems.push(
                    <div className="news-slide-container">
                        {tempSlide.map((item) => (
                            <TCard
                                title={item.title}
                                light
                                img={item.image}
                                onClick={() => navigate(`/pages/news/${item.id}`)}
                                classNameModificator={'news'}
                            />
                        ))}
                    </div>
                );
                tempSlide = [];
            }
        }
        return resultItems;
        // return items.value.map(item => (<TCard title={item.title} light img={item.img} action={() => {}} classNameModificator={"news"}/>));
    }, [items]);

    const [titleRef, titleSize] = useElementSize();

    return (
        <FullContainer css={{ background: 'white' }}>
            <Mask />
            <Mask right />
            <TContainer
                css={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                }}
            >
                <BlockTitle
                    color={'tblack'}
                    ref={titleRef}
                    css={{ mt: '50px' }}
                >
                    Новости
                </BlockTitle>
                <Row css={{ flexGrow: 1 }} align="center" justify="center">
                    <HorizontalSlider
                        items={cards}
                        severalComponentOnSlide={true}
                        numberOfComponentOnSlide={1}
                    />
                </Row>
            </TContainer>
        </FullContainer>
    );
}

export default News;
