import {useCallback, useRef, useState} from "react";
import useIsomorphicLayoutEffect from "./useIsomorphicLayoutEffect";
import useWindowSize from "./useWindowSize";

export default function useElementSize(deps) {
    if (!deps) {
        deps = [];
    }
    const ref = useRef();
    const windowSize = useWindowSize();
    const [elementSize, setElementSize] = useState({
        width: 0, height: 0, left: 0, top: 0,
    });

    const handleResize = useCallback(() => {
        if (!ref.current) {
            return
        }
        setElementSize({
            width: ref.current.offsetWidth,
            height: ref.current.offsetHeight,
            left: ref.current.offsetLeft,
            top: ref.current.offsetTop
        })
    }, [ref.current, ref.current?.offsetWidth, ref.current?.offsetHeight, ref.current?.offsetLeft, ref.current?.offsetTop, windowSize, ...deps])

    useIsomorphicLayoutEffect(() => {
        handleResize();
    }, [ref.current, ref.current?.offsetWidth, ref.current?.offsetHeight, ref.current?.offsetLeft, ref.current?.offsetTop, windowSize, ...deps]);
    return [ref, elementSize];
}