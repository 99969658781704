import {API} from "../../../api/api";

export function setMockShows(axiosMockAdapterInstance) {
    //SLIDER
    axiosMockAdapterInstance
        .onGet(API.slider)
        .reply(() => {
            return [
                200,
                [
                    {
                        img: "https://avatars.mds.yandex.net/get-afishanew/31447/b7f53bf11474336bf8d7cc59ff07e253/orig",
                        title: "Щелкунчик",
                        link: "https://quicktickets.ru/armavir-teatr-dramy-i-komedii/e53"
                    },
                    {
                        img: "https://www.modern-theatre.ru/assets/images/vojna-i-mir/vim-007-1.jpg",
                        title: "Война и мир",
                        link: "https://quicktickets.ru/armavir-teatr-dramy-i-komedii/e53"
                    },
                    {
                        img: "https://teatral-online.ru/i/ph/xl/xl_20210428115534.jpg",
                        title: "Мертвые души",
                        link: "https://quicktickets.ru/armavir-teatr-dramy-i-komedii/e53"
                    },
                    {
                        img: "https://www.teatrvfk.ru/wp-content/uploads/2017/07/IGP5156.jpg",
                        title: "Двенадцать месяцев",
                        link: "https://quicktickets.ru/armavir-teatr-dramy-i-komedii/e53"
                    }
                ]]
        })

    // current
    axiosMockAdapterInstance
        .onGet(API.currentShows)
        .reply(() => {
            return [
                200,
                [
                    {
                        img: "https://avatars.mds.yandex.net/get-afishanew/31447/b7f53bf11474336bf8d7cc59ff07e253/orig",
                        title: "Щелкунчик",
                        pegi: 16,
                        description: "Музыка Чайковского, рождественская сказка, мышиный король, ожившие куклы… Догадались, о чём идёт речь? Балет «Щелкунчик» Чайковского уже второй век не теряет своей притягательности для хореографов и зрителей. Балет поставлен на либретто Петипа по сказке Гофмана «Щелкунчик и мышиный король».",
                        duration: "2 часа 20 минут с 1 антрактом",
                        id: 1,
                    },
                    {
                        img: "https://www.modern-theatre.ru/assets/images/vojna-i-mir/vim-007-1.jpg",
                        title: "Война и мир",
                        pegi: 18,
                        description: "Мы творим, оформляем, вкладываем судьбы, парадоксы, безумие, любовь, радость, отчаяние. Мир красивый, эстетичный, театральный, очень хрупкий и как будто бы лёгкий — всё это поглощается кометой. Под этим облаком, этой заражённой войной зоной, вступают некие другие способы нашего рассказа.",
                        duration: "3 часа 10 минут с 2 антрактом",
                        id: 2,
                    },
                    {
                        img: "https://teatral-online.ru/i/ph/xl/xl_20210428115534.jpg",
                        title: "Мертвые души",
                        pegi: 16,
                        description: "«Галопад летел во всю пропалую: почтмейстерша, капитан-исправник, дама с голубым пером, дама с белым пером, грузинский князь Чипхайхилидзев, чиновник из Петербурга, чиновник из Москвы, француз Куку, Перхуновский, Беребендовский — всё поднялось и понеслось» (Н. В. Гоголь. «Мёртвые души»).",
                        duration: "1 час 45 минут с 1 антрактом",
                        id: 3,
                    },
                    {
                        img: "https://www.teatrvfk.ru/wp-content/uploads/2017/07/IGP5156.jpg",
                        title: "Двенадцать месяцев",
                        pegi: 12,
                        description: "«Двенадцать месяцев» — музыкальная комедия, где самые эффектные театральные технологии переплетаются с потрясающей музыкой и оригинальными режиссерскими решениями. Волшебные рождественские картины сопровождают каждую сцену спектакля, а появление самих двенадцати месяцев станет волшебством. ",
                        duration: "1 час 45 минут с 1 антрактом",
                        id: 4,
                    },
                    {
                        img: "https://www.modern-theatre.ru/assets/images/vojna-i-mir/vim-007-1.jpg",
                        title: "Война и мир",
                        pegi: 18,
                        description: "Мы творим, оформляем, вкладываем судьбы, парадоксы, безумие, любовь, радость, отчаяние. Мир красивый, эстетичный, театральный, очень хрупкий и как будто бы лёгкий — всё это поглощается кометой. Под этим облаком, этой заражённой войной зоной, вступают некие другие способы нашего рассказа.",
                        duration: "3 часа 10 минут с 2 антрактом",
                        id: 5,
                    },
                    {
                        img: "https://teatral-online.ru/i/ph/xl/xl_20210428115534.jpg",
                        title: "Мертвые души",
                        pegi: 16,
                        description: "«Галопад летел во всю пропалую: почтмейстерша, капитан-исправник, дама с голубым пером, дама с белым пером, грузинский князь Чипхайхилидзев, чиновник из Петербурга, чиновник из Москвы, француз Куку, Перхуновский, Беребендовский — всё поднялось и понеслось» (Н. В. Гоголь. «Мёртвые души»).",
                        duration: "1 час 45 минут с 1 антрактом",
                        id: 6,
                    },
                    {
                        img: "https://www.teatrvfk.ru/wp-content/uploads/2017/07/IGP5156.jpg",
                        title: "Двенадцать месяцев",
                        pegi: 12,
                        description: "«Двенадцать месяцев» — музыкальная комедия, где самые эффектные театральные технологии переплетаются с потрясающей музыкой и оригинальными режиссерскими решениями. Волшебные рождественские картины сопровождают каждую сцену спектакля, а появление самих двенадцати месяцев станет волшебством. ",
                        duration: "1 час 45 минут с 1 антрактом",
                        id: 7,
                    },
                    {
                        img: "https://teatral-online.ru/i/ph/xl/xl_20210428115534.jpg",
                        title: "Мертвые души",
                        pegi: 16,
                        description: "«Галопад летел во всю пропалую: почтмейстерша, капитан-исправник, дама с голубым пером, дама с белым пером, грузинский князь Чипхайхилидзев, чиновник из Петербурга, чиновник из Москвы, француз Куку, Перхуновский, Беребендовский — всё поднялось и понеслось» (Н. В. Гоголь. «Мёртвые души»).",
                        duration: "1 час 45 минут с 1 антрактом",
                        id: 8,
                    },
                ]]
        })

    // POSTER
    axiosMockAdapterInstance
        .onGet(/\/api\/shows\/scheduled\?year=\d+&month=\d+/)
        .reply((config) => {
                const match = config.url.match(/\/api\/shows\/scheduled\?year=(\d+)&month=(\d+)/);
                const year = match[1];
                const month = match[2];
                const date = (d, h) => {
                    return `${year}-${(month + "").padStart(2, "0")}-${(d + "").padStart(2, "0")}T${(h + "").padStart(2, "0")}:00:00`
                }
                return [200, [
                    {
                        datetime: date(5, 12),
                        show: {
                            id: 1,
                            img: "https://avatars.mds.yandex.net/get-afishanew/31447/b7f53bf11474336bf8d7cc59ff07e253/orig",
                            title: "Щелкунчик",
                            link: "https://quicktickets.ru/armavir-teatr-dramy-i-komedii/e53"
                        },

                    },
                    {
                        datetime: date(5, 18),
                        show: {
                            id: 2,
                            img: "https://www.modern-theatre.ru/assets/images/vojna-i-mir/vim-007-1.jpg",
                            title: "Война и мир",
                            link: "https://quicktickets.ru/armavir-teatr-dramy-i-komedii/e53"
                        },

                    },
                    {
                        datetime: date(13,10),
                        show: {
                            id: 3,
                            img: "https://teatral-online.ru/i/ph/xl/xl_20210428115534.jpg",
                            title: "Мертвые души",
                            link: "https://quicktickets.ru/armavir-teatr-dramy-i-komedii/e53"
                        },
                    },
                    {
                        datetime: date(13,13),
                        show: {
                            id: 1,
                            img: "https://avatars.mds.yandex.net/get-afishanew/31447/b7f53bf11474336bf8d7cc59ff07e253/orig",
                            title: "Щелкунчик",
                            link: "https://quicktickets.ru/armavir-teatr-dramy-i-komedii/e53"
                        }
                    },
                    {
                        datetime: date(13,18),
                        show: {
                            id: 2,
                            img: "https://www.modern-theatre.ru/assets/images/vojna-i-mir/vim-007-1.jpg",
                            title: "Война и мир",
                            link: "https://quicktickets.ru/armavir-teatr-dramy-i-komedii/e53"
                        }
                    },
                    {
                        datetime: date(18,18),
                        show: {
                            id: 4,
                            img: "https://www.teatrvfk.ru/wp-content/uploads/2017/07/IGP5156.jpg",
                            title: "Двенадцать месяцев",
                            link: "https://quicktickets.ru/armavir-teatr-dramy-i-komedii/e53"
                        }
                    },
                    {
                        datetime: date(26,10),
                        show: {
                            id: 3,
                            img: "https://teatral-online.ru/i/ph/xl/xl_20210428115534.jpg",
                            title: "Мертвые души",
                            link: "https://quicktickets.ru/armavir-teatr-dramy-i-komedii/e53"
                        },
                    },
                    {
                        datetime: date(26,18),
                        show: {
                            id: 1,
                            img: "https://avatars.mds.yandex.net/get-afishanew/31447/b7f53bf11474336bf8d7cc59ff07e253/orig",
                            title: "Щелкунчик",
                            link: "https://quicktickets.ru/armavir-teatr-dramy-i-komedii/e53"
                        }
                    },
                    {
                        datetime: date(27,10),
                        show: {
                            id: 2,
                            img: "https://www.modern-theatre.ru/assets/images/vojna-i-mir/vim-007-1.jpg",
                            title: "Война и мир",
                            link: "https://quicktickets.ru/armavir-teatr-dramy-i-komedii/e53"
                        }
                    },
                    {
                        datetime: date(27,13),
                        show: {
                            id: 1,
                            img: "https://avatars.mds.yandex.net/get-afishanew/31447/b7f53bf11474336bf8d7cc59ff07e253/orig",
                            title: "Щелкунчик",
                            link: "https://quicktickets.ru/armavir-teatr-dramy-i-komedii/e53"
                        }
                    },
                    {
                        datetime: date(28,18),
                        show: {
                            id: 2,
                            img: "https://www.modern-theatre.ru/assets/images/vojna-i-mir/vim-007-1.jpg",
                            title: "Война и мир",
                            link: "https://quicktickets.ru/armavir-teatr-dramy-i-komedii/e53"
                        }
                    },
                ]]
            }
        )

    axiosMockAdapterInstance
        .onGet(/\/api\/shows\/\d+\/schedule/)
        .reply(() => {
            return [200, [
                "2023-04-01 18:30:00",
                "2023-04-03 16:30:00",
                "2023-04-05 18:00:00",
                "2023-04-07 17:00:00",
                "2023-04-10 19:00:00",
                "2023-04-13 14:00:00",
                "2023-04-17 15:00:00",
                "2023-05-01 18:30:00",
                "2023-05-03 16:30:00",
                "2023-05-05 18:00:00",
                "2023-05-07 17:00:00",
                "2023-05-10 19:00:00",
                "2023-05-13 14:00:00",
                "2023-05-17 15:00:00",

            ]]
        })
    
    axiosMockAdapterInstance
        .onGet(/\/api\/shows\/\d+\/cast/)
        .reply(() => {
            return [200, 
                {
                    roles: [
                        {
                            title: "Анна Каренина",
                            employees: [
                                {
                                    name: "Калялина Надежда",
                                    id: 4
                                }
                            ]
                        },
                        {
                            title: "Алексей Каренин",
                            employees: [
                                {
                                    name: "Зорин Андрей",
                                    id: 5
                                },
                                {
                                    name: "Бойко Павел",
                                    id: 8
                                }
                            ]
                        },
                        {
                            title: "Сережа",
                            description: "сын Карениных",
                            employees: [
                                {
                                    name: "Акопджанян Эрнест",
                                    id: 9
                                },
                            ]
                        },
                        {
                            title: "Дамы, кавалеры, гости, слуги, наездники",
                            employees: [
                                {
                                    name: "Стремянова Жанна",
                                    id: 4
                                },
                                {
                                    name: "Малющева Ольга",
                                    id: 6,
                                },
                                {
                                    name: "Недорезов Иван",
                                    id: 8
                                }

                            ]
                        }
                    ],
                    production_team: [
                        {
                            title: "Хореограф-постановщик",
                            employees: [
                                {
                                    name: "Калялина Надежда",
                                    id: 1
                                },
                            ]
                        },
                        {
                            title: "Звукорежиссеры",
                            employees: [
                                {
                                    name: "Семенов Алексей",
                                    id: 2,
                                },
                                {
                                    name: "Ситнов Алексей",
                                    id: 5
                                },
                                {
                                    name: "Бойко Павел",
                                    id: 3
                                }
                            ]
                        }
                    ]
                },
            ]
        })

// инфа о спектакле
    axiosMockAdapterInstance
        .onGet(/\/api\/shows\/\d+/)
        .reply(() => {
            return [200, {
                poster_image: "https://img08.rl0.ru/afisha/904x508q85i/s3.afisha.ru/mediastorage/59/d7/7818489f29e845b48e5c6f15d759.jpg",
                title: "Анна Каренина",
                link: "https://quicktickets.ru/armavir-teatr-dramy-i-komedii/e53",
                description: `Армавирский театр драмы и комедии представляет новый спектакль по роману Льва Толстого. Это история о трагической любви замужней женщины Анны Карениной и офицера Алексея Вронского, но также это и масштабная постановка о нравах и быте дворянской среды Петербурга и Москвы второй половины XIX века. Сохранена главная сюжетная линия романа. На сцене артисты с большим мастерством передают атмосферу событий, погружая зрителя в эпоху автора.`,
                duration: "2 часа 20 минут с 1 антрактом",
                producer: "Юрий Ковалев",
                genre: "Комедия",
                premiere_date: "2018-12-01",
                images: ["https://avatars.mds.yandex.net/get-afishanew/28638/fdbfe850bec8ccd67f21d1fc96ce2183/orig", "https://karenina-musical.ru/uploads/photos/b5d3c24ed7.jpg", "https://karenina-musical.ru/uploads/photos/42bdb1a4f4.jpg", "https://karenina-musical.ru/uploads/photos/ccb266cccf.jpg", "https://cdn.culture.ru/images/f356372e-ed0b-51dd-aa6a-b95ef06c0af3/w_884,h_442,c_fill,g_center/0ac08f53db461cdacb318844b2ac0ca2.jpeg", "https://theatreworld.ru/wp-content/uploads/2016/10/7797.jpg"]
            }]
        })
}
