import React, {useMemo} from "react"
import ContentLoader from "react-content-loader"
import {responsiveLoaderSize} from "./Utils";



const LogoLoader = ({containerSize}) => {
    const responsiveSize = useMemo(() => {
        return responsiveLoaderSize(containerSize, {
            width: 680,
            height: 550
        }, 50)
    }, [containerSize.width, containerSize.height])

    return (
        <ContentLoader
            speed={2}
            width={responsiveSize.width}
            height={responsiveSize.height}
            viewBox="0 0 680 550"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
        >
            <path
                d="M160.61,16.42H6C2.69,16.42,0,19.1,0,22.42V157.55c0,3.31,2.69,6,6,6l71.16,.06c-5.18,8.8-10.87,17.35-30.29,28.74-14.03,8.23-32.32,10.77-41.35,11.48-3.15,.25-5.55,2.91-5.51,6.06v81.51c.05,3.4,2.91,6.08,6.31,5.92,81.47-3.95,122.08-85.15,141.53-141.48,16.69-48.34,18.59-113.45,18.77-133.38,.03-3.33-2.67-6.04-6-6.04ZM36.25,97.07v-26.15s11.13,2.07,21.49,8.54c10.36,6.47,17.35,17.61,17.35,17.61H36.25Z"/>
            <path
                d="M188.82,223.81c9.25-6.32,18.94-9.61,25.41-10.19,2.43-.22,4.42-1.98,4.42-4.42V109.52c0-2.44-1.98-4.42-4.42-4.42h-49.75c-2.8,17.74-6.76,35.93-12.38,52.22-9.79,28.34-21.12,52.55-33.95,72.61,16.42,37.94,44.93,79.87,94.96,82.29,2.51,.12,4.62-1.85,4.65-4.36l.83-60.19c.03-2.33-1.74-4.29-4.06-4.47l-43.65-.62s3.8-9.11,17.93-18.76Zm-25.53-59.25s5.15-8.21,12.79-12.98c7.64-4.77,15.85-6.3,15.85-6.3v19.28h-28.64Z"/>
            <path
                d="M169.04,91.48L186.05,15.3h9.09l17.01,76.18h-10.91l-3.21-16.37h-14.87l-3.21,16.37h-10.91Zm26.96-26.64l-5.35-27.61h-.21l-5.35,27.61h10.91Z"/>
            <path
                d="M218.04,91.48V15.3h16.37c3,0,5.63,.39,7.92,1.18,2.28,.79,4.35,2.18,6.21,4.17,1.85,2,3.14,4.33,3.85,7.01,.71,2.68,1.07,6.3,1.07,10.86,0,3.42-.2,6.31-.59,8.67-.39,2.35-1.23,4.57-2.51,6.63-1.5,2.5-3.5,4.44-5.99,5.83-2.5,1.39-5.78,2.09-9.84,2.09h-5.56v29.75h-10.91Zm10.91-65.91v25.89h5.24c2.21,0,3.92-.32,5.14-.96,1.21-.64,2.1-1.53,2.67-2.67,.57-1.07,.91-2.39,1.02-3.96,.11-1.57,.16-3.32,.16-5.24,0-1.78-.04-3.48-.11-5.08-.07-1.6-.39-3.01-.96-4.23-.57-1.21-1.43-2.14-2.57-2.78-1.14-.64-2.78-.96-4.92-.96h-5.67Z"/>
            <path
                d="M262.13,91.48V15.3h10.49l14.02,40.34h.21l13.91-40.34h10.59V91.48h-10.91V45.15h-.21l-10.81,32.74h-5.46l-10.7-32.74h-.21v46.33h-10.91Z"/>
            <path
                d="M317.23,91.48l17.01-76.18h9.1l17.01,76.18h-10.91l-3.21-16.37h-14.87l-3.21,16.37h-10.91Zm26.96-26.64l-5.35-27.61h-.21l-5.35,27.61h10.91Z"/>
            <path
                d="M366.24,91.48V15.3h15.94c3.49,0,6.47,.54,8.93,1.6,2.46,1.07,4.48,2.5,6.04,4.28,1.57,1.78,2.69,3.83,3.37,6.15,.68,2.32,1.02,4.73,1.02,7.22v2.78c0,2.07-.16,3.82-.48,5.24-.32,1.43-.8,2.67-1.45,3.75-1.21,2-3.07,3.71-5.56,5.14,2.57,1.21,4.46,3,5.67,5.35,1.21,2.35,1.82,5.56,1.82,9.63v4.28c0,6.71-1.62,11.84-4.87,15.41-3.25,3.57-8.44,5.35-15.57,5.35h-14.87Zm10.91-65.91v20.97h4.92c3.64,0,6.08-.91,7.33-2.73,1.25-1.82,1.87-4.48,1.87-7.97s-.7-5.99-2.09-7.7c-1.39-1.71-3.91-2.57-7.54-2.57h-4.49Zm0,30.6v24.4h4.71c2.21,0,3.94-.32,5.19-.96,1.25-.64,2.19-1.53,2.84-2.67,.64-1.14,1.03-2.5,1.18-4.07,.14-1.57,.21-3.28,.21-5.14s-.11-3.6-.32-5.03c-.21-1.43-.64-2.64-1.28-3.64-.71-1-1.68-1.73-2.89-2.19-1.21-.46-2.82-.7-4.81-.7h-4.82Z"/>
            <path
                d="M437.82,91.48V42.05h-.21l-14.34,49.43h-10.91V15.3h10.91v49.54h.21l14.34-49.54h10.91V91.48h-10.91Z"/>
            <path
                d="M459.54,91.48V15.3h16.37c3,0,5.63,.39,7.92,1.18,2.28,.79,4.35,2.18,6.21,4.17,1.85,2,3.14,4.33,3.85,7.01,.71,2.68,1.07,6.3,1.07,10.86,0,3.42-.2,6.31-.59,8.67-.39,2.35-1.23,4.57-2.51,6.63-1.5,2.5-3.5,4.44-5.99,5.83-2.5,1.39-5.78,2.09-9.84,2.09h-5.56v29.75h-10.91Zm10.91-65.91v25.89h5.24c2.21,0,3.92-.32,5.14-.96,1.21-.64,2.1-1.53,2.67-2.67,.57-1.07,.91-2.39,1.02-3.96,.11-1.57,.16-3.32,.16-5.24,0-1.78-.04-3.48-.11-5.08-.07-1.6-.39-3.01-.96-4.23-.57-1.21-1.43-2.14-2.57-2.78-1.14-.64-2.78-.96-4.92-.96h-5.67Z"/>
            <path
                d="M538.29,69.76v4.71c0,2.35-.45,4.58-1.34,6.69-.89,2.1-2.12,3.98-3.69,5.62-1.57,1.64-3.41,2.94-5.51,3.91-2.11,.96-4.37,1.44-6.79,1.44-2.07,0-4.17-.29-6.31-.86-2.14-.57-4.07-1.57-5.78-3-1.71-1.43-3.12-3.26-4.23-5.51-1.11-2.25-1.66-5.12-1.66-8.61V32.21c0-2.5,.43-4.81,1.28-6.96,.86-2.14,2.07-3.99,3.64-5.56,1.57-1.57,3.44-2.8,5.62-3.69,2.18-.89,4.58-1.34,7.22-1.34,5.14,0,9.31,1.68,12.52,5.03,1.57,1.64,2.8,3.58,3.69,5.83,.89,2.25,1.34,4.69,1.34,7.33v4.28h-10.91v-3.64c0-2.14-.61-3.99-1.82-5.56-1.21-1.57-2.85-2.35-4.92-2.35-2.71,0-4.51,.84-5.4,2.51-.89,1.68-1.34,3.8-1.34,6.37v38.95c0,2.21,.48,4.07,1.44,5.56s2.69,2.25,5.19,2.25c.71,0,1.48-.12,2.3-.37,.82-.25,1.59-.66,2.3-1.23,.64-.57,1.18-1.35,1.61-2.35,.43-1,.64-2.25,.64-3.75v-3.75h10.91Z"/>
            <path
                d="M585.8,25.57c-1,0-1.82,.13-2.46,.37-.64,.25-1.25,.7-1.82,1.34-.57,.64-.96,1.43-1.18,2.35-.21,.93-.39,1.96-.54,3.1l-.96,8.88c-.07,.93-.18,1.78-.32,2.57-.14,.71-.29,1.44-.43,2.19-.14,.75-.36,1.41-.64,1.98-.43,1-.95,1.87-1.55,2.62-.61,.75-1.2,1.34-1.76,1.77l11.34,38.73h-11.13l-9.63-33.92h-6.1v33.92h-10.91V15.3h10.91V47.94h3.64c1.28,0,2.32-.18,3.1-.54,.78-.36,1.43-.86,1.93-1.5,.5-.64,.86-1.37,1.07-2.19,.21-.82,.39-1.73,.54-2.73l1.07-9.1c.07-.28,.16-.87,.27-1.77,.11-.89,.3-1.91,.59-3.05,.28-1.14,.68-2.32,1.18-3.53,.5-1.21,1.14-2.35,1.93-3.42,1.35-1.71,3.01-2.94,4.98-3.69,1.96-.75,4.26-1.12,6.9-1.12v10.27Z"/>
            <path d="M618,91.48V42.05h-.21l-14.34,49.43h-10.91V15.3h10.91v49.54h.21l14.34-49.54h10.91V91.48h-10.91Z"/>
            <path
                d="M665.19,91.48V42.05h-.21l-14.34,49.43h-10.91V15.3h10.91v49.54h.21l14.34-49.54h10.91V91.48h-10.91ZM652.14,0c.07,.43,.14,.95,.21,1.55,.07,.61,.3,1.21,.7,1.82,.39,.61,.96,1.12,1.71,1.55s1.8,.64,3.16,.64,2.41-.21,3.16-.64,1.32-.94,1.71-1.55c.39-.61,.62-1.21,.7-1.82,.07-.6,.14-1.12,.21-1.55h9.1c-.5,4.28-1.91,7.42-4.23,9.42-2.32,2-5.87,3-10.65,3s-8.33-1-10.65-3c-2.32-2-3.73-5.14-4.23-9.42h9.09Z"/>
            <path d="M261,297.33V143.33h-29.5v-24h84.5v24h-29.5v154h-25.5Z"/>
            <path d="M327.24,297.33V119.33h76v24h-50.5v52.25h44v24h-44v52.25h50.5v25.5h-76Z"/>
            <path
                d="M408.24,297.33l39.75-178h21.25l39.75,178h-25.5l-7.5-38.25h-34.75l-7.5,38.25h-25.5Zm63-62.25l-12.5-64.5h-.5l-12.5,64.5h25.5Z"/>
            <path d="M526.99,297.33V143.33h-29.5v-24h84.5v24h-29.5v154h-25.5Z"/>
            <path
                d="M593.24,297.33V119.33h38.25c7,0,13.16,.92,18.5,2.75,5.33,1.84,10.16,5.09,14.5,9.75,4.33,4.67,7.33,10.12,9,16.38,1.66,6.25,2.5,14.71,2.5,25.38,0,8-.46,14.75-1.38,20.25-.92,5.5-2.88,10.67-5.88,15.5-3.5,5.84-8.17,10.38-14,13.62-5.84,3.25-13.5,4.88-23,4.88h-13v69.5h-25.5Zm25.5-154v60.5h12.25c5.16,0,9.16-.75,12-2.25,2.83-1.5,4.91-3.58,6.25-6.25,1.33-2.5,2.12-5.58,2.38-9.25,.25-3.66,.38-7.75,.38-12.25,0-4.16-.09-8.12-.25-11.88-.17-3.75-.92-7.04-2.25-9.88-1.34-2.83-3.34-5-6-6.5-2.67-1.5-6.5-2.25-11.5-2.25h-13.25Z"/>
            <path
                d="M35,318.81l-11.95,9.71-7.77-9.56,21.73-17.66,4.59,5.65c1.91-.47,3.98-1.13,6.23-1.99,2.24-.86,4.52-1.87,6.83-3.03,2.31-1.15,4.64-2.39,7-3.7,2.36-1.32,4.6-2.66,6.74-4.03,1.64-1.09,3.44-2.35,5.41-3.77,1.97-1.42,3.98-2.9,6.03-4.45,2.05-1.55,4.08-3.11,6.11-4.69,2.02-1.58,3.94-3.11,5.75-4.58l22.6-18.36s19.37,27.73,40,43.27c25.64,19.3,63.68,21.67,63.68,21.67v87.08h8.12v28h-12.32v-15.4s-55.34,4.74-106.86-28.4c-36.46-23.45-71.93-75.75-71.93-75.75Zm78-42.09l-12.71,10.33c-1.02,.83-2.51,1.98-4.49,3.47-1.98,1.49-4.15,3.07-6.51,4.75-2.36,1.68-4.75,3.37-7.14,5.08-2.4,1.71-4.56,3.16-6.47,4.36-1.57,1.03-3.41,2.14-5.52,3.31s-4.32,2.34-6.63,3.49c-2.31,1.15-4.63,2.26-6.96,3.31-2.33,1.05-4.52,1.87-6.57,2.46,0,0,37.06,50.06,68.07,68.74,41.17,24.8,85.65,24.34,85.65,24.34v-74.48s-28.43-3.97-56.39-22.36c-17.7-11.64-34.32-36.8-34.32-36.8Z"/>
            <path
                d="M248.04,422.96v-99.68h21.42c3.92,0,7.37,.51,10.36,1.54,2.99,1.03,5.69,2.85,8.12,5.46,2.43,2.61,4.11,5.67,5.04,9.17,.93,3.5,1.4,8.24,1.4,14.21,0,4.48-.26,8.26-.77,11.34-.51,3.08-1.61,5.97-3.29,8.68-1.96,3.27-4.57,5.81-7.84,7.63-3.27,1.82-7.56,2.73-12.88,2.73h-7.28v38.92h-14.28Zm14.28-86.24v33.88h6.86c2.89,0,5.13-.42,6.72-1.26,1.59-.84,2.75-2.01,3.5-3.5,.75-1.4,1.19-3.13,1.33-5.18,.14-2.05,.21-4.34,.21-6.86,0-2.33-.05-4.55-.14-6.65-.09-2.1-.51-3.94-1.26-5.53-.75-1.59-1.87-2.8-3.36-3.64-1.49-.84-3.64-1.26-6.44-1.26h-7.42Z"/>
            <path
                d="M310.9,422.96l22.26-99.68h11.9l22.26,99.68h-14.28l-4.2-21.42h-19.46l-4.2,21.42h-14.28Zm35.28-34.86l-7-36.12h-.28l-7,36.12h14.28Z"/>
            <path
                d="M391.82,422.96v-99.68h13.72l18.34,52.78h.28l18.2-52.78h13.86v99.68h-14.28v-60.62h-.28l-14.14,42.84h-7.14l-14-42.84h-.28v60.62h-14.28Z"/>
            <path
                d="M487.16,323.28h14.28v40.88h5.32c4.95,0,9.08,.68,12.39,2.03,3.31,1.35,6,3.24,8.05,5.67,2.15,2.61,3.5,5.41,4.06,8.4,.56,2.99,.89,6.3,.98,9.94v5.6c0,8.77-2.12,15.49-6.37,20.16-4.25,4.67-11.04,7-20.37,7h-18.34v-99.68Zm14.28,53.48v31.92h5.04c2.89,0,5.16-.42,6.79-1.26,1.63-.84,2.87-2.01,3.71-3.5,.84-1.49,1.35-3.27,1.54-5.32,.19-2.05,.28-4.29,.28-6.72s-.14-4.67-.42-6.44c-.28-1.77-.84-3.31-1.68-4.62-.93-1.31-2.19-2.31-3.78-3.01-1.59-.7-3.69-1.05-6.3-1.05h-5.18Zm38.08,46.2v-99.68h14.28v99.68h-14.28Z"/>
            <path
                d="M661.6,422.96v-64.68h-.28l-18.76,64.68h-14.28v-99.68h14.28v64.82h.28l18.76-64.82h14.28v99.68h-14.28Z"/>
            <path
                d="M73.84,383.45c-.19,.25-1.41,14.12-7.95,29.37-5.76,13.43-16.96,28.48-16.96,28.48,0,0-2.05-.04,5.33-.04,31.85,0,46.51,33.83,51.29,49.7,6.92,22.98,7.53,54.73,7.53,54.73h-14.56s-.46-37.1-9.59-57.78c-4.04-9.16-11.37-32.04-39.47-32.04-8.11,0-35.18,.2-35.18,.2v89.63H0v-204.46H14.28v102.23s15.37,0,28.31-15.88c17.12-21.01,19.46-50.83,20.58-52.3l10.67,8.18Z"/>
            <path
                d="M152.43,469.52c0-4.01,.7-7.56,2.1-10.64,1.4-3.08,3.27-5.65,5.6-7.7,2.24-1.96,4.78-3.45,7.63-4.48,2.85-1.03,5.72-1.54,8.61-1.54s5.76,.51,8.61,1.54c2.85,1.03,5.44,2.52,7.77,4.48,2.24,2.05,4.06,4.62,5.46,7.7,1.4,3.08,2.1,6.63,2.1,10.64v52.64c0,4.2-.7,7.79-2.1,10.78-1.4,2.99-3.22,5.46-5.46,7.42-2.33,2.05-4.92,3.59-7.77,4.62-2.85,1.03-5.72,1.54-8.61,1.54s-5.76-.51-8.61-1.54c-2.85-1.03-5.39-2.57-7.63-4.62-2.33-1.96-4.2-4.43-5.6-7.42-1.4-2.99-2.1-6.58-2.1-10.78v-52.64Zm14.28,52.64c0,3.45,.96,6,2.87,7.63,1.91,1.63,4.18,2.45,6.79,2.45s4.88-.82,6.79-2.45c1.91-1.63,2.87-4.18,2.87-7.63v-52.64c0-3.45-.96-6-2.87-7.63-1.91-1.63-4.18-2.45-6.79-2.45s-4.88,.82-6.79,2.45c-1.91,1.63-2.87,4.18-2.87,7.63v52.64Z"/>
            <path
                d="M244.54,545.68v-99.68h13.72l18.34,52.78h.28l18.2-52.78h13.86v99.68h-14.28v-60.62h-.28l-14.14,42.84h-7.14l-14-42.84h-.28v60.62h-14.28Z"/>
            <path d="M354.02,545.68v-99.68h42.56v13.44h-28.28v29.26h24.64v13.44h-24.64v29.26h28.28v14.28h-42.56Z"/>
            <path
                d="M444.74,545.68v15.4h-12.32v-28h7.28c.84-1.77,1.63-3.8,2.38-6.09,.75-2.29,1.4-4.69,1.96-7.21,.56-2.52,1.07-5.11,1.54-7.77,.47-2.66,.84-5.25,1.12-7.77,.19-1.96,.35-4.15,.49-6.58,.14-2.43,.26-4.92,.35-7.49,.09-2.57,.16-5.13,.21-7.7,.05-2.57,.07-5.02,.07-7.35v-29.12h41.02v87.08h8.12v28h-12.32v-15.4h-39.9Zm16.52-87.08v16.38c0,1.31-.05,3.2-.14,5.67-.09,2.47-.23,5.16-.42,8.05-.19,2.89-.37,5.81-.56,8.75-.19,2.94-.42,5.53-.7,7.77-.19,1.87-.49,3.99-.91,6.37s-.91,4.83-1.47,7.35c-.56,2.52-1.17,5.02-1.82,7.49-.65,2.47-1.4,4.69-2.24,6.65h21.56v-74.48h-13.3Z"/>
            <path
                d="M569.2,545.68v-64.68h-.28l-18.76,64.68h-14.28v-99.68h14.28v64.82h.28l18.76-64.82h14.28v99.68h-14.28Z"/>
            <path
                d="M661.88,545.68v-64.68h-.28l-18.76,64.68h-14.28v-99.68h14.28v64.82h.28l18.76-64.82h14.28v99.68h-14.28Z"/>
        </ContentLoader>
    )
};

export default LogoLoader;

