import {Row, Spacer, styled, Text} from "@nextui-org/react";
import {Children, forwardRef, useMemo} from "react";
import useWindowSize from "../../hooks/useWindowSize";
import TText from "../Common/TText";
import BlockTitleLoader from "./BlockTitleLoader";

function BlockTitle({children, color, css, isLoading, h}, ref) {
    return (
        <div ref={ref} style={{width: "100%"}}>
            <Row justify={"flex-start"} css={{...styles.rowContainer, ...css}} align={"center"}>
                <TText css={styles.text(h && h > 0 ? h - 1 : 0)} color={color}>
                    {isLoading ? <BlockTitleLoader/> : Children.map(children, child => (child))}
                </TText>
                <TitleLine color={color}/>
            </Row>
        </div>
    )
}

export default forwardRef(BlockTitle);

const TitleLine = styled(Row, {
    variants: {
        color: {
            tblack: {
                background: '$primary'
            },
            twhite: {
                background: 'white',
            }
        }
    },
    position: "absolute",
    left: 0, right: 0,
    top: "58%",
    transform: "translateY(-50%)",
    height: "2px"
})

const styles = {
    rowContainer: {
        m: '$sm 0 $sm 0',
        position: 'relative',
        '@md': {
            m: '$md 0 $md 0',
        }
    },
    text: (h) => ({
        zIndex: 9,
        pr: '$lg',
        fontSize: `$${5-h}xl`,
        '@sm': {
            fontSize: `$${6-h}xl`,
        },
        '@md': {
            fontSize: `$${7-h}xl`,
        },
    }),
}