import React, { useEffect, useState } from 'react';
import {imageUrl} from "../../api/api";

const BlurryLoadingImage = ({src, preview, alt, css, imgCss, bgColor = 'transparent'}) => {
    const [loading, setLoading] = useState(true);

    const fetchImage = (src) => {
        const loadingImage = new Image();
        loadingImage.src = src;
        loadingImage.onload = () => {
            setLoading(false);
            loadingImage.remove()
        };
    };

    useEffect(() => {
        fetchImage(src);
    }, []);

    return (
        <div style={{position: "relative", overflow: 'hidden', ...css}}>
            {loading && (
                <div style={{position: "absolute", left: 0, right: 0, top: 0, bottom: 0, backdropFilter: "blur(20px)"}}/>
            )}
            <img
                style={{
                    height: '100%',
                    width: '100%',
                    background: bgColor,
                    ...imgCss
                }}
                src={loading ? preview : src}
                alt={alt}
            />
        </div>
    );
};

export default BlurryLoadingImage;