import Header from '../Header/Header';
import { Row } from '@nextui-org/react';
import Slider from './Slider/Slider';
import FullPageScroller from '../FullPageScroller/FullPageScroller';
import FullPageContainer from '../FullPageScroller/FullPageContainer';
import useWindowSize from '../../hooks/useWindowSize';
import { useState } from 'react';
import Poster from './Poster/Poster';
import Shows from './Shows/Shows';
import News from './News/News';
import Footer from './Footer/Footer';
import cat from '../../images/cat.jpeg';
import PublicHeader from '../Header/PublicHeader';

export default function MainPage() {
    const windowSize = useWindowSize();
    const [headerHeight, setHeaderHeight] = useState(76);
    return (
        <>
            <FullPageScroller>
                <FullPageContainer key="slider">
                    <Row
                        justify="flex-start"
                        css={{
                            flexDirection: 'column',
                            position: 'absolute',
                            top: 0,
                            bottom: 0,
                        }}
                    >
                        <PublicHeader />
                        <Row css={{ flexGrow: 1 }}>
                            <Slider />
                        </Row>
                    </Row>
                </FullPageContainer>
                <FullPageContainer key="poster">
                    <Poster />
                </FullPageContainer>
                <FullPageContainer key="shows">
                    <Shows />
                </FullPageContainer>
                <FullPageContainer key="news">
                    <News />
                </FullPageContainer>
                <FullPageContainer key="footer">
                    <Footer />
                </FullPageContainer>
            </FullPageScroller>
            <div
                style={{
                    position: 'fixed',
                    zIndex: '-999',
                    bottom: '-27px',
                    right: '20%',
                    opacity: '0.2',
                }}
            >
                <img src={cat} width={'70'} />
            </div>
        </>
    );
}
