import LeftArrow from "../../../images/arrowLeft.svg";
import { Row, Spacer, Col } from "@nextui-org/react";
import TText from "../../Common/TText";
import useValue from "../../../hooks/useValue";
import moment from "moment";
import { forwardRef, useCallback } from "react";

function MonthControl({currentMonth, currentYear, onClick}, ref) {
    const initialDate = useValue({
        month: moment().month(),
        year: moment().year(),
    })
    

    const onNextMonth = useCallback(() => {
        if (currentMonth.value === 11) {
            currentYear.increment(1);
            currentMonth.set(0);
            return;
        }

        currentMonth.increment(1);

    }, [currentMonth.value, currentYear.value])

    const onPrevMonth = useCallback(() => {
        if (currentMonth.value === initialDate.value.month && currentYear.value === initialDate.value.year) {
            return;
        }

        if (currentMonth.value === 0) {
            currentYear.decrement(1);
            currentMonth.set(11);
            return;
        }

        currentMonth.decrement(1);
    }, [currentMonth.value, currentYear.value])

    return (
        <div ref={ref} onClick={onClick ? onClick : () => {}} style={style.container}>
            <Row align="center" justify="center">
                <Col css={{...style.arrowContainer,
                    opacity: currentMonth.value === initialDate.value.month && currentYear.value === initialDate.value.year ? 0.2 : 1,
                }} onClick={onPrevMonth}>
                    <img src={LeftArrow} style={{filter: "invert(1)", width: "100%"}}/>
                </Col>
                <Spacer x={.5}/>
                <TText light css={style.text}>{moment().month(currentMonth.value).format("MMMM")}</TText>
                <Spacer x={.5}/>
                <Col css={style.arrowContainer} onClick={onNextMonth}>
                    <img src={LeftArrow} style={{filter: "invert(1)", transform: "rotate(180deg)", width: "100%"}}/>
                </Col>
            </Row>
        </div>
    )
}

const style = {
    container: {
        marginBottom: "10px",
    },
    text: {
        textTransform: "capitalize",
        fontSize: "30px",
        '@sm': {
            fontSize: "40px",
            margin: "0 5px",
        },
        '@md': {
            fontSize: "50px",
            margin: "0 10px",
        },
    },
    arrowContainer: {
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        w: "40px",
        '@sm': {
            w: "50px",
        },
        '@md': {
            w: "60px",
        }
    }
}

export default forwardRef(MonthControl);