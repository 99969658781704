import { useEffect, useRef } from "react";
import { configuration } from "./configuration";
import useValue from "../../hooks/useValue";
import EditorJS from "@editorjs/editorjs";
import "./Editor.css";

function Editor({holder, data, instanceSetter}) {
    const editor = useValue(null);

    const editorRef = useRef(null);

    useEffect(() => {
        if (!editorRef.current || editor.value) {
            return;
        }
        const editorJs = new EditorJS(configuration(holder, data));
        editor.set(editorJs);
        window.editor = editorJs;
        if (instanceSetter) {
            instanceSetter.set(editorJs);
        }
    }, [editorRef.current])

    return (
        <div ref={editorRef} id={holder}/>
    )
};

export default Editor;