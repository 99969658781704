import { Col, Row } from "@nextui-org/react";
import TCollapse from "../../../Common/TCollapse";
import SimpleLogoLoader from "../../../Common/Loader/SimpleLogoLoader";
import Output from "../../../../lib/editor-output/editor-output";
import TText from "../../../Common/TText";

function FAQMobile({questions, questionSelector, answer, answerIsLoading, questionsIsLoading}) {
    const onExpand = (currentSelected) => {
        if (currentSelected === questionSelector.value) {
            questionSelector.set(null);
            return
        }

        questionSelector.set(currentSelected);
    }

    return (
    <Col>
        {questions.map((question, idx) => {
            return (
            <TCollapse key={question.id} title={<TText css={style.text}>{question.title}</TText>} content={
                <>
                    {answerIsLoading && (
                        <Row justify="center" css={{width: "100%", paddingBottom: "20px"}}>
                            <SimpleLogoLoader containerSize={{width: 200, height: 200}} padding={10}/>
                        </Row>
                    )}
                    {!answerIsLoading && <Output data={answer.content}/>}
                </>
            }
            onExpand={() => onExpand(question.id)}
            isExpanded={questionSelector.value === question.id}
            css={{container: idx === questions.length - 1 ? {...style.itemContainer, borderBottom: "none"} : style.itemContainer, titleContainer: style.titleContainer}}
            />
            )
        }
        )}
    </Col>
    )
}

const style = {
    itemContainer: {
        borderBottom: "2px $primary solid",
    },
    titleContainer: {
        justifyContent: "space-between",
        height: "85px",
    },
    acitveItemContainer: {
        background: "$primary"
    },
    text: {
        fontSize: "36px"
    }
}

export default FAQMobile;