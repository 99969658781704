import { useState } from 'react';

export default function useValue(init) {
    const [value, set] = useState(init);
    const [isLoading, setLoading] = useState(true);
    return {
        value,
        set,
        update: (newValue) => set({ ...value, ...newValue }),
        increment: (by) => set(value + by),
        decrement: (by) => set(value - by),
        isLoading: isLoading,
        setLoading: setLoading,
    };
}
