import { Col, Row } from '@nextui-org/react';
import TInput from '../../Common/TInput/TInput';
import { useContext } from 'react';
import { UserContext } from '../../../context';

function Login() {

    return (
        <div>
            <Col>
                <Row>Авторизация</Row>
                <TInput />
            </Col>
        </div>
    );
}

export default Login;
