import {API} from "../../../api/api";

export function setMockBanners(axiosMockAdapterInstance) {
    // current
    axiosMockAdapterInstance
        .onGet(API.banners)
        .reply(() => {
            return [
                200,
                [
                    {
                        img: "https://gosuslugi.pnzreg.ru/bitrix/templates/gosuslugi/images/icon/gos_logo_mobile.svg",
                        title: "gosuslugi",
                        link: "https://gosuslugi.ru",
                        id: 1,
                    },
                    {
                        img: "https://minkultrb.ru/upload/resize_cache/iblock/329/900_900_1/329598916702e0f7cb8d5a77db59212d.png",
                        title: "Культура",
                        link: "https://culture.ru",
                        id: 2,
                    },
                    {
                        img: "https://static.tildacdn.com/tild3862-3637-4061-b332-363237333336/noroot.png",
                        title: "Добро",
                        link: "https://dobro.ru",
                        id: 3,
                    },
                    {
                        img: "https://mykcson.ru/media/2020/09/02/1257036813/Nacz_proekty_logo_sin_kontur_lev.png",
                        title: "Национальные проекты России",
                        link: "https://национальныепроекты.рф",
                        id: 4,
                    },
                    {
                        img: "https://bukbmc.ru/wp-content/uploads/Tall_monochrome-1024x976.png",
                        title: "Пушкинская карта",
                        link: "https://пушка.рф",
                        id: 5,
                    },
                    {
                        img: "https://iv.kommersant.ru/Issues.photo/CORP/2013/05/19/KMO_121006_02588_1_t218.jpg",
                        title: "Общественное телевидение России",
                        link: "https://otr-online.ru",
                        id: 6,
                    },
                    {
                        img: "https://dv1930.ru/wp-content/uploads/2019/12/lica-pobedi.png",
                        title: "Лица победы",
                        link: "https://historydepositarium.ru",
                        id: 7,
                    },
                ]]
        })
}
