import {Children} from "react";
import "./TText.css";
import {StyledP} from "./StyledP";

export default function TText({children, css, light, size, onClick, style, color, className}) {
    return (
        <StyledP css={{
            fontFamily: "DIN Condensed",
            fontWeight: light ? "300" : "normal",
            fontSize: `${size}px`,
            lineHeight: "100%",
            cursor: onClick ? "pointer" : "",
            webkitLetterSpacing: "0px",
            mozLetterSpacing: "0px",
            letterSpacing: "0px",
            ...css,
            ...style
        }}
                 className={`${className} ${light ? "ttext-light" : "ttext"}`}
                 onClick={onClick ? onClick : () => { }}
                 color={color}
        >{Children.map(children, child => (child))}</StyledP>
    )
}