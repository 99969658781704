import { useEffect, useRef } from "react";
import TText from "../../Common/TText";
import MonthControl from "./MonthControl";
import PosterItem from "./PosterItem";
import { Loading, Row, Spacer } from "@nextui-org/react";


function PosterList({currentMonth, currentYear, isLoading, shows}) {
    const firstControl = useRef();

    const scrollToTop = () => {
        firstControl.current.parentNode.parentNode.scrollTo(0, firstControl.current.offsetTop - 10);
    }

    return (
        <>
            <MonthControl currentMonth={currentMonth} currentYear={currentYear} ref={firstControl}/>
            {isLoading && (
            <Row align="center" justify="center">
                <Loading/>
            </Row>
            )}
            {!isLoading && shows.map((item, idx, arr) => (
                <PosterItem
                    show={{...item.show, at: item.at}}
                    last={idx === arr.length - 1}
                /> ))}
            {!isLoading && shows.length === 0 && (
                <Row align="center" justify="center">
                    <TText light size={35} css={{textAlign: "center"}}>На выбранный месяц нет мероприятий</TText>
                </Row>)}
            {!isLoading && shows.length > 3 && (
                <>
                    <Spacer y={0.5}/>
                    <MonthControl currentMonth={currentMonth} currentYear={currentYear} onClick={scrollToTop}/>
                </>
            )}
            <Spacer y={.5}/>
        </>
    )
}

export default PosterList;