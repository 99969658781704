import { Row } from "@nextui-org/react"
import TText from "../../Common/TText"

function HARSHMINT() {
    return (
        <Row justify={"center"} css={harshmintStyles.container} align={"center"}>
            <a href={"https://harshmint.ru"} target={"_blank"}>
                <TText css={harshmintStyles.text} light>HARSHMINT</TText>
            </a>
        </Row>
    )
}

const harshmintStyles = {
    container: {
        width: "100%",
        // position: "absolute",
        // bottom: 0,
        // left: "50%",
        // transform: "translate(-50%, 0)",
        borderTop: "1px solid white",
        height: "35px",
        '@xs': {
            height: "70px"
        }
    },
    text: {
        color: "white",
        fontSize: "$xl",
        cursor: "pointer"
    }
}

export default HARSHMINT;