import { Col, Row, Spacer, styled, Text } from '@nextui-org/react';
import TDropdown from '../Common/TDropdown';
import PushkinButton from './PushkinButton';
import EyeButton from './EyeButton';
import TContainer from '../Common/TContainer/TContainer';
import { Link, useNavigate } from 'react-router-dom';
import logoWithText from './logoWithText.svg';

function DesktopHeader({ windowSize, items }) {
    const logoFontCSS = {
        fontFamily: 'DIN Condensed',
        fontSize: '45px',
        lineHeight: '0.9em',
    };
    const overallHeight = 220;
    const logoRowHeight = 0.7 * overallHeight;
    const buttonsRowHeight = overallHeight - logoRowHeight;
    const TTextHeader = styled(Text, {
        fontSize: '32px',
        fontFamily: 'DIN Condensed',
        fontWeight: 300,
        cursor: 'pointer',
    });

    const navigation = useNavigate();

    return (
        <Row css={{ h: `${overallHeight}px` }}>
            <TContainer>
                <Link to="/">
                    <Row
                        justify={'center'}
                        css={{ h: `${logoRowHeight}px` }}
                        align={'center'}
                    >
                        <img src={logoWithText} />
                    </Row>
                </Link>
                <Row justify={'space-between'} css={{ h: '' }} align={'center'}>
                    <Col>
                        <Row
                            justify={'flex-start'}
                            align={'center'}
                            css={{
                                h: `${buttonsRowHeight}px`,
                                w: 'max-content',
                            }}
                        >
                            {items.map((item, idx, arr) => {
                                if (item.link) {
                                    return (
                                        <>
                                            <Link to={item.link}>
                                                <TTextHeader>
                                                    {item.name}
                                                </TTextHeader>
                                            </Link>
                                            {idx !== arr.length - 1 && (
                                                <Spacer x={2} />
                                            )}
                                        </>
                                    );
                                }
                                return (
                                    <>
                                        <TDropdown
                                            title={
                                                <TTextHeader>
                                                    {item.name}
                                                </TTextHeader>
                                            }
                                            items={item.items.map((item) => ({
                                                key: item.link,
                                                title: item.name,
                                            }))}
                                            onAction={(key) => navigation(key)}
                                        />
                                        {idx !== arr.length - 1 && (
                                            <Spacer x={2} />
                                        )}
                                    </>
                                );
                            })}
                        </Row>
                    </Col>
                    <Col css={{ w: 'min-content' }}>
                        <Row justify={'flex-end'}>
                            <PushkinButton
                                simple={windowSize.width < 1280}
                                onClick={() => navigation('/pages/pushkin')}
                            />
                            {windowSize.width >= 1280 && <Spacer x={1} />}
                            {/*<EyeButton simple={windowSize.width < 1280} />*/}
                        </Row>
                    </Col>
                </Row>
            </TContainer>
        </Row>
    );
}

export default DesktopHeader;
