import {Dropdown} from "@nextui-org/react";
import {ArrowDown2} from "iconsax-react";
import ArrowDown from "../../images/ArrowDown.svg"
import TText from "./TText";

export default function TDropdown({title, items, onAction}) {
    const TDropdownItemCss = {borderRadius: 0, background: "white", '&:hover': {border: "1px solid", background: "white"}};

    return (
      <Dropdown>
          <Dropdown.Button ripple={false} light iconRight={<img src={ArrowDown}/>} css={{p: "0"}}>
              {title}
          </Dropdown.Button>
          <Dropdown.Menu containerCss={{border: "solid 1px", borderRadius: 0}} onAction={onAction}>
              {items.map(item => (
                  <Dropdown.Item css={TDropdownItemCss} key={item.key}>{<TText light size={21}>{item.title}</TText>}</Dropdown.Item>
              ))}
          </Dropdown.Menu>
      </Dropdown>
    );
}