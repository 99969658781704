import "./ShowGallery.css";
import useBreakpoints from "../../../../hooks/useBreakpoints";
import { useMemo, useRef } from "react";
import { Grid, Row } from "@nextui-org/react";
import TText from "../../../Common/TText.js";
import useValue from "../../../../hooks/useValue.js";
import { IconChevronLeft, IconChevronRight, IconSquareX } from "@tabler/icons-react";
import "swiper/element/css/zoom";
import {adaptiveImageUrl, imageUrl} from "../../../../api/api";
import ImageAdaptive from "../../../Common/ImageAdaptive";
import BlurryLoadingImage from "../../../Common/BlurryLoadingImage";


function ShowGallery({images}) {

    const bp = useBreakpoints();

    const maxNum = useMemo(() => {
        if (bp.sm) {
            return 4;
        }

        if (bp.md || bp.lg) {
            return 6;
        }

        if (bp.xl) {
            return 8;
        }

        return 0;
    }, [bp]);

    const sliderIsOpen = useValue(false);

    const swiperRef = useRef();

    return (
        <>
            <div className={`show-gallery__slider-wrapper ${sliderIsOpen.value ? "" : "hidden"}`}>
                <Row justify="center" className="show-gallery__slider_close-button show-gallery__slider_button-wrapper" align="center" onClick={() => sliderIsOpen.set(false)}>
                    <IconSquareX color="white" size={32}/>
                </Row>
                <Row justify="center" className="show-gallery__slider_left-button show-gallery__slider_button-wrapper" align="center">
                    <IconChevronLeft color="white" size={32}/>
                </Row>
                <Row justify="center" className="show-gallery__slider_right-button show-gallery__slider_button-wrapper" align="center">
                    <IconChevronRight color="white" size={32}/>
                </Row>
                <swiper-container ref={swiperRef} class="show-gallery__slider" navigation-next-el=".show-gallery__slider_right-button" navigation-prev-el=".show-gallery__slider_left-button" zoom-min-ration="1">
                    {images && images.map(image => (
                        <swiper-slide>
                            <div className="swiper-zoom-container">
                                <BlurryLoadingImage preview={adaptiveImageUrl(image, 100, 100)} src={imageUrl(image)} css={{width:"100%"}}/>
                            </div>
                        </swiper-slide>
                    ))}
                </swiper-container>
            </div>
            {bp.xs && (
                <Row className="show-gallery__mobile-gallery">
                    <swiper-container pagination="true" pagination-dynamic-bullets="true">
                  {images && images.map((image, idx) => (
                        <swiper-slide>
                            <div className="show-gallery__mobile-gallery_image-wrapper" onClick={() => {swiperRef.current.swiper.slideTo(idx, 0, false); sliderIsOpen.set(true)}}>
                                <div style={{backgroundImage: "url(" + adaptiveImageUrl(image, 50, 50) + ")"}}/>
                                <img src={adaptiveImageUrl(image, 500, 225)} alt={"album"} className="image"/>
                            </div>
                        </swiper-slide>
                    ))}
                    </swiper-container>
                </Row>
            )}
            {bp.gteSm && 
                <Row wrap="wrap" css={{pt: "20px", pb: "20px"}}>
                    
                    {images && images.slice(0, maxNum).map((image, idx, arr) => {
                        return (
                            <div className="show-gallery__image" onClick={() => {swiperRef.current.swiper.slideTo(idx, 0, false); sliderIsOpen.set(true)}}>
                                <BlurryLoadingImage preview={adaptiveImageUrl(image, 100, 100)} src={adaptiveImageUrl(image, 377, 250)}/>
                                {idx === maxNum - 1 && images.length > maxNum && (
                                    <div className="show-gallery__last-image-button">
                                        <TText>Еще фото..</TText>
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </Row>
            }
        </>
    );
}

export default ShowGallery;