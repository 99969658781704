import { useParams } from 'react-router-dom';
import useFetch from '../../../hooks/useFetch';
import BlockTitle from '../../MainPage/BlockTitle';
import moment from 'moment';
import ShowMainInfo from './ShowMainInfo';
import ShowSchedule from './ShowSchedule';
import ShowGallery from './ShowGallery/ShowGallery.js';
import Cast from './ShowCast/Cast.js';
import { API } from '../../../api/api';
import { useMemo } from 'react';

function ShowPage({ isArchive }) {
    const { showID } = useParams();

    const show = useFetch({
        api: API.show(showID),
    });
    const schedule = useFetch({
        initial: [],
        api: API.showSchedule(showID),
    });
    const cast = useFetch({
        initial: [],
        api: API.showCast(showID),
    });

    const canBuy = useMemo(() => {
        return schedule.isLoading || schedule.data.length !== 0;
    }, [schedule]);

    return (
        <>
            <BlockTitle color={'tblack'} isLoading={show.isLoading}>
                {show.data?.title}
            </BlockTitle>
            <ShowMainInfo show={show} isArchive={isArchive || !canBuy} />
            {canBuy && (
                <ShowSchedule
                    schedule={schedule.data}
                    link={show.data?.link_to_ticket}
                />
            )}
            {show.data?.images &&
                <ShowGallery images={show.data?.images} />
            }
            <Cast cast={cast.data} />
        </>
    );
}

export default ShowPage;
