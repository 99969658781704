import axios, { AxiosRequestConfig } from 'axios';
import AxiosMockAdapter from 'axios-mock-adapter';
import setMocks from './mocks/mocks';

const axiosMockInstance = axios.create();
const axiosLiveInstance = axios.create();

export const axiosMockAdapterInstance = new AxiosMockAdapter(
    axiosMockInstance,
    { delayResponse: 300 }
);
if (process.env.REACT_APP_MOCK_AXIOS === 'true') {
    setMocks(axiosMockAdapterInstance);
}

// export default process.env.REACT_APP_MOCK_AXIOS === "true" ? axiosMockInstance : axiosLiveInstance;
export default axiosLiveInstance;
