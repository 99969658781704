import TText from './TText';
import './TCard.css';
import useValue from '../../hooks/useValue';

function TCard({ img, imgSet, title, theme, light, classNameModificator, onClick }) {
    const isPressed = useValue(false);

    return (
        <div
            className={`tcard_main-page${isPressed.value ? ' pressed ' : ''}${
                classNameModificator ? ` ${classNameModificator} ` : ''
            }`}
            onMouseDown={() => isPressed.set(true)}
            onMouseUp={() => isPressed.set(false)}
            onMouseLeave={() => isPressed.set(false)}
            onTouchStart={() => isPressed.set(true)}
            onTouchEnd={() => isPressed.set(false)}
            onClick={onClick}
        >
            <div className="tcard_main-page__body">
                <img src={img} srcSet={imgSet} />
            </div>
            <div className="tcard_main-page__footer">
                <TText
                    light={light}
                    css={{ color: theme === 'dark' ? 'white' : 'dark' }}
                >
                    {title}
                </TText>
            </div>
        </div>
    );
}

export default TCard;
