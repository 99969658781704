import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { NextUIProvider } from '@nextui-org/react';
import { BrowserRouter } from 'react-router-dom';
import defaultTheme from './themes/default';
import { register } from 'swiper/element/bundle';

// register Swiper custom elements
register();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <NextUIProvider theme={defaultTheme}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </NextUIProvider>
);

document.getElementById('loader').remove();