import { useEffect } from "react";
import useValue from "../../../hooks/useValue";
import Editor from "../../../lib/Editor/Editor";
import Output from "../../../lib/editor-output/editor-output";
import { TButton } from "../../Common/TButton";
import BlockTitle from "../../MainPage/BlockTitle";
import axios from "../../../lib/axios/axios";
import {API, imageUrlOutput} from "../../../api/api";
import {useNavigate, useParams} from "react-router-dom";
import useFetch from "../../../hooks/useFetch";

function Page() {
    const {pageID} = useParams();
    const navigate = useNavigate();
    const {data, isLoading} = useFetch({
        api: API.pages(pageID),
        initial: null,
        errClb: () => navigate("/pages/not-found")
    })

    return (
        <>
            <BlockTitle color="tblack">{isLoading ? "Загрузка..." : data.title}</BlockTitle>
            {data &&
                <Output data={imageUrlOutput(data.content)}/>
            }
        </>
    )
}

export default Page;