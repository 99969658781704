import useValue from "../../../hooks/useValue";
import BlockTitle from "../../MainPage/BlockTitle";
import moment from "moment";
import { useEffect } from "react";
import axios from "../../../lib/axios/axios";
import { API } from "../../../api/api";
import PosterList from "./PosterList";


function PosterPage() {
    const currentMonth = useValue(moment().month());
    const currentYear = useValue(moment().year());
    const isLoading = useValue(true);

    const shows = useValue([]);

    useEffect(() => {
        isLoading.set(true);
        axios
            .get(API.poster + `?year=${currentYear.value}&month=${currentMonth.value + 1}`)
            .then(resp => {
                isLoading.set(false);
                if (resp.status !== 200) {
                    return;
                }
                shows.set(resp.data.filter(item => moment(item.at) >= moment()));
            })
            .catch(err => {
                isLoading.set(false);
            })
    }, [currentMonth.value, currentYear.value]);

    return (
        <>
            <BlockTitle color={"tblack"}>Афиша</BlockTitle>
            <PosterList shows={shows.value} isLoading={isLoading.value} currentMonth={currentMonth} currentYear={currentYear}/> 
        </>
    );
}

export default PosterPage;