import {imageUrl} from "../../api/api";

export const ImageAdaptive = ({src, w, h, alt, style, fit= true, set = true}) => {
    const base = (w, h) => (imageUrl(`/adaptive/${fit ? 'fit-in/' : ''}${w ? w : 'orig'}x${h ? h : 'orig'}${src}`))

    const srcset = (w, h) => {
        let srcset = ''
        for (let i = 1; i <= 2; i += 0.5) {
            srcset += `${base(w * i, h * i)} ${i}x, `
        }
        return srcset
    }

    return (
        <img src={base(w,h)} srcSet={set ? srcset(w,h) : ''} alt={alt} style={style}/>
    )
}

export default ImageAdaptive;