import {Row, Spacer} from "@nextui-org/react";
import BlockTitle from "../../MainPage/BlockTitle";
import FilterController from "../../Common/FilterController/FilterController";
import { API } from "../../../api/api";
import ShowItem from "../Shows/ShowItem/ShowItem";
import useBreakpoints from "../../../hooks/useBreakpoints";
import TText from "../../Common/TText";
import useValue from "../../../hooks/useValue";
import useInfiniteScroll from "react-infinite-scroll-hook";
import useFetchOffsetPagination from "../../../hooks/useFetchOffsetPagination";
import SimpleLogoLoader from "../../Common/Loader/SimpleLogoLoader";

function ArchivePage() {
    const params = useValue({})
    const bp = useBreakpoints();

    const shows = useFetchOffsetPagination({
        initial: [],
        api: API.archiveShows,
        params: params.value,
        limit: 10,
        deps: [params.value]
    })


    return (
        <>
            <Row align="center">
                    <BlockTitle color={"tblack"}>Архив</BlockTitle>
                    <FilterController params={params}/>
            </Row>
            {!shows.isLoading && shows.data.length === 0 && <EmptyMessage message={'В архиве пусто'}/>}
            {shows.data.length !== 0 && shows.data.map((show, idx, arr) => (<ShowItem show={show} isLast={idx === arr.length - 1} isArchive isMoblie={bp.xs}/>))}
            {(shows.isLoading || shows.hasMore) &&
                <Row justify={"center"}>
                    <Spacer/>
                    <div ref={shows.scrollRef}>
                        <SimpleLogoLoader containerSize={{
                           width: 100,
                           height: 100,
                        }} padding={10}/>
                    </div>
                </Row>
            }
        </>
    )
}

export default ArchivePage;

function EmptyMessage({isLoading, message}) {
    return (
        <Row justify={"center"} css={{pt: '30px'}}>
            {isLoading && (
                <SimpleLogoLoader
                    containerSize={{
                        width: 100,
                        height: 100,
                    }} padding={10}/>)}
            {!isLoading && (
                <TText size={32}>{message}</TText>
            )}
        </Row>
    )
}