import moment from "moment";
import "moment/locale/ru"
import {useEffect, useRef, useTransition} from "react";
import useWindowSize from "../../../../hooks/useWindowSize";
import PosterCalendarControls from "./PosterCalendarControls";
import useValue from "../../../../hooks/useValue";
import PosterCalendarWeeks from "./PosterCalendarWeeks";
import PosterCalendarDays from "./PosterCalendarDays";


export default function PosterCalendar({activeDays, currentDate, isLoading}) {
    moment.locale("ru")

    const onPrevClick = () => {
        if (currentDate.value.year === moment().year() && currentDate.value.month === moment().month()) {
            return
        }

        let prevMonth = currentDate.value.month - 1
        if (prevMonth === -1) {
            currentDate.update({year: currentDate.value.year - 1, month: 11})
            return
        }
        currentDate.update({month: prevMonth})
    }

    const onNextClick = () => {
        let nextMonth = currentDate.value.month + 1
        if (nextMonth === 12) {
            currentDate.update({year: currentDate.value.year + 1, month: 0});
            return;
        }
        currentDate.update({ month: nextMonth});
    }

    const ref = useRef();
    const containerHeight = useValue(1080);
    const containerWidth = useValue(1080);
    const windowSize = useWindowSize();
    useEffect(() => {
        if (!ref.current) {
            return
        }
        containerHeight.set(ref.current.offsetHeight);
        containerWidth.set(ref.current.offsetWidth);
    }, [ref.current, windowSize.height, windowSize.width])

    const getResponsiveSize = (width, height) => {
        if (width <= 700 || height < 700) {
            return "m"
        }
        return "lg"
    }

    return (
        <div ref={ref} style={{position: "absolute", left: 0, top: 0, bottom: 0, right: 0}}>
            <PosterCalendarControls
                size={getResponsiveSize(containerWidth.value, containerHeight.value)}
                currentMonth={currentDate.value.month}
                currentYear={currentDate.value.year}
                onNextClick={onNextClick}
                onPrevClick={onPrevClick}/>

            <PosterCalendarWeeks
                size={getResponsiveSize(containerWidth.value, containerHeight.value)}
            />

            <PosterCalendarDays
                size={getResponsiveSize(containerWidth.value, containerHeight.value)}
                onlyActive={true}
                activeDaysForCurrentMonth={activeDays}
                height={containerHeight.value}
                currentDate={currentDate}
                isLoading={isLoading}
                // animation={!isLoading}
            />
        </div>

    )
}