import { useNavigate } from "react-router-dom";
import ShowItemDesktop from "./ShowItemDesktop/ShowItemDesktop";
import ShowItemMobile from "./ShowItemMobile/ShowItemMobile";

function ShowItem({show, isLast, isMoblie, isArchive}) {
    const navigate = useNavigate();
    const onClick = () => navigate(`/pages/${isArchive ? "archive" : "shows"}/${show.id}`); 

    return isMoblie ? <ShowItemMobile show={show} isLast={isLast} isArchive={isArchive} onClick={onClick}/> : <ShowItemDesktop show={show} isLast={isLast} isArchive={isArchive} onClick={onClick}/>
}

export default ShowItem;