import { Card, Row } from '@nextui-org/react';
import TText from '../../Common/TText';
import moment from 'moment';
import { useMemo } from 'react';
import {useNavigate} from "react-router-dom";
import {adaptiveImageUrl, adaptiveSrcSet, imageUrl} from "../../../api/api";

export default function PosterShowCard({ item }) {
    const navigate = useNavigate();
    return (
        <Card variant={'flat'} isPressable css={styles.card} onClick={() => navigate(`/pages/shows/${item.show.id}`)}>
            <Card.Body css={{ p: 0 }}>
                <Card.Image
                    src={adaptiveImageUrl(item.show?.main_picture, 300, 230)}
                    srcSet={adaptiveSrcSet(item.show?.main_picture, 300, 230)}
                    width="100%"
                    height="100%"
                    objectFit="cover"
                    alt="Card example background"
                />
            </Card.Body>
            <Card.Footer css={styles.cardFooter}>
                <Row justify={'space-between'}>
                    <TText css={styles.title}>{item.show?.title}</TText>
                    <TText light css={styles.time}>
                        {moment(item.at).format('HH:mm')}
                    </TText>
                </Row>
            </Card.Footer>
        </Card>
    );
}

const styles = {
    card: {
        w: '180px',
        h: '170px',
        background: 'transparent',
        borderRadius: 0,
        '@sm': {
            w: '300px',
            h: '230px',
        },
    },
    cardFooter: {
        background: 'transparent',
        borderRadius: 0,
        pl: 0,
        pr: 0,
        pb: 0,
        '@sm': {
            pb: '10px',
        },
    },
    title: {
        fontSize: '$xl',
        '@sm': {
            fontSize: '$3xl',
        },
    },
    time: {
        fontSize: '$xl',
        color: '$primary',
        '@sm': {
            fontSize: '$3xl',
        },
    },
};
