import useWindowSize from "./useWindowSize";
import {useMemo} from "react";

const useDeviceSize = () => {
    const windowSize = useWindowSize();

    return useMemo(() => {
        if (windowSize.width > 1981) {
            return "xl";
        }
        if (windowSize.width > 1400) {
            return "lg";
        }
        if (windowSize.width > 1280) {
            return "md";
        }
        if (windowSize.width > 960) {
            return "sm";
        }
        if (windowSize.width > 650) {
            return "xs";
        }
        return "xxs";
    }, [windowSize.width])
}

export default useDeviceSize;