import { useEffect } from "react";
import BlockTitle from "../../../MainPage/BlockTitle";
import { API } from "../../../../api/api";
import useValue from "../../../../hooks/useValue";
import axios from "../../../../lib/axios/axios";
import ShowItem from "../ShowItem/ShowItem";
import useWindowSize from "../../../../hooks/useWindowSize";

function CurrentShowsPage() {
    const shows = useValue([]);
    const windowSize = useWindowSize();

    useEffect(() => {
        axios.get(API.currentShows)
        .then(resp => {
            shows.set(resp.data);
        })
        .catch(err => {

        })
    }, []);

    return (
        <>
            <BlockTitle color={"tblack"}>Репертуар</BlockTitle>
            {shows.value.map((show, idx, arr) => (
                <ShowItem show={show} isLast={idx === arr.length - 1} isMoblie={windowSize.width < 650}/>
            ))}
        </>
    );
}

export default CurrentShowsPage;