import { Col, Grid, Row, Spacer } from "@nextui-org/react";
import QuestionItem from "./QuestionItem";
import TText from "../../../Common/TText";
import SimpleLogoLoader from "../../../Common/Loader/SimpleLogoLoader";
import Output from "../../../../lib/editor-output/editor-output";

function FAQDesktop({questions, questionSelector, answer, answerIsLoading, questionsIsLoading}) {
    return (
        <>
            <Spacer y={2}/> 
            <Grid.Container gap={1}>
                <Grid xs={4}>
                    <Col css={style.questionList}>
                        {questions.map(question => (
                            <QuestionItem key={question.id} title={question.title} onClick={() => questionSelector.set(question.id)} isActive={question.id === questionSelector.value}/>
                        ))}
                    </Col>
                </Grid>
                <Grid xs={8}>
                    <Col css={{position: "relative"}}>
                            {answerIsLoading && (
                                <Row justify="center" css={style.loaderContainer}>
                                    <SimpleLogoLoader containerSize={{width: 200, height: 200}} padding={20}/>
                                </Row>
                            )}
                        {!answerIsLoading && (
                            <>
                                <Row align="center" justify="center">
                                    <TText css={style.title}>{answer.title}</TText>
                                </Row>
                                <div style={{padding: "10px"}}>
                                    <Output data={answer.content}/>
                                </div>
                            </>
                            )}
                    </Col>
                </Grid>
            </Grid.Container>
        </>
    );
};

const style = {
    loaderContainer: {
        position: "absolute",
        left: 0,
        top: "50%",
        translate: "0 -50%",
    },
    title: {
        fontSize: "36px"
    },
    questionList: {
        minHeight: "500px",
    }
}

export default FAQDesktop;