import './App.css';
import { Route, Routes } from 'react-router-dom';
import MainPage from './components/MainPage/MainPage';
import PosterPage from './components/Pages/Poster/PosterPage';
import NewsPage from './components/Pages/News/NewsPage';
import ShowPage from './components/Pages/Show/ShowPage';
import EmployeesPage from './components/Pages/Employees/EmployeesPage';
import HistoryPage from './components/Pages/History/HistoryPage';
import FAQPage from './components/Pages/FAQ/FAQPage';
import BoxEditor from './components/BoxEditor/BoxEditor';
import CurrentShowsPage from './components/Pages/Shows/Current/CurrentShowsPage';
import PushkinCardPage from './components/Pages/PushkinCard/PushkinCard';
import ArchivePage from './components/Pages/Archive/Archive';
import ConcreteNewsPage from './components/Pages/News/ConcreteNewsPage';
import NotFoundPage from './components/Pages/NotFound/NotFoundPage';
import EmployeePage from './components/Pages/Employee/EmployeePage';
import ArchiveShow from './components/Pages/Archive/ArchiveShow';
import Layout from './components/Layouts/Layout';
import { UserContext } from './context';
import useValue from './hooks/useValue';
import Login from './components/AdminApp/Login/Login';
import AdminLayout from './components/Layouts/AdminLayout';
import Page from "./components/Pages/Page/Page";

function App() {
    const user = useValue({ admin: false });

    return (
        <UserContext.Provider value={user}>
            <div className={'app'}>
                <Routes>
                    <Route index path={'/'} element={<MainPage />} />
                    <Route path={'pages'} element={<Layout />}>
                        <Route index path={'poster'} element={<PosterPage />} />
                        <Route path={'news'} element={<NewsPage />} />
                        <Route
                            path={'news/:newsID'}
                            element={<ConcreteNewsPage />}
                        />
                        <Route path={'shows'} element={<CurrentShowsPage />} />
                        <Route path={'shows/:showID'} element={<ShowPage />} />
                        <Route path={'employees'} element={<EmployeesPage />} />
                        <Route
                            path={'employees/:employeeID'}
                            element={<EmployeePage />}
                        />
                        <Route path={'history'} element={<HistoryPage />} />
                        <Route path={'faq'} element={<FAQPage />} />
                        <Route path={'editor'} element={<BoxEditor />} />
                        <Route path={'pushkin'} element={<PushkinCardPage />} />
                        <Route path={'archive'} element={<ArchivePage />} />
                        <Route
                            path={'archive/:showID'}
                            element={<ArchiveShow />}
                        />
                        <Route path={'page/:pageID'} element={<Page/>}/>
                    </Route>
                    <Route path="login" element={<Login />} />
                    <Route path={'*'} element={<NotFoundPage />} />
                </Routes>
            </div>
        </UserContext.Provider>
    );
}

export default App;
