import BlockTitle from '../BlockTitle';
import HorizontalSlider from '../../Common/Slider/HorizontalSlider';
import FullContainer from '../../Common/FullContainer';
import { useEffect, useMemo } from 'react';
import TContainer from '../../Common/TContainer/TContainer';
import useValue from '../../../hooks/useValue';
import axios from '../../../lib/axios/axios';
import { Col, Spacer } from '@nextui-org/react';
import {adaptiveImageUrl, adaptiveSrcSet, API} from '../../../api/api';
import useElementSize from '../../../hooks/useElementSize';
import useDeviceSize from '../../../hooks/useDeviceSize';
import useWindowSize from '../../../hooks/useWindowSize';
import TCard from '../../Common/TCard';
import Mask from '../../Common/Mask';
import { useNavigate } from 'react-router-dom';

function Shows({}) {
    const deviceSize = useDeviceSize();
    const items = useValue([]);
    const navigate = useNavigate();

    useEffect(() => {
        axios
            .get(API.currentShows)
            .then((resp) => {
                items.set(resp.data);
            })
            .catch(() => {});
    }, []);

    const cards = useMemo(() => {
        if (!items.value) {
            return [];
        }

        return items.value.map((item) => (
            <TCard
                title={item.title}
                img={adaptiveImageUrl(item.poster, 340, 486)}
                imgSet={adaptiveSrcSet(item.poster, 340, 486)}
                onClick={() => navigate('/pages/shows/' + item.id)}
                theme={'dark'}
            />
        ));
    }, [items.value, navigate]);

    const windowSize = useWindowSize();
    const numberOfComponents = () => {
        if (windowSize.width > 960) {
            if (windowSize.height > 700) {
                return 3;
            }
            if (windowSize.width < 1280) {
                return 4;
            }
            return 5;
        }

        if (windowSize.height > 700) {
            return 2;
        }

        return 3;
    };
    const [titleRef, titleSize] = useElementSize();

    return (
        <FullContainer css={{ background: '$darkBackground' }}>
            <Mask dark />
            <Mask right dark />

            <TContainer>
                <BlockTitle color={'twhite'} ref={titleRef} css={{ mt: '5%' }}>
                    Репертуар
                </BlockTitle>
                <Col
                    css={{
                        h:
                            windowSize.height -
                            (titleSize.top + titleSize.height) +
                            'px',
                        w: '100%',
                        display: 'flex',
                        flexFlow: 'column',
                        justifyContent: 'center',
                    }}
                >
                    <HorizontalSlider
                        items={cards}
                        theme={'dark'}
                        severalComponentOnSlide={deviceSize !== 'xxs'}
                        numberOfComponentOnSlide={numberOfComponents()}
                    />
                </Col>
            </TContainer>
        </FullContainer>
    );
}

export default Shows;
