export const API = {
    archiveShows: '/shows?archive=true',
    shows: '/shows',
    show: (id) => `/shows/${id}`,
    showSchedule: (id) => `/shows/${id}/schedule`,
    showCast: (id) => `/shows/${id}/cast`,
    slider: '/shows/pinned',
    poster: '/shows/scheduled',
    currentShows: '/shows/?current=true',
    news: '/news',
    concreteNews: (id) => `/news/${id}`,
    newsSlider: '/news/pinned',
    banners: '/banners',
    footer: '/footer',
    employees: '/employees',
    employee: (id) => `/employees/${id}`,
    categoriesOfEmployees: '/positions/category',
    frequentlyAskedQuestions: '/faq',
    frequentlyAskedQuestion: (id) => `/faq/${id}`,
    history: '/pages/history',
    pages: (id) => `/pages/${id}`,
    genre: `/genre`,

    sessions: '/sessions',
};

function setPrefix(prefix, API) {
    for (const property in API) {
        if (API[property] instanceof Function) {
            const tmpFunc = API[property];
            API[property] = (...args) => prefix + tmpFunc(...args);

            continue;
        }
        API[property] = prefix + API[property];
    }
}

const backendHost = process.env.REACT_APP_DEV === 'true' ? 'http://localhost' : ''

setPrefix(backendHost + '/api', API);
// setPrefix('/api', API);

export const imageUrl = url =>  {
    return backendHost + url
}

export const adaptiveImageUrl = (src, w, h = 0, fit = true) => {
    if (h === 0) {
        h = w
    }
    return imageUrl(`/adaptive/${fit ? 'fit-in/' : ''}${w ? w : 'orig'}x${h ? h : 'orig'}${src}`)
}

export const adaptiveSrcSet = (src, w, h = 0) => {
    let srcset = ''
    for (let i = 1; i <= 2; i += 0.5) {
        srcset += `${adaptiveImageUrl(src, w * i, h * i)} ${i}x, `
    }
    return srcset
}

export const imageUrlOutput = content => {
    if (backendHost.length === 0) {
        return content
    }

    for (let idx = 0; idx < content.blocks.length; idx++) {
        if (content.blocks[idx].type === "Image") {
            content.blocks[idx].data.file.url = imageUrl(content.blocks[idx].data.file.url)
        }
    }

    return content
}