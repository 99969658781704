import { Col, Grid, Row } from "@nextui-org/react";
import Output from "../../../lib/editor-output/editor-output";
import { data } from "./pushkinData";

function PushkinDescription() {
    return (
        <Grid.Container gap={3}>
            <Grid md={3} sm={5} xs={12}>
                <Row justify="center" align="center">
                    <img src={"/pushkin.png"} alt={"пушкинская карта"} style={style.image} />
                </Row>
            </Grid>
            <Grid md={9} sm={7} xs={12}>
                <Col>
                    <Output data={data}/>
                </Col>
            </Grid>
        </Grid.Container>
    );
}

const style = {
    image: {
        objectFit: "contain",
        maxHeight: "350px",
    },
};

export default PushkinDescription;
