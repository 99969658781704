import { API } from '../../../api/api';

export function setMockEmployeesCategory(axiosMockAdapterInstance) {
    axiosMockAdapterInstance.onGet(API.categoriesOfEmployees).reply(() => {
        return [
            200,
            [
                { id: 1, title: 'Труппа' },
                { id: 2, title: 'Творческий коллектив' },
                { id: 3, title: 'Административно управляющий персонал' },
            ],
        ];
    });
}

export function setMockEmployees(axiosMockAdapterInstance) {
    // current
    axiosMockAdapterInstance.onGet(API.employees).reply(() => {
        return [
            200,
            [
                {
                    id: 1,
                    name: 'Бойко Павел Викторович',
                    photo: 'https://armteatr.ru/images/truppa/boyko.jpg',
                    categoryID: 1,
                },
                {
                    id: 2,
                    name: 'Вишневская Виолетта Владимировна',
                    photo: 'https://armteatr.ru/images/truppa/vishnevskaya.jpg',
                    categoryID: 1,
                },
                {
                    id: 3,
                    name: 'Галанцев Игорь Викторович',
                    photo: 'https://armteatr.ru/images/truppa/galancev.jpg',
                    categoryID: 1,
                },
                {
                    id: 4,
                    name: 'Загидуллина Сания Ильдусовна',
                    photo: 'https://armteatr.ru/images/truppa/zagidullina.jpg',
                    categoryID: 1,
                },
                {
                    id: 5,
                    name: 'Зорин Андрей Владимирович',
                    photo: 'https://armteatr.ru/images/truppa/zorin.jpg',
                    categoryID: 1,
                },
                {
                    id: 6,
                    name: 'Калялина Надежда Васильевна',
                    photo: 'https://armteatr.ru/images/truppa/kalyalina.jpg',
                    categoryID: 1,
                },
                {
                    id: 7,
                    name: 'Малющева Ольга Геннадьевна',
                    photo: 'https://armteatr.ru/images/truppa/malyusheva.jpg',
                    categoryID: 1,
                },
                {
                    id: 8,
                    name: 'Недорезов Иван Владимирови',
                    photo: 'https://armteatr.ru/images/truppa/nedorezov.jpg',
                    categoryID: 1,
                },
                {
                    id: 9,
                    name: 'Семенов Алексей Сергеевич',
                    photo: 'https://armteatr.ru/images/truppa/semenov.jpg',
                    categoryID: 2,
                },
                {
                    id: 10,
                    name: 'Стремянова Жанна Евгеньевна',
                    photo: 'https://armteatr.ru/images/truppa/stremyanova.JPG',
                    categoryID: 2,
                },
                {
                    id: 11,
                    name: 'Талаева Наталья Юрьевна',
                    photo: 'https://armteatr.ru/images/truppa/talaeva.jpg',
                    categoryID: 2,
                },
                {
                    id: 12,
                    name: 'Чекурова Жанна Владимировна',
                    photo: 'https://armteatr.ru/images/truppa/chekurova.jpg',
                    categoryID: 2,
                },
                {
                    id: 13,
                    name: 'Юдина Татьяна Владимировна',
                    photo: 'https://armteatr.ru/images/truppa/udina.jpg',
                    categoryID: 2,
                },
                {
                    id: 14,
                    name: 'Яровенко Наталья Григорьевна ',
                    photo: 'https://armteatr.ru/images/truppa/yarovenko.jpg',
                    categoryID: 2,
                },
                {
                    id: 15,
                    name: 'Акопджанян Эрнест Гарикович',
                    photo: 'https://armteatr.ru/images/truppa/akopdzhanyan.jpg',
                    categoryID: 3,
                },
                {
                    id: 16,
                    name: 'Белозёрова Наталья Николаевна',
                    photo: 'https://armteatr.ru/images/truppa/belozerova.jpg',
                    categoryID: 3,
                },
                {
                    id: 17,
                    name: 'Абелян Александр Хоренович',
                    photo: 'https://armteatr.ru/images/truppa/Abelyan.jpg',
                    categoryID: 3,
                },
                {
                    id: 18,
                    name: 'Ковалёв Юрий Алексеевич',
                    photo: 'https://armteatr.ru/images/truppa/Kovalev.jpg',
                    categoryID: 3,
                },
            ],
        ];
    });

    axiosMockAdapterInstance.onGet(/\/api\/employees\/\d+/).reply(() => {
        return [
            200,
            {
                name: 'Малющева Ольга Геннадьевна',
                description: {
                    time: 1689711582976,
                    blocks: [
                        {
                            id: 'OGST8ofOJs',
                            type: 'paragraph',
                            data: {
                                text: 'После окончания Школы-студии МХАТ в 2006 году была принята в труппу Театра имени А.С.Пушкина. Ее дебютом на сцене нашего театра стала роль Джульетты в спектакле Романа Козака «Ромео и Джульетта».',
                            },
                        },
                        {
                            id: 'LAocEnyy1j',
                            type: 'paragraph',
                            data: {
                                text: 'Ведет передачу «Волшебный чуланчик» на телеканале «Карусель».',
                            },
                        },
                        {
                            id: 'i-8Qp9UPlB',
                            type: 'paragraph',
                            data: {
                                text: 'Кинодебют Анны Бегуновой состоялся еще в студенческие годы в картине «Женщины в игре без правил»(2004).',
                            },
                        },
                        {
                            id: '6zgq7B00im',
                            type: 'paragraph',
                            data: {
                                text: 'Снималась в фильмах: «Жестокость», «Голоса», «Наследница», «Полный вперед!», «Игра в прятки», «Сердцеедки», «Красная ртуть», «Моя большая семья», «Кухня», «В час беды», «Отель Элеон», «Гранд», «Звоните ДиКаприо!», «СеняФедя» и др.',
                            },
                        },
                    ],
                    version: '2.27.0',
                },
                photo: 'https://armteatr.ru/images/truppa/malyusheva.jpg',
                roles: [
                    {
                        title: 'Сибил Бернинг',
                        show: {
                            title: 'Инспектор пришел',
                            id: 1,
                        },
                    },
                    {
                        title: 'Глафира',
                        show: {
                            title: 'Между делом',
                            id: 1,
                        },
                    },
                    {
                        title: 'Марселина',
                        show: {
                            title: 'Женитьба Фигаро',
                            id: 1,
                        },
                    },
                    {
                        title: 'Три Ивана',
                        show: {
                            title: 'Баба-Яга',
                            id: 1,
                        },
                    },
                ],
            },
        ];
    });
}
