import {setMockShows} from "./shows";
import {setMockArticles} from "./articles";
import {setMockBanners} from "./banners";
import { setMockEmployees, setMockEmployeesCategory } from "./employees";
import { setMockFAQ } from "./faq";
import { setMockHistory } from "./history";

export default function setMocks(axiosMockAdapterInstance) {
   setMockShows(axiosMockAdapterInstance);
   setMockBanners(axiosMockAdapterInstance);
   setMockArticles(axiosMockAdapterInstance);
   setMockEmployees(axiosMockAdapterInstance);
   setMockEmployeesCategory(axiosMockAdapterInstance);
   setMockFAQ(axiosMockAdapterInstance);
   setMockHistory(axiosMockAdapterInstance);
}