import {Row, Grid} from "@nextui-org/react";
import moment from "moment";
import TText from "../../../Common/TText";
import {useEffect} from "react";
import RectLoader from "../../../Common/Loader/RectLoader";
import useElementSize from "../../../../hooks/useElementSize";
import LogoLoader from "../../../Common/Loader/LogoLoader";
import SimpleLogoLoader from "../../../Common/Loader/SimpleLogoLoader";

export default function PosterCalendarDays({
                                               size,
                                               height,
                                               currentDate,
                                               onlyActive,
                                               activeDaysForCurrentMonth,
                                               isLoading,
                                               animation
                                           }) {
    const responsiveSize = () => {
        switch (size) {
            case "m":
                return {
                    headerHeight: 50,
                    fontSize: 24,
                    selectedFontSize: 30
                }
            default:
                return {
                    headerHeight: 70,
                    fontSize: 48,
                    selectedFontSize: 96
                }
        }
    }

    const [containerRef, containerSize] = useElementSize();

    useEffect(() => {
        if (!activeDaysForCurrentMonth.length) {
            return;
        }
        currentDate.update({date: activeDaysForCurrentMonth[0]});
    }, [currentDate.value.month, currentDate.value.year, activeDaysForCurrentMonth])

    const dayCells = () => {
        const currentMoment = moment().year(currentDate.value.year).month(currentDate.value.month).date(currentDate.value.date);
        let days = [];
        for (let idx = 0; idx < currentMoment.daysInMonth(); idx++) {
            const day = idx + 1;
            const isActive = activeDaysForCurrentMonth.includes(day);
            const lastWeekStart = moment().month(currentDate.value.month).year(currentDate.value.year).date(day).endOf("month").startOf("week");
            const isLastWeek = day >= lastWeekStart.date();
            let fontSize = responsiveSize().fontSize
            if (isActive && day === currentDate.value.date) {
                fontSize = responsiveSize().selectedFontSize
            }
            days.push((
                <Grid xs={12 / 7} justify={"center"} alignItems={"center"} alignContent={"center"}
                      css={{
                          cursor: isActive ? "pointer" : "",
                          borderBottom: isLastWeek ? "" : "1px solid rgb(0,0,0,0.1)"
                      }}
                      onClick={isActive ? () => currentDate.update({date: day}) : () => {
                      }}
                      key={`grid-${currentDate.value.year}${currentDate.value.month}${day}`}
                >
                    <Row css={{height: responsiveSize().selectedFontSize}} justify={"center"} align={"center"}>
                        <TText size={fontSize} light css={{
                            transition: animation ? "200ms" : "",
                            color: isActive ?
                                day === currentDate.value.date ? "#8F2512" : "black"
                                : "rgb(0,0,0,0.2)"
                            ,
                        }}
                        >{day}</TText>
                    </Row>
                </Grid>
            ))
        }
        const firstDayOfWeek = moment().date(1).month(currentDate.value.month).year(currentDate.value.year).weekday();
        const append = [];
        for (let idx = 0; idx < firstDayOfWeek; idx++) {
            append.push(<Grid xs={12 / 7} css={{borderBottom: "1px solid rgb(0,0,0,0.1)"}}></Grid>)
        }

        days = [...append, ...days]
        return days;
    }

    if (isLoading) {
        return (
            <Row css={{h: `${height - 2 * responsiveSize().headerHeight}px`}}>
                <div ref={containerRef} style={{position: "relative", width: "100%", height: "100%"}}>
                    <div style={{position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)"}}>
                        <SimpleLogoLoader containerSize={{...containerSize, height: height - 2 * responsiveSize().headerHeight}} padding={100}/>
                    </div>
                </div>
            </Row>
        )
    }

    return (
        <Row css={{h: `${height - 2 * responsiveSize().headerHeight}px`, pb: "$sm"}}>
            <Grid.Container css={{h: "100%"}}>
                {dayCells().map(day => (day))}
            </Grid.Container>
        </Row>
    )
}