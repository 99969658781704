import { Col, Row } from "@nextui-org/react";
import TText from "./TText";
import { IconChevronLeft } from "@tabler/icons-react";

function TTitle({title, h}) {
    return (
        <Row css={style.container}>
            <Row css={style.titleContainer}>
                <TText css={style.title(h && h > 0 ? h - 1 : 0)}>{title}</TText>
            </Row>
            <Row css={style.line}/>
        </Row>
           )
};

const style ={
    container: {
        flexFlow: "column",
        width: "100%",
    },
    titleContainer: {
        paddingBottom: "5px", 
    },
    title: (h) => ({
        fontSize: `$${4-h}xl`,
        '@xs': {
            fontSize: `$${5-h}xl`,
        },
        '@sm': {
            fontSize: `$${6-h}xl`,
        },
    }),
    line: {
        borderBottom: "2px solid $primary"
    }
}

export default TTitle;