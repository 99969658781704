import { Col, Grid, Row } from '@nextui-org/react';
import TText from '../../../Common/TText';
import {useNavigate} from "react-router-dom";

function fi(fio) {
    const splitted = fio.split(' ');

    if (splitted.length === 1) {
        return splitted[0]
    }
    return splitted[0] + ' ' + splitted[1];
}

function Role({ compact, role }) {
    const navigate = useNavigate();

    return (
        <Row css={style.container}>
            <Grid.Container>
                <Grid
                    xs={12}
                    sm={compact ? 12 : 7}
                    css={style.roleTitleContainer}
                >
                    <Col css={style.roleTitleWrapper}>
                        <TText css={style.roleTitle}>{role.title}</TText>
                        {role.description && (
                            <TText light css={style.description}>
                                {role.description}
                            </TText>
                        )}
                    </Col>
                </Grid>
                <Grid xs={12} sm={compact ? 12 : 5}>
                    <Row justify="flex-end">
                        <Col css={style.employeeNameContainer}>
                            {role.employees.map((employee) => (
                                <TText light css={style.employeeName} onClick={() => navigate(`/pages/employees/${employee.id}`)}>
                                    {fi(employee.name)}
                                </TText>
                            ))}
                        </Col>
                    </Row>
                </Grid>
            </Grid.Container>
        </Row>
    );
}

const style = {
    container: {
        borderTop: '1px solid',
        paddingTop: '10px',
        paddingBottom: '20px',
    },
    roleTitleContainer: {
        paddingRight: '15px',
    },
    roleTitle: {
        fontSize: '30px',
        paddingBottom: '5px',
        '@lg': {
            fontSize: '36px',
        },
    },
    employeeName: {
        fontSize: '30px',
        color: '#8F2512',
        paddingBottom: '10px',
        cursor: 'pointer',
        width: 'max-content',
        '@lg': {
            fontSize: '36px',
        },
    },
    description: {
        fontSize: '20px',
        color: '#ababab',
        paddingLeft: '15px',
        '@sm': {
            paddingLeft: 0,
        },
        '@lg': {
            fontSize: '27px',
        },
    },
    employeeNameContainer: {
        '@xs': {
            width: '250px',
        },
        '@lg': {
            width: '300px',
        },
    },
    roleTitleWrapper: {
        display: 'flex',
        alignItems: 'center',
        '@sm': {
            display: 'block',
        },
    },
};

export default Role;
