import { useEffect, useRef, useState } from 'react';
import { Col, Row, Spacer, Text } from '@nextui-org/react';
import { TButton } from '../../Common/TButton';
import useWindowSize from '../../../hooks/useWindowSize';
import Arrow from '../../../images/arrow.svg';
import axios from '../../../lib/axios/axios';
import {adaptiveImageUrl, API, imageUrl} from '../../../api/api';
import useValue from '../../../hooks/useValue';
import LogoLoader from '../../Common/Loader/LogoLoader';
import useElementSize from '../../../hooks/useElementSize';
import TText from '../../Common/TText';
import { useNavigate } from 'react-router-dom';
import ImageAdaptive from "../../Common/ImageAdaptive";
import BlurryLoadingImage from "../../Common/BlurryLoadingImage";

export default function Slider() {
    const [containerRef, containerSize] = useElementSize();
    const [currentPosition, setCurrentPosition] = useState(0);
    const [animationEnabled, setAnimationEnabled] = useState(true);
    const isLoading = useValue(true);
    const [size, setSize] = useState({ text: 95, button: 'xl' });
    const animationDuration = 300;
    const [items, setItems] = useState([]);

    const nextButtonRef = useRef(null);

    useEffect(() => {
        axios
            .get(API.slider)
            .then((resp) => {
                if (resp.status !== 200) {
                }

                setItems(resp.data);
                isLoading.set(false);
            })
            .catch((err) => {
                isLoading.set(false);
            });
    }, []);

    const windowSize = useWindowSize();

    useEffect(() => {
        if (windowSize.width < 650) {
            setSize({ text: 40, button: 'lg' });
        } else if (windowSize.width < 1280) {
            setSize({ text: 60, button: 'lg' });
        } else {
            setSize({ text: 95, button: 'xl' });
        }
    }, [windowSize.width]);

    useEffect(() => {
        const autoUpdateInterval = setInterval(() => {
            if (nextButtonRef.current) {
                nextButtonRef.current.click();
            }
        }, 7000);

        return () => clearInterval(autoUpdateInterval);
    }, []);

    const [canPressPrev, setCanPressPrev] = useState(true);
    const onPrevButtonClick = () => {
        if (!canPressPrev) {
            return;
        }
        setCanPressPrev(false);
        setAnimationEnabled(false);
        const lastItem = items[items.length - 1];
        setItems([lastItem, ...items.slice(0, items.length - 1)]);
        setCurrentPosition(currentPosition - containerSize.height);
        setTimeout(() => {
            setAnimationEnabled(true);
            setCurrentPosition(0);
            setTimeout(() => {
                setCanPressPrev(true);
            }, animationDuration);
        }, 50);
    };

    const [canPressNext, setCanPressNext] = useState(true);

    const onNextButtonClick = () => {
        if (!canPressNext) {
            return;
        }
        setCanPressNext(false);
        setCurrentPosition(currentPosition - containerSize.height);
        setTimeout(() => {
            setAnimationEnabled(false);
            setItems([...items.slice(1, items.length), items[0]]);
            setCurrentPosition(0);
            setTimeout(() => {
                setAnimationEnabled(true);
                setCanPressNext(true);
            }, 50);
        }, animationDuration);
    };

    const navigate = useNavigate();

    if (isLoading.value) {
        return (
            <div
                ref={containerRef}
                style={{
                    position: 'absolute',
                    left: '0',
                    right: '0',
                    bottom: '0',
                    top: '0',
                    overflow: 'hidden',
                }}
            >
                <div
                    style={{
                        position: 'absolute',
                        left: '50%',
                        top: '50%',
                        transform: 'translate(-50%, -50%)',
                    }}
                >
                    <LogoLoader containerSize={containerSize} />
                </div>
            </div>
        );
    }

    return (
        <>
            <div
                ref={containerRef}
                style={{
                    position: 'absolute',
                    left: '0',
                    right: '0',
                    bottom: '0',
                    top: '0',
                    overflow: 'hidden',
                }}
            >
                {items.length > 1 && (
                    <div
                        onClick={onPrevButtonClick}
                        style={{
                            zIndex: '99',
                            position: 'absolute',
                            right: '20px',
                            top: '25%',
                            cursor: 'pointer',
                        }}
                    >
                        <img
                            src={Arrow}
                            alt={'prev'}
                            style={{ transform: 'rotate(180deg)' }}
                        />
                    </div>
                )}
                {items.length > 1 && (
                    <div
                        ref={nextButtonRef}
                        onClick={onNextButtonClick}
                        style={{
                            zIndex: '99',
                            position: 'absolute',
                            right: '20px',
                            bottom: '25%',
                            cursor: 'pointer',
                        }}
                    >
                        <img src={Arrow} alt={'next'} />
                    </div>
                )}

                <div
                    style={{
                        position: 'relative',
                        transition: animationEnabled
                            ? `${animationDuration}ms`
                            : '',
                        top: `${currentPosition}px`,
                        height: `${containerSize.height * items.length}px`,
                        width: '100%',
                    }}
                >
                    {items.map((item, idx) => (
                        <div
                            style={{
                                width: '100%',
                                height: `${containerSize.height}px`,
                                cursor: 'pointer',
                            }}
                            onClick={() => navigate('/pages/shows/' + item.id)}
                            key={idx}
                        >
                            <BlurryLoadingImage
                                preview={adaptiveImageUrl(item.main_picture, 100, 100)}
                                src={imageUrl(item.main_picture)}
                                css={{
                                    width: '100%',
                                    height: '100%',
                                }}
                                imgCss={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                }}
                            />
                        </div>
                    ))}
                </div>

                <Row
                    css={{
                        position: 'absolute',
                        left: '0',
                        bottom: '0',
                        height: '40%',
                        p: windowSize.width < 650 ? '20px' : '30px',
                        pr: windowSize.width < 650 ? '' : '100px',
                        background:
                            'linear-gradient(0deg, rgba(3,3,3,0.4) 0%, rgba(255,255,255,0) 100%)',
                    }}
                    align={'flex-end'}
                >
                    {windowSize.width >= 960 && (
                        <Row align={'center'} justify={'space-between'}>
                            <TText
                                css={{
                                    fontSize: `${size.text}px`,
                                    color: 'white',
                                }}
                            >
                                "{items.length ? items[0].title : 'Загрузка...'}
                                "
                            </TText>
                            {!!items.length && (
                                <a
                                    href={items[0].link_to_ticket}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <TButton
                                        size={size.button}
                                        transparent={'true'}
                                        white={'true'}
                                    >
                                        <TText size={30} light>
                                            Купить билет
                                        </TText>
                                    </TButton>
                                </a>
                            )}
                        </Row>
                    )}
                    {windowSize.width < 960 && (
                        <Col css={{ w: '100%' }}>
                            <Row justify={'flex-start'}>
                                <TText
                                    css={{
                                        fontSize: `${size.text}px`,
                                        color: 'white',
                                    }}
                                >
                                    "
                                    {items.length
                                        ? items[0].title
                                        : 'Загрузка...'}
                                    "
                                </TText>
                            </Row>
                            <Spacer />
                            <Row justify={'flex-start'}>
                                {!!items.length && (
                                    <a
                                        href={items[0].link_to_ticket}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <TButton
                                            size={size.button}
                                            css={{
                                                fontSize: '24px',
                                                fontWeight: '300',
                                            }}
                                            transparent
                                            white
                                        >
                                            <TText light size={30}>
                                                Купить билет
                                            </TText>
                                        </TButton>
                                    </a>
                                )}
                            </Row>
                        </Col>
                    )}
                </Row>
            </div>
        </>
    );
}
