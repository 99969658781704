import { Col, Row } from '@nextui-org/react';
import LeftArrow from '../../../images/arrowLeft.svg';
import TText from '../../Common/TText';
import { useEffect, useMemo, useRef } from 'react';
import './ShowSchedule.css';
import ScheduleDay from './ScheduleDay/ScheduleDay';
import { appendToObj } from '../../../lib/utils';
import moment from 'moment';
import useValue from '../../../hooks/useValue';
import RectLoader from '../../Common/Loader/RectLoader';

const processDates = (dates) => {
    const result = {};

    for (let idx = 0; idx < dates.length; idx++) {
        appendToObj(result, moment(dates[idx].at).format('MMMM'), dates[idx]);
    }

    return Object.entries(result);
};

function ShowSchedule({ schedule, link }) {
    const swiperDaysRef = useRef();

    const currentMonthIdx = useValue(0);

    const dates = useMemo(() => {
        return processDates(schedule);
    }, [schedule]);

    const onNext = () => {
        if (currentMonthIdx.value === dates.length - 1) {
            return;
        }

        currentMonthIdx.increment(1);
    };

    const onPrev = () => {
        if (currentMonthIdx.value === 0) {
            return;
        }

        currentMonthIdx.decrement(1);
    };

    return (
        <Col>
            <Row align="center" css={style.controlsContainer}>
                <Col
                    css={{
                        ...style.arrowContainer,
                        opacity: currentMonthIdx.value === 0 ? 0.3 : 1,
                    }}
                    onClick={onPrev}
                >
                    <img
                        src={LeftArrow}
                        style={style.arrow}
                        alt={'left arrow'}
                        className={'show-schedule__arrow'}
                    />
                </Col>
                <TText css={style.controlsTextMonth}>
                    {dates.length ? (
                        dates[currentMonthIdx.value][0]
                    ) : (
                        <RectLoader width={100} height={40} padding={0} />
                    )}
                </TText>
                <Col
                    css={{
                        ...style.arrowContainer,
                        opacity:
                            currentMonthIdx.value === dates.length - 1
                                ? 0.3
                                : 1,
                    }}
                    onClick={onNext}
                >
                    <img
                        src={LeftArrow}
                        style={{ ...style.arrow, transform: 'rotate(180deg)' }}
                        alt={'right arrow'}
                        className={'show-schedule__arrow'}
                    />
                </Col>
            </Row>
            <swiper-container
                class="swiper-days"
                ref={swiperDaysRef}
                free-mode="true"
                slides-per-view="auto"
                direction="horizontal"
                mousewheel="true"
            >
                <swiper-slide>
                    {dates.length &&
                        dates[currentMonthIdx.value][1].map((datetime) => (
                            <ScheduleDay datetime={datetime} link={datetime.ticket_link ? datetime.ticket_link : link} />
                        ))}
                </swiper-slide>
            </swiper-container>
        </Col>
    );
}

const style = {
    controlsContainer: {
        justifyContent: 'center',
        padding: '20px 0',
        '@xs': {
            justifyContent: 'flex-start',
        },
    },
    controlsTextMonth: {
        fontSize: '40px',
        padding: '0 20px',
        textTransform: 'capitalize',
        '@xs': {
            fontSize: '54px',
        },
    },
    arrowContainer: {
        cursor: 'pointer',
        width: 'max-content',
    },
    arrow: {
        filter: 'invert(1)',
    },
};

export default ShowSchedule;
