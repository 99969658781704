import {Col, Popover, Row, useInput} from "@nextui-org/react";
import { IconChevronDown } from "@tabler/icons-react";
import TText from "../TText";
import useValue from "../../../hooks/useValue";
import useElementSize from "../../../hooks/useElementSize";
import { useEffect, useRef } from "react";
import axios from "../../../lib/axios/axios";
import TInput from "../TInput/TInput";
// import axios from "axios";

function TSelect({placeholder, initSelected, initOptions, initZIndex, autocompleteAPI, autocompleteAPIParams, setSelected}) {
    const selected = useValue(initSelected ? initSelected : null);
    const isOpen = useValue(false);
    const options = useValue(initOptions ? initOptions : []);
    const searchInput = useInput();
    const [contentContainerRef, contentContainerSize] = useElementSize([options.value]);
    const selectContainerRef = useRef();

    useEffect(() => {
        if (autocompleteAPI) {
            axios.get(autocompleteAPI, {params: {
                ...autocompleteAPIParams,
                    search: searchInput.value
            }})
            .then(resp => {
                options.set(resp.data);
            })
        }
    }, [searchInput.value])

    function handleClickOutsideOnOpen(event) {
        if (selectContainerRef.current && !selectContainerRef.current.contains(event.target)) {
            onClose();
        }
    }

    const onClose = () => {
        isOpen.set(false);
    }

    const onOpen = () => {
        isOpen.set(true);
    }

    useEffect(() => {
        if (isOpen.value) {
            document.addEventListener("mousedown", handleClickOutsideOnOpen);
            return;
        }

        document.removeEventListener("mousedown", handleClickOutsideOnOpen);
        return () => {
            document.removeEventListener("mousedown", handleClickOutsideOnOpen);
        }
    }, [selectContainerRef, isOpen.value])

    const onToggle = () => {
        if (isOpen.value) {
            onClose();
            return;
        }

        onOpen();
    }

    const onSelect = (value) => {
        selected.set(value);
        if (setSelected) {
            setSelected(value);
        }
        onClose();
    }

    const deselect = () => {
        selected.set(null);
        if (setSelected) {
            setSelected(null);
        }
        onClose();
    }

    return (
        <div style={{...style.container, zIndex: initZIndex ? 10 + initZIndex : 10}} ref={selectContainerRef}>
            <Row justify="space-between" align="center" css={style.selectedContainer} onClick={onToggle}>
                <TText size={21} light>{selected.value !== null ? selected.value.name : "Не выбрано"}</TText>
                <IconChevronDown style={{transition: "300ms", transform: `rotate(${isOpen.value ? 180 : 0}deg)`}}/>
            </Row>
            <div style={{...style.contentContainer, height: isOpen.value ? contentContainerSize.height + "px" : 0}}>
                <div ref={contentContainerRef} style={style.contentWrapper}>
                    {autocompleteAPI && (
                        <TInput
                            placeholder={"Введите для поиска"}
                            light
                            css={style.autocompleteInput}
                            bindings={searchInput.bindings}/>
                    )}
                    <div style={style.resultContainer}>
                        {selected.value !== null && (
                            <Row css={style.optionItem} onClick={deselect}>
                                <TText size={21} light style={{color: 'gray'}}>Не выбрано</TText>
                            </Row>
                        )}
                        {options.value.map(option => (
                            <Row css={style.optionItem} onClick={() => onSelect(option)} key={option.id}>
                                <TText size={21} light>{option.name}</TText>
                            </Row>
                        ))}
                    </div>
                </div>
                
            </div>
            
        </div>
        
    )
}

const style = {
    autocompleteInput: {
        width: "100%",
    },
    container: {
        border: "2px solid",
        borderBottom: "none",
        padding: "5px",
        transition: "300ms",
        position: "relative",
        width: "100%"
    },
    selectedContainer: {
        cursor: "pointer",
    },
    contentContainer: {
        overflow: "hidden",
        transition: "300ms",
        border: "2px solid",
        borderTop: "none",
        position: "absolute",
        left: -2,
        width: "calc(100% + 4px)",
        top: "100%",
        background: "white",
    },
    resultContainer: {
        overflowY: "scroll",
        flexGrow: 1,
    },
    optionItem: {
        padding: "5px",
        cursor: "pointer",
        '&:hover': {
            background: "rgb(0, 0, 0, 0.1)"
        }
    },
    contentWrapper: {
        display: "flex",
        flexFlow: "column",
        maxHeight: "300px",
    }
}

export default TSelect;