import { Col, Row } from '@nextui-org/react';
import moment from 'moment';
import TText from '../../../Common/TText';
import './ScheduleDay.css';
import { useMemo, useRef } from 'react';

function ScheduleDay({ datetime, link }) {
    const disable = moment() > moment(datetime.at);

    const aRef = useRef();

    return (
        <Col
            css={{ ...style.container, cursor: disable ? '' : 'pointer' }}
            className={`schedule-day__container${disable ? ' disable' : ''}`}
            onClick={() => {
                if (disable) {
                    return;
                }

                aRef.current.click();
            }}
        >
            <Row justify="center">
                <TText
                    css={{ ...style.dayText, color: disable ? '#ccc' : '' }}
                    className={'schedule-day__day-text'}
                >
                    {moment(datetime.at).date()}
                </TText>
            </Row>
            <Row justify="center">
                <TText
                    css={{
                        ...style.timeText,
                        color: disable ? '#ccc' : '',
                    }}
                >
                    {moment(datetime.at).format('dd, HH:mm')}
                </TText>
            </Row>
            <a ref={aRef} href={link} target="_blank" rel="noreferrer" hidden>
                .
            </a>
        </Col>
    );
}

const style = {
    container: {
        float: 'left',
        width: '70px',
        '@xs': {
            width: '100px',
        },
    },
    dayText: {
        fontSize: '24px',
        paddingBottom: '5px',
        transition: '300ms',
        '@xs': {
            fontSize: '36px',
            paddingBottom: '10px',
        },
    },
    timeText: {
        textTransform: 'uppercase',
        color: 'gray',
        fontSize: '18px',
        fontWeight: 300,
        '@xs': {
            fontSize: '24px',
        },
    },
};

export default ScheduleDay;
