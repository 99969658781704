import {Col, Row, Spacer} from "@nextui-org/react";
import ArrowDown from "../../images/ArrowDown.svg"
import {useEffect, useRef, useState} from "react";
import useElementSize from "../../hooks/useElementSize";

export default function TCollapse({title, content, isExpanded, onExpand, css}) {
    const [expanded, setExpanded] = useState(false);
    const [containerRef, containerSize] = useElementSize([content]);

    const ref = useRef();

    const isExpandedResolver = () => {
        return isExpanded === undefined ? expanded : isExpanded;
    }

    const customCSS = {
        titleContainer: css?.titleContainer ? css?.titleContainer : {}, 
        container: css?.container ? css?.container : {}, 
    }

    return (
        <Row css={customCSS.container}>
            <Col>
                <Row align={"center"} onClick={isExpanded === undefined ? () => setExpanded(!expanded) : onExpand} css={{cursor: "pointer", ...customCSS.titleContainer}}>
                    {title}
                    <Spacer x={0.5}/>
                    <img src={ArrowDown} style={{
                        height: "21px",
                        transition: "300ms",
                        transform: `rotate(${isExpandedResolver() ? 180 : 0}deg)`
                    }}/>
                </Row>
                <Row css={{
                    pl: "20px",
                    transition: "300ms",
                    overflowY: "hidden",
                    height: isExpandedResolver() ? containerSize.height + 5 + "px" : "0"}}>
                    <div ref={containerRef} style={{width: "100%"}}>
                        {content}
                    </div>
                </Row>
            </Col>
        </Row>
    );
}