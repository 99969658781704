import { Col, Grid, Row } from '@nextui-org/react';
import TText from '../../../Common/TText';
import Role from './Role';

function Cast({ cast }) {
    return (
        <Row css={style.showCastContainer}>
            <Grid.Container>
                <Grid xs={12} sm={7}>
                    <Col css={style.rolesContainer}>
                        <TText css={style.text}>
                            Действующие лица и исполнители
                        </TText>
                        {cast &&
                            cast
                                .filter((item) => item.is_actor)
                                .map((role) => <Role role={role} />)}
                    </Col>
                </Grid>
                <Grid xs={12} sm={5}>
                    <Col css={style.productionTeamContainer}>
                        <TText css={style.text}>Постановочная группа</TText>
                        {cast &&
                            cast
                                .filter((item) => !item.is_actor)
                                .map((role) => <Role compact role={role} />)}
                    </Col>
                </Grid>
            </Grid.Container>
        </Row>
    );
}

const style = {
    showCastContainer: {
        marginBottom: '50px',
    },
    text: {
        marginBottom: '15px',
        fontSize: '36px',
        '@xs': {
            fontSize: '36px',
        },
        '@md': {
            marginBottom: '30px',
            fontSize: '44px',
        },
        '@lg': {
            fontSize: '55px',
        },
    },
    rolesContainer: {
        padding: '10px',
        paddingLeft: 0,
        '@sm': {
            paddingRight: '25px',
        },
        '@md': {
            paddingRight: '100px',
        },
    },
    productionTeamContainer: {
        border: '2px solid',
        padding: '10px',
    },
};
export default Cast;
