import useElementSize from "../../../hooks/useElementSize";
import { Grid, Col, Row } from "@nextui-org/react";
import FooterTitle from "./FooterTitle";
import FooterText from "./FooterText";
import useWindowSize from "../../../hooks/useWindowSize";


const FooterInfoBlock = ({title, items, additionalItems, mobileVersion, isOpen, onOpen}) => {
    if (!additionalItems) {
        additionalItems = [];
    }
    const windowSize = useWindowSize();
    const [contentRef, contentSize] = useElementSize();

    return (
        <Grid xs={12} md={windowSize.height < 700 ? 12 : 12 / 5}>
            <Col css={{m: 0, p: 0}}>
                <FooterTitle text={title} mobileVersion={mobileVersion} isOpen={isOpen} onClick={mobileVersion ? onOpen : () => {}}/>
                <Row css={{w: "100%", overflow: "hidden", transition: "300ms", height: (!mobileVersion || isOpen) ? contentSize.height + 5 + "px" : "0"}}>
                    <div ref={contentRef} style={{width: "100%"}}>
                        <Grid.Container gap={mobileVersion ? 1 : 2}>
                            {[...items, ...additionalItems].map(item => {
                                if (item.text) {
                                    return <FooterText key={item.text} text={item.text} link={item.link}/>
                                }

                                if (item.row) {
                                    return item.row
                                }
                            })
                            }
                        </Grid.Container>
                    </div>
                </Row>
            </Col>
        </Grid>
    )
}

export default FooterInfoBlock;