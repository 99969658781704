import { Row, Grid } from "@nextui-org/react";
import useElementSize from "../../../hooks/useElementSize";
import useValue from "../../../hooks/useValue";
import ArrowDown from "../../../images/ArrowDown.svg";
import {imageUrl} from "../../../api/api";

function GridSlider({items, css}) {
    const [viewRef, viewSize] = useElementSize();
    const [containerRef, containerSize] = useElementSize([items]);
    const [firstItemRef, firstItemSize] = useElementSize([items]);

    const currentPosition = useValue(0);

    const canNext = viewSize.height + 30 < containerSize.height + currentPosition.value;

    const canPrev = currentPosition.value < 0;

    const onNextClick = () => {
        currentPosition.decrement(firstItemSize.height + 30);
    }

    const onPrevClick = () => {
        currentPosition.increment(firstItemSize.height + 30);
    }

    return (
        <div ref={viewRef} style={{width: "100%"}}>
            <Row css={{position: "relative", overflow: "hidden", ...css}}>
                <div ref={containerRef}
                     style={{width: "100%", position: "absolute", top: currentPosition.value + "px", left: 0, transition: "300ms"}}>
                    <Grid.Container gap={2} css={{p: 0}}>
                        {!!items && !!items.length && items.map((item, idx) => (
                            <Grid key={item.id} xs={12 / 2} sm={12 / 3} md={12 / 4} css={{p: "15px 0 15px 0"}}>
                                <div ref={idx === 0 ? firstItemRef : null}
                                     style={{width: "100%", display: "flex", justifyContent: "center"}}>
                                    <Row css={{
                                        cursor: "pointer",
                                        background: "white",
                                        w: "160px", h: "100px",
                                        transition: "300ms",
                                        '@xs': {
                                            w: "300px", h: "200px"
                                        },
                                        '&:hover': {
                                            transform: "scale(1.03)"
                                        }
                                    }} justify={"center"} align={"center"} as={"a"} href={item.url} target={"_blank"}>
                                        <img src={imageUrl(item.image)} alt={item.title} style={{
                                            objectFit: "contain",
                                            height: "calc(100% - 40px)",
                                            width: "calc(100% - 40px)",
                                        }}/>
                                    </Row>
                                </div>
                            </Grid>
                        ))}
                    </Grid.Container>
                </div>


                <GridSliderButton up visible={canPrev} onClick={onPrevClick}/>
                <GridSliderButton visible={canNext} onClick={onNextClick}/>
            </Row>
        </div>
    )
}

export default GridSlider;

const GridSliderButton = ({up, visible, onClick}) => {
    return (
        <Row css={{
            position: "absolute",
            bottom: up ? "" : 0,
            top: up ? 0 : "",
            left: 0,
            right: 0,
            height: "70px",
            background: `linear-gradient(${up ? 180 : 0}deg, $darkBackground 0%, rgba(255,255,255,0) 100%)`,
            visibility: visible ? "visible" : "hidden",
            cursor: "pointer",
        }} justify={"center"} align={"center"} onClick={onClick}>
            <img src={ArrowDown} style={{
                filter: "invert(1)", position: "absolute",
                height: "36px",
                left: "50%",
                transform: `translate(-50%, 0) ${up ? "rotate(180deg)" : ""}`,
                bottom: up ? "" : "5px",
                top: up ? "5px" : "",
            }}/>
        </Row>
    )

}