import { Row, Col } from '@nextui-org/react';
import moment from 'moment';
import {Blend, Clock} from 'iconsax-react';
import { TButton } from '../../Common/TButton';
import TText from '../../Common/TText';
import Output from '../../../lib/editor-output/editor-output';
import { useNavigate } from 'react-router-dom';
import pushkin from '../../../images/P_Black.svg';
import './ShowMainInfo.css';
import {adaptiveImageUrl, adaptiveSrcSet} from "../../../api/api";
import ImageAdaptive from "../../Common/ImageAdaptive";
import BlurryLoadingImage from "../../Common/BlurryLoadingImage";

function ShowMainInfo({ show, isArchive }) {
    const navigate = useNavigate();

    console.log('POSTER', show.data?.poster)

    return (
        <>
            <Row css={styles.container}>
                <Row css={styles.posterContainer}>
                    <Col css={styles.poster}>
                        {show.data && (
                            <BlurryLoadingImage
                                preview={adaptiveImageUrl(show.data?.poster, 100, 100)}
                                src={adaptiveImageUrl(show.data?.poster, 700, 900)}
                                css={{width: "100%", height: "100%"}}
                                imgCss={{objectFit: "cover"}}
                            />
                        )}
                        {/*<ImageAdaptive src={show.data?.poster} style={{width: "100%", height: "100%", objectFit: "cover"}} w={340} h={480}/>*/}
                    </Col>
                </Row>
                <Row css={styles.infoContainer} justify="space-between">
                    <Row css={styles.description}>
                        <Output data={show.data?.description} />
                    </Row>
                    <Row css={styles.mainInfoContainer}>
                        <Col>
                            <Row css={styles.mainInfoRow}>
                                <span style={styles.mainInfoTitle}>
                                    Режиссёр:
                                </span>{' '}
                                <span
                                    style={{ cursor: 'pointer' }}
                                    onClick={() =>
                                        navigate(
                                            '/pages/employees/' +
                                                show.data?.director.id
                                        )
                                    }
                                >
                                    {show.data?.director.name}
                                </span>
                            </Row>
                            <Row css={styles.mainInfoRow}>
                                <span style={styles.mainInfoTitle}>Жанр:</span>{' '}
                                {show.data?.genre}
                            </Row>
                            <Row css={styles.mainInfoRow}>
                                <span style={styles.mainInfoTitle}>
                                    Премьера:
                                </span>{' '}
                                {moment(show.data?.premiere).format(
                                    'D MMMM YYYY г.'
                                )}
                            </Row>
                            <Row css={styles.mainInfoRow}>
                                <span style={styles.mainInfoTitle}>
                                    <Clock color="#000" size="24" />
                                </span>{' '}
                                {show.data?.duration}
                            </Row>
                        </Col>
                        {!isArchive && (
                            <Row css={styles.buttonContainer} className={"show-page__button-container"}>
                                <a href={show.data?.link_to_ticket} target={"_blank"}>
                                    <TButton transparent bold css={{ p: '20px' }}>
                                        {show.data?.pushkin &&
                                            <img src={pushkin} style={{height: "30px", marginRight: "5px"}}/>
                                        }
                                        <TText size={30} light>
                                            Купить билет
                                        </TText>
                                    </TButton>
                                </a>
                            </Row>
                        )}
                    </Row>
                </Row>
            </Row>
            <Col css={styles.mobileDescription}>
                <Output data={show.data?.description} />
            </Col>
        </>
    );
}

const styles = {
    container: {
        marginTop: '15px',
        flexFlow: 'column',
        '@xs': {
            height: '350px',
            flexFlow: 'row',
        },
        '@sm': {
            marginTop: '30px',
        },
        '@md': {
            height: 'max-content',
        },
    },
    posterContainer: {
        h: '100%',
        justifyContent: 'center',
        '@xs': {
            w: 'min-content',
        },
    },
    poster: {
        width: '230px',
        marginRight: '15px',
        height: '325px',
        '@xs': {
            height: '100%',
        },
        '@sm': {
            marginRight: '30px',
        },
        '@md': {
            marginRight: '50px',
            width: '340px',
            h: '480px',
        },
    },
    description: {
        display: 'none',
        '@md': {
            display: 'block',
        },
    },
    mobileDescription: {
        marginTop: '10px',
        '@md': {
            display: 'none',
        },
    },
    mainInfoRow: {
        fontSize: '16px',
        mt: '10px',
    },
    mainInfoTitle: {
        fontWeight: 'bold',
        paddingRight: '5px',
    },
    infoContainer: {
        height: '100%',
        flexFlow: 'column',
        justifyContent: 'space-between',
    },
    mainInfoContainer: {
        flexFlow: 'column',
        justifyContent: 'space-between',
        h: '100%',
        '@md': {
            h: 'max-content',
            flexFlow: 'row',
            alignItems: 'flex-end',
        },
    },
    buttonContainer: {
        paddingTop: '15px',
        justifyContent: 'flex-end',
        height: '100%',
        alignItems: 'flex-end',
    },
};

export default ShowMainInfo;
