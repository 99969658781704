const WarningRender = ({data, style, calssNames, config}) => {
    return (
    <div className="ewarning">
      <div className="ewarning__title-container">
        <div className="ewarning__icon">☝️</div>
        <div className="ewarning__title">{data.title}</div>
      </div>
      <div className="ewarning__message">{data.message}</div>
    </div>
  )
}

export default WarningRender;