import {Row, Grid} from "@nextui-org/react";
import { randomKey } from "../utils";

function SlideContainer({items, defaultCountOnSlide, width}) {
    return (
        <Row css={{width}}>
            <Grid.Container justify={"center"}>
                {items.map(item => (
                    <Grid key={randomKey()} xs={12/defaultCountOnSlide} justify={"center"}>
                        {item}
                    </Grid>
                    ))}
            </Grid.Container>
        </Row>
    )
}

export default SlideContainer;