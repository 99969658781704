import { Col, Row, Text } from "@nextui-org/react";
import { Clock } from "iconsax-react";
import "../ShowItem.css";
import TText from "../../../../Common/TText";
import { TButton } from "../../../../Common/TButton";
import Output from "../../../../../lib/editor-output/editor-output";
import OutputWrapper from "../../../../../lib/editor-output/output-wrapper";
import pushkin from "../../../../../images/P_Grey.svg";
import {adaptiveImageUrl, adaptiveSrcSet} from "../../../../../api/api";

function ShowItemDesktop({show, isLast, isArchive, onClick}) {
    return (
        <Row css={{...style.showContainer, borderBottom: isLast ? "unset" : "$primary solid 2px"}} className="show-item" onClick={onClick}>
            <Col css={style.imageContainer}>
                <img src={adaptiveImageUrl(show.poster, 120, 230)} srcSet={adaptiveSrcSet(show.poster, 120,230)} style={style.image}/>
            </Col>
            <Col css={style.contentContainer}>
                <Row css={style.titleContainer}>
                    <TText css={style.title} className={"show-item__title"}>{show.title}</TText>
                    <Row align={"center"} css={{w: "min-content", ml: "10px"}}>
                        <Col css={style.pushkinContainer}>
                            {!isArchive && show.pushkin &&
                                <img src={pushkin} alt={"pushkin"} style={{height: "100%"}}/>
                            }
                        </Col>
                        <Col css={{w: "min-content"}}>
                            <TText css={style.rars}>{show.rars}+</TText>
                        </Col>
                    </Row>
                </Row>
                <Row css={style.descriptionContainer}>
                    <Col>
                        <OutputWrapper data={show.description} maxHeight={'200px'}/>
                    </Col>
                </Row>
                <Row css={style.footerContainer}>
                    <Row css={style.durationContainer}>
                        <Clock color="#000" size="24"/>
                        <TText light css={style.duration}>{show.duration}</TText>
                    </Row>
                    <TButton css={style.button} onClick={onClick}><TText light css={style.buttonText}>{isArchive ? "Подробнее" : "Купить билет"}</TText></TButton>
                </Row>
            </Col>
        </Row>
    );
}

const style = {
    showContainer: {
        padding: "20px 0",
        cursor: "pointer",
    },
    imageContainer: {
        minWidth: "120px",
        height: "230px",
        marginRight: "20px",
        '@xs': {
            marginTop: "10px",
            height: "100%",
            marginRight: "50px", 
            minWidth: "200px",
            maxWidth: "200px",
        }
    },
    image: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
    },
    contentContainer: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
    },
    pushkinContainer: {
        width: "30px",
        height: "30px",
        marginRight: "5px",
        '@md': {
            width: "40px",
            height: "40px",
        }
    },
    titleContainer: {
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "15px",
    },
    title: {
        fontSize: "32px",
        transition: "200ms",
        '@sm': {
            fontSize: "48px",
        },
        '@md': {
            fontSize: "64px",
        }
    },
    rars: {
        fontSize: "27px",
        color: "gray",
        width: "min-content",
        '@sm': {
            fontSize: "32px",
        },
        '@md': {
            fontSize: "42px",
        }
    },
    descriptionContainer: {
        flexGrow: 1,
        minHeight: "130px",
    },
    description: {
        display: "-webkit-box",
        overflow: "hidden",
        "-webkit-box-orient": "vertical",
        "-webkit-line-clamp": 6,
        whiteSpace: "pre-wrap",
    },
    footerContainer: {
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "flex-start",
        '@sm': {
            alignItems: "flex-end",
            flexDirection: "unset",
        }
    },
    durationContainer: {
        alignItems: "center",
        marginBottom: "10px",
        '@sm': {
            marginBottom: 0,
        }
    },
    duration: {
        marginLeft: "10px",
        alignItems: "center",
        fontSize: "21px",
    },
    buttonText: {
        fontSize: "21px",
    },
    button: {
        width: "100%",
        '@sm': {
            width: "unset",
        }
    }
}

export default ShowItemDesktop;