import { Col, Grid, Row, Spacer } from "@nextui-org/react";
import TText from "../../Common/TText";
import TCard from "../../Common/TCard";
import EmployeCard from "./EmployeCard";

function EmployeesBlock({title, employees}) {
    return (
        <>
            <Row justify="center">
                <TText css={titleStyle} light>{title}</TText>
            </Row>
            <Grid.Container gap={3}>
                {
                    employees.map(item => (
                        <Grid xs={12/2} sm={12/3} md={12/4} lg={12/5}>
                            <Row justify="center">
                                <EmployeCard employee={item}/>
                            </Row>
                        </Grid>
                    ))
                }
            </Grid.Container>
        </>
    )
}

const titleStyle = {
    fontSize: "32px",
    margin: "10px",
    textAlign: "center",
    '@xs': {
        fontSize: "48px",
    },
    '@sm': {
        fontSize: "72px",
    }
};

export default EmployeesBlock;