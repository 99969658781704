import EditorOutput from 'editorjs-react-renderer';
import "./editor-output.css";
import WarningRender from './renderers/warning';

function Output({data}) {
    return <EditorOutput renderers={renderers} data={data} classNames={style}/>
}

const renderers = {
    warning: WarningRender
}

const style = {
    paragraph: "ep",
    header: {
      h1: "eh1",
      h2: "eh2",
      h3: "eh3",
      h4: "eh4",
      h5: "eh5",
      h6: "eh6",
    },
    image: {
      img: "eimg",
      figure: "efigure",
      figcaption: "efigcaption",
    },
    video: {
      video: "evideo",
      figure: "efigure",
      figcaption: "efigcaption" 
    },
    embed: {
      video: "evideo-embed",
      figure: "efigure-embed",
      figcaption: "efigcaption-embed" 
    },
    list: {
      container: "elist__container",
      listItem: "elist__item",
    },
    checklist: {
      container: "echecklist__container",
      item: "echecklist__item",
      checkbox: "echecklist__checkbox",
      label: "echecklist__label",
    },
    table: {
      table: "etable",
      tr: "etable__tr",
      th: "etable__th",
      td: "etable__td",
    },
    quote: {
      container: "equote__container",
      content: "equote__content",
      author: "equote__quauteor",
      message: "equote__message", 
    },
    codeBox: {
      container: "ecode__container",
      code: "ecode_code",
    },
    warning: {
      container: "ewarning__container",
      icon: "ewarning__container",
      title: "ewarning__title",
      message: "ewarning__message",
    },
    delimiter: {
      container: "edelimiter__container",
      svg: "edelimiter__svg",
      path: "edelimiter__path" 
    },
    personality: {
      container: "epersonality__container",
      textHolder: "epersonality__text",
      name: "epersonality__name",
      description: "epersonality__description",
      photo: "epersonality__photo",
      link: "epersonality__link"
    },
    linkTool: {
      container: "elink__container",
      textHolder: "elink__text",
      title: "elink__title",
      description: "elink__description",
      image: "elink__image",
      siteName: "elink__site-name"
    },
  };

export default Output;