import {useNavigate, useParams} from 'react-router-dom';
import TTitle from '../../Common/TTitle';
import useFetch from '../../../hooks/useFetch';
import {API, imageUrl} from '../../../api/api';
import { Col, Grid, Row, Spacer } from '@nextui-org/react';
import Output from '../../../lib/editor-output/editor-output';
import BlockTitle from '../../MainPage/BlockTitle';
import TText from '../../Common/TText';
import ImageAdaptive from "../../Common/ImageAdaptive";

function EmployeePage() {
    const { employeeID } = useParams();

    const employee = useFetch({ initial: {}, api: API.employee(employeeID) });

    const navigate = useNavigate();

    return (
        <>
            <TTitle title={employee.data.name} h={2} controls={'Коллектив'} />
            <Row>
                <TText css={style.positionTitle}>{employee.data.position_title}</TText>
            </Row>
            <Row css={style.mainInfoContainer}>
                <Row css={style.imageContainer} justify="center">
                    {/*<Col css={{w: "max-content"}}>*/}
                        <ImageAdaptive
                            src={employee.data.photo}
                            alt={employee.data.name}
                            w={300}
                            h={458}
                            style={style.image}
                        />

                    {/*</Col>*/}
                </Row>
                <Col css={style.description}>
                    {employee.data.description
                        && Object.keys(employee.data.description).length !== 0
                        && <Output data={employee.data.description} />
                    }
                </Col>
            </Row>
            {employee.data.roles && (
                <>
                    <Spacer y={0.5} />
                    <BlockTitle color="tblack" h={2}>
                        Роли в спектаклях
                    </BlockTitle>
                    <Row css={style.rolesContainer}>
                        {employee.data.roles.map((role) => (
                            <Col css={style.roleContainer} onClick={() => navigate('/pages/shows/' + role.show.id)}>
                                <Row css={style.showTitleContainer}>
                                    <TText light css={style.showTitle}>
                                        {role.show.title}
                                    </TText>
                                </Row>
                                <Row css={style.roleTitleContainer}>
                                    <TText light css={style.roleTitle}>
                                        {role.title}
                                    </TText>
                                </Row>
                            </Col>
                        ))}
                    </Row>
                    <Spacer/>
                </>
            )}
        </>
    );
}

const style = {
    mainInfoContainer: {
        flexFlow: 'column',
        marginTop: '20px',
        '@sm': {
            flexFlow: 'row',
        },
    },
    description: {
        paddingTop: '25px',
        '@sm': {
            paddingTop: 0,
            paddingLeft: '40px',
        },
    },
    imageContainer: {
        '@sm': {
            width: 'max-content',
        },
    },
    positionTitle: {
        fontSize: "30px",
        pt: "10px",
        fontWeight: "300",
        color: "gray",
    },
    image: {
        height: '458px',
        minWidth: '300px',
        width: '300px',
    },
    rolesContainer: {
        flexWrap: 'wrap',
        justifyContent: 'space-around',
    },
    roleContainer: {
        cursor: 'pointer',
        marginBottom: '20px',
        width: '100%',
        '@xs': {
            maxWidth: '500px',
            padding: '10px',
            width: 'max-content',
        },
    },
    showTitleContainer: {
        '@xs': {
            justifyContent: 'center',
        },
    },
    showTitle: {
        fontSize: '36px',
        marginBottom: '5px',
        '@xs': {
            textAlign: 'center',
            fontSize: '40px',
        },
        '@sm': {
            fontSize: '48px',
        },
    },
    roleTitleContainer: {
        '@xs': {
            justifyContent: 'center',
        },
    },
    roleTitle: {
        color: '$primary',
        fontSize: '27px',
        '@xs': {
            textAlign: 'center',
        },
    },
};

export default EmployeePage;
