import { Row } from "@nextui-org/react";
import BlockTitle from "../../MainPage/BlockTitle";
import axios from "../../../lib/axios/axios";
import { API } from "../../../api/api";
import useValue from "../../../hooks/useValue";
import EmployeesBlock from "./EmployeesBlock";
import { useEffect } from "react";

function EmployeesPage() {
    const categories = useValue([]);
    const employees = useValue([]);

    useEffect(() => {
        axios.get(API.categoriesOfEmployees)
        .then(resp => {
            categories.set(resp.data);
        })
        .catch(err => {
        })

        axios.get(API.employees)
        .then(resp => {
            employees.set(resp.data);
        })
        .catch(err => {

        })
    }, [])

    


    return (
        <>
            <BlockTitle color={"tblack"}>Коллектив</BlockTitle> 
            {categories.value.map(category => (
                <EmployeesBlock title={category.title} employees={employees.value.filter(employee => employee.categoryID === category.id)}/>
            ))}
        </>
    )
}

export default EmployeesPage;